fedelzet-page {
  background: white;

  .green-bar {
    background-color: $main-color;
    padding: 35px 0;
    color: white;
    font-family: $title-font;
    text-transform: uppercase;

    .btn {
      font-family: $title-font;
      text-transform: uppercase;
      font-size: 2.5rem;
      padding: 17px 12px;
      border-radius: 7px;
      transition: color .2s, background-color .2s;

      &.btn-black {
        background-color: #333;
        border-color: #333;
        color: white;

        &:hover {
          background-color: white;
          color: #333;
        }
      }

      &.btn-white {
        background-color: white;
        border-color: white;
        color: #333;

        &:hover {
          background-color: #333;
          color: white;
        }
      }
    }
  }

  .login-bar {
    .login-bar-text {
      text-align: center;
      font-size: 2.3rem;
      line-height: 1.2;
      margin-top: 8px;
    }
  }

  .download-bar {
    .download-text {
      text-align: center;
      font-size: 2.5rem;
      line-height: 1.1;

      strong {
        color: white;
        font-size: 2.9rem;
      }
    }

    .short-hr {
      margin: 14px auto 18px;
    }

    .btn {
      padding: 10px 13px;

      .t-table {
        .t-cell {
          vertical-align: middle;

          &:last-child {
            text-align: center;
            padding: 0 0 0 5px;
          }
        }
      }

      .download-name {
        font-size: 1.2rem;
        white-space: normal;
        line-height: 1.4;
        display: block;
        text-transform: none;
        margin-bottom: 10px;
      }

      .download-label {
        display: block;
        line-height: 1;
        font-weight: bold;
        font-size: 2rem;
      }
    }

    .thumbnail-image {
      width: 56px;
      height: 56px;
    }
  }

  .soma-image {
    width: auto;
    max-width: 100%;
    margin: 0 auto 25px;

    .t-cell {
      vertical-align: middle;
    }
  }

  .fedelzet-content {
    word-break: break-word;

    h3 {
      margin-top: 45px;
      text-align: center;

      &.extra-emphasis {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 2.6rem;
      }
    }

    h3 + hr {
      margin: 15px auto 35px;
    }
  }

  .packages {
    //margin-bottom: 40px;

    .package-box {
      background-color: white;
      border: 1px solid #ccc;
      border-radius: 2px;
      padding: 15px 25px;
      //min-height: 595px;
      //margin-bottom: 15px;
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;

      h4, h5 {
        text-align: center;
      }

      hr.short-hr {
        margin: 10px auto;

        &.thin {
          height: 2px;
        }
      }

      ul {
        &.feature-list {
          //min-height: 305px;
          min-height: 236px;
        }

        li {
          text-align: center;
          padding: 10px 0;
          border-bottom: 1px solid #eee;
          font-size: 1.6rem;

          &:last-child {
            border-bottom: none;
          }
        }
      }

      .prices-title {
        margin-top: 20px;
      }

      .prices {
        ul {
          li {
            text-align: left;

            .btn.btn-primary {
              text-align: left;
              font-size: 2rem;
            }

            span:last-child {
              float: right;
              font-weight: bold;
            }
          }
        }

        .important-buying-note {
          font-size: 1.6rem;
          font-weight: bold;
        }
      }

      .special-price {
        display: inline-block;
        margin-top: 10px;
        font-weight: bold;
      }
    }

    @media (max-width: $screen-xs-max) {
      margin-top: 15px;
    }
  }

  .not-premium-block {
    margin-bottom: 40px;
  }

  h3.extra-emphasis {

  }

  .section-title-wrapper {
    .section-title-content {
      position: relative;

      .section-title-buttons {
        position: absolute;
        right: 0;
        bottom: 8px;

        .btn {
          font-size: 2rem;
          text-transform: uppercase;

          font-family: $title-font;
          transition: color .2s, background-color .2s;

          &.btn-black {
            background-color: #333;
            border-color: #333;
            color: white;

            &:hover {
              background-color: white;
              color: #333;
            }
          }

          &.btn-white {
            background-color: white;
            border-color: #333;
            color: #333;

            &:hover {
              background-color: #333;
              color: white;
            }
          }
        }

        .login-buttons {
          .btn {
            font-size: 2.2rem;
          }
        }

        @media (max-width: $screen-sm-max) {
          position: static;
          margin-top: 10px;
        }

        @media (max-width: $screen-tn-max) {
          .btn {
            display: block;

            + .btn {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  &.general-page section {
    margin-top: 0;
  }

  .subscription-content {
    .nav-tabs-container {
      position: relative;

      .nav-tabs {
        position: absolute;
        width: 100%;
        bottom: 100%;
        text-align: right;
        border-bottom: none;

        > li {
          float: none;
          display: inline-block;

          a {
            font-size: 2rem;
          }

          &.active {
            > a {
              border-color: #eee;
              background-color: $body-bg;
              border-bottom-color: transparent;
            }
          }
        }
      }
    }

    .tab-content {
      border-top: 1px solid #eee;
      background-color: $body-bg;
    }

    .subscription-renew-warning-panel {
      margin-top: 15px;

      .text-bold {
        font-weight: bold;
      }

      a {
        color: $brand-primary;
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    .download-image {
      max-width: 300px;
      margin-bottom: 20px;
    }

    .soma-image {
      display: block;
      text-align: center;

      .t-cell {
        display: block;
      }
    }

    .download-bar {
      .btn {
        display: inline-block;
      }
    }
  }

  @media (max-width: $screen-tn-max) {
    .download-bar {
      .btn {
        margin-top: 15px;
      }
    }
  }
}

.ngdialog.fedelzet-description-dialog-wrapper {
  .ngdialog-overlay {
    pointer-events: none;
  }

  .ngdialog-content {
    background: #fefefe;
    width: 750px;
    text-align: justify;

    iframe {
      margin: 20px auto;
      display: block;
    }

    h3 {
      text-align: center;
    }

    hr.short-hr {
      margin-left: auto;
      margin-right: auto;
    }
  }
}