.ngdialog.ngdialog-theme-default.user-dialog-wrapper {
  text-align: center;
  white-space: nowrap;
  padding-top: 0;
  padding-bottom: 0;

  @include dib-mid(".dialog-content");

  .ngdialog-content {
    display: inline-block;
    vertical-align: middle;
    border-radius: 0;
    padding: 0;
    max-width: 95%;
    width: auto;
  }
}

.user-dialog {
  display: inline-block;
  padding: 75px 92px;
  width: 550px;
  max-width: 100%;
  text-align: left;
  white-space: normal;
  background: linear-gradient(to top, $main-color, $main-color-2);

  .close-wrapper {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 2.5rem;

    a {
      color: white;
    }
  }

  select option {
    color: black;

    &:first-child {
      background-color: #f0f0f0;
      color: #999;
    }
  }

  .checkbox {
    color: white;
  }

  .has-error {
    .form-control {
      border-color: red;
    }
  }

  .btn-primary {
    background-color: white;
    border-color: white;

    color: #333;
  }

  .btn-link {
    color: #333;
  }

  .social-buttons {
    text-align: center;
    margin: 15px 0;
    padding-top: 15px;
    border-top: 1px solid #FFF;

    .col {
      &:first-child {
        padding-left: 0;
        padding-right: 3px;
      }

      &:last-child {
        padding-left: 3px;
        padding-right: 0;
      }
    }

    .btn {
      //display: inline-block;
      //width: auto;
      text-align: center;

      .button-text {
        //font-size: 1.2rem;
      }
    }
  }

  .dialog-header-img {
    margin-bottom: 50px;
    max-width: 100%;
  }

  @media (max-width: 599px) {
    padding-left: 35px;
    padding-right: 35px;

    .social-buttons {
      .col,
      .col:first-child,
      .col:last-child {
        padding: 0;
      }

      .btn {
        margin-bottom: 8px;
      }
    }
  }
}

register-dialog.user-dialog {
  width: 850px;
  display: block;

  .dialog-header-img {
    max-width: 350px;
  }

  hr.short-hr {
    width: auto;
    background-color: rgba(0, 0, 0, 0.6);
    height: 2px;
    margin-top: 25px;
    margin-bottom: 15px;
  }

  button[type="submit"] {
    margin-top: 30px;
  }

  @media (min-width: 600px) {
    padding-left: 77px;
    padding-right: 77px;
  }
}

.ngdialog.video-dialog-wrapper,
.ngdialog.home-dialog-wrapper {
  @include dib-mid(".ngdialog-content");
  text-align: center;

  .ngdialog-content {
    max-width: 750px;
    width: 750px;
    @media (max-width: 768px) {
      width: 95%;
    }
    padding: 2em;

    img {
      width: 100% !important;
      height: auto !important;
    }

    .video-wrapper {
      @include fluid-aspect(16 9);
    }

    .close-wrapper {
      position: absolute;
      top: 6px;
      right: 9px;
      font-family: Arial, sans-serif;
      font-size: 2.2rem;
      font-weight: bold;
    }
  }
}

.ngdialog.adult-dialog-wrapper {
  @include dib-mid("> .ngdialog-content");
  text-align: center;

  .ngdialog-overlay {
    background-color: #fbfbfb;
  }

  .ngdialog-content {
    background-color: transparent;
    text-align: center;
    font-family: $title-font;

    .dialog-title {
      font-size: 3.6rem;
      margin-top: 25px;
      color: #e90000;
    }

    hr {
      border-color: rgba(0, 0, 0, 0.45);
    }

    .dialog-question {
      font-size: 2.4rem;
    }

    .dialog-buttons {
      margin-top: 20px;

      .btn {
        font-size: 1.8rem;

        + .btn {
          margin-left: 15px;
        }

        .fa {
          font-size: 2.2rem;
          margin-right: 8px;
        }
      }
    }
  }
}

.circle-18 {
  $circle-size: 6.5rem;
  $circle-size-small: 4.2rem;

  font-weight: bold;
  font-size: $circle-size * 0.7;
  border: ($circle-size * 0.1) solid #e90000;
  line-height: $circle-size;
  display: inline-block;
  border-radius: 50%;
  height: $circle-size;
  width: $circle-size;
  box-sizing: content-box;
  text-align: center;
  vertical-align: middle;

  &:before {
    content: '18';
    display: inline-block;
    margin-left: 8%;
    margin-top: 3%;
  }

  &.circle-18-small {
    border-width: $circle-size-small * 0.1;
    font-size: $circle-size-small * 0.7;
    line-height: $circle-size-small;
    height: $circle-size-small;
    width: $circle-size-small;
  }
}

.ngdialog.forgot-password-dialog-wrapper,
forgot-password-continue-page {
  h2 {
    margin-top: 0;
    margin-bottom: 50px;
    text-align: center;
    color: white;
  }
}
