main-menu-dropdown {
  $dropdown-height: 200px;
  $dropdown-padding: 17px;
  $full-dropdown-height: $dropdown-height + 2*$dropdown-padding;

  display: block;
  white-space: normal;
  transition: .5s height ease-in-out;
  height: 0;
  overflow: hidden;

  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  //z-index: 802;

  .dropdown-content {
    background-color: rgba(white, .94);
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: $dropdown-padding 0;
    height: $full-dropdown-height;

    .container > .row > .col {
      min-height: $dropdown-height;

      // Custom widths
      &:nth-child(2) {
        @media (min-width: $screen-sm-min) {
          width: 22%;
        }
      }

      &:nth-child(3) {
        @media (min-width: $screen-sm-min) {
          width: 36.33333%;
        }
      }
    }

    .ng-enter {
      opacity: 0;
      transition: opacity .4s;
    }

    .ng-enter.ng-enter-active {
      opacity: 1;
    }

    .ng-leave {
      opacity: 1;
      transition: opacity .4s;
    }

    .ng-leave.ng-leave-active {
      opacity: 0;
    }
  }

  .category-subtitle {
    @extend .category-color;

    font-family: $title-font;
    font-size: 1.6rem;
    line-height: 1.2;
  }

  .small-separator {
    @extend .category-bg-color;

    margin: 11px 0 3px;
    display: inline-block;
    width: 56px;
    height: 4px;
  }

  .category-description {
    font-family: $title-font;
    font-size: 1.2rem;
    text-align: justify;
  }

  .col.sideways-text-col {
    padding-left: 37px;

    .sideways-text-wrapper {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 32px;
      border-right: 1px solid rgba(black, .39);

      &:before {
        content: '';
        display: block;
        width: 4px;
        height: 56px;
        position: absolute;
        top: 0;
        right: -3px;
      }

      .sideways-text {
        position: absolute;
        right: 50%;
        top: -16px;
        transform: rotate(-90deg);
        transform-origin: right;
        white-space: nowrap;

        font-family: $title-font;
        text-transform: uppercase;
        font-weight: bold;
        color: #2e2e2e;
        font-size: 2.4rem;
      }
    }
  }

  .col.latest-col {
    padding-left: 44px;

    a {
      color: black;
      transition: color .2s;

      &:hover {
        color: #666;
      }
    }

    .col {
      padding: 0 3px;
    }

    .post {
      .post-image-wrapper {
        //max-width: 96%;
        margin: 0 auto;
      }

      .post-meta {
        margin-top: 3px;
        font-size: 1.1rem;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .post-author {
          margin-right: 5px;
        }

        .post-date {
          color: rgba(black, .7);
        }
      }

      .post-title {
        margin-top: 3px;
        font-family: $title-font;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1.1rem;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .post-summary {
        margin-top: 3px;
        font-size: 1.1rem;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  @media (min-width: $screen-md-min) {
    li:hover & {
      height: $full-dropdown-height;
    }
  }

  a {
    color: white;
  }

  .meta .author,
  .meta .date {
    color: white;
  }

  .submenu-items {
    li {
      a {
        line-height: 2.8;
        transition: color .3s;
        font-family: $headings-font-family;

        &:hover {
          color: $main-color-2;
        }
      }
    }
  }

  .container {
    //padding-left: 7px;
    //padding-right: 7px;

    @media (min-width: $screen-lg-min) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .row {
    margin-left: -7px;
    margin-right: -7px;
  }

  .col {
    padding-left: 7px;
    padding-right: 7px;
  }
}