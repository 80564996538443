main-header {
  font-family: $headings-font-family;
  $header-body-bg: #1a1a1a;
  //background-color: $header-body-bg;

  display: block;
  z-index: 801;
  position: relative;
  top: 0;
  width: 100%;

  .list-inliner {
    @extend .list-inline;

    margin: 0;
    padding: 0;
    display: inline-block;
  }

  ul.menu-items {
    > li > a {
      &:not(.btn) {
        color: white;
        font-weight: 600;
      }
    }
  }

  .header-top {
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 1;
    display: block;
    position: relative;
    background: white;

    .header-top-background {
      background: linear-gradient(to bottom, $main-color-2 0%, $main-color 100%);
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: .15s opacity;
    }

    .menu-items.menu-items-top {
      $btn-blue-1-bg-color: #169ce5;
      $btn-purple-1-bg-color: #c72df4;
      $btn-hover-darken-amount: 10%;
      $space-between-lines: 5px;

      margin-top: -$space-between-lines;

      li {
        padding-top: $space-between-lines;

        .btn-blue-1,
        .btn-purple-1 {
          color: white;
        }

        .btn-blue-1 {
          background-color: $btn-blue-1-bg-color;

          &:hover {
            background-color: darken($btn-blue-1-bg-color, $btn-hover-darken-amount);
          }
        }

        .btn-purple-1 {
          background-color: $btn-purple-1-bg-color;

          &:hover {
            background-color: darken($btn-purple-1-bg-color, $btn-hover-darken-amount);
          }
        }
      }
    }

    .subscription-expires {
      color: white;
      border-right: 1px solid white;
      padding-right: 6px;
      margin-right: 6px;

      .value {
        margin-left: .2em;
      }

      @media (max-width: $screen-sm-max) {
        display: none;
      }
    }

    .container {
      //height: 24px;
      position: relative;

      @media (min-width: $screen-lg-min) {
        padding: 0;
      }
    }

    .menu-items {
      li {
        padding: 0 5px;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        .fa {
          font-size: 2.1rem;
        }

        a {
          display: inline-block;
          vertical-align: middle;

          &:not(.btn) {
            transition: color .3s;
            text-transform: none;

            &.active,
            &:hover {
              color: black;
            }
          }
        }
      }
    }

    .user-top-wrapper {
      white-space: nowrap;

      .user-top-guest,
      .user-top-loggedin {
        display: inline-block;
      }

      .user-top-loggedin {
        ul {
          margin: 0;

          li {
            //margin-right: 8px;

            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .welcome-message {
          //margin-right: 10px;

          .user-name {
            margin-left: 5px;
          }
        }

        .fa-caret-down {
          font-size: 1.8rem;
          vertical-align: middle;
          margin-left: 3px;
        }

        .user-image {
          border-radius: 50%;
          height: 26px;
        }

        [uib-dropdown-toggle] {
          cursor: pointer;
          color: white;
        }

        .dropdown-menu {
          border-radius: 0;
          left: auto;
          right: 0;
          width: 292px;
          // Todo: wind a way to max-width with respect to the body
          //max-width: 100%;
          text-align: right;
          white-space: normal;

          margin-top: 5px;
          padding: 35px 41px;

          ul {
            li {
              margin-bottom: 24px;

              &:last-child {
                margin-bottom: 0;
              }

              a {
                text-transform: uppercase;
              }
            }
          }
        }

        .gear-icon-container {
          display: inline-block;
          position: relative;

          .black {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
          }
        }
      }
    }

    @media (max-width: $screen-sm-max) {
      display: none;
    }
  }

  .header-body {
    background: rgba($header-body-bg, .9);
    padding-top: 18px;
    padding-bottom: 18px;
    transition: padding .2s, background-color .2s;

    > .container-fluid {
      height: 42px;
      //position: relative;

      @media (min-width: $screen-lg-min) {
        padding: 0;
      }
    }

    .header-logo {
      display: inline-block;

      .header-logo-icon {
        height: 26px;
      }

      .header-logo-text {
        width: 120px;
        margin-left: 5px;
        transition: width .2s, margin .2s;
      }

      @media (max-width: $screen-sm-max) {
        margin-top: 5px;
      }

      @media (max-width: 445px) {
        .header-logo-text {
          display: none;
        }
      }
    }

    .main-menu {
      > li {
        padding: 0;

        > a {
          display: inline-block;
          vertical-align: middle;
          padding: 14px 0 11px;
          border-bottom: 3px solid transparent;
          transition: color .3s, border-color .3s;
          line-height: 1;
          text-transform: uppercase;

          &.active,
          &:hover {
            color: #cccccc;
            border-bottom-color: white;
          }
        }

        &:before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 1px;
          height: 10px;
          background-color: rgba(white, 0.4);
          margin: 0 8px;
        }
      }

      white-space: nowrap;
    }

    .header-body-right-wrapper {
      position: relative;

      .header-body-right {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 2.0rem;
        height: 42px;
        //background: $header-body-bg;

        ul {
          vertical-align: middle;

          li {
            margin-left: 5px;

            a {
              transition: .3s color;

              &:hover {
                color: $main-color-2;
              }
            }
          }
        }
      }
    }

    .sticky-visible {
      visibility: hidden;
      position: absolute;
      right: 20000px;
      transform: scale(0);
      transition: transform .2s;
    }

    .user-icon-wrapper {
      svg {
        height: 20px;
        display: inline-block;
        width: auto;
        vertical-align: middle;
        margin-top: -4px;

        path {
          fill: white;
          transition: fill .3s;
        }
      }

      &:hover {
        svg {
          path {
            fill: $main-color-2;
          }
        }
      }
    }
  }

  .header-cell {
    .cell-title {
      @include montserrat($font-weight-normal);

      color: black;
      font-size: 1.4rem;
      text-transform: uppercase;
      text-align: center;
    }

    &.user-top-wrapper {
      .cell-title {
        color: #fefefe;
        font-size: 1rem;
        text-align: right;
        padding-bottom: .4rem;
        transition: color .25s;
      }
    }

    .user-top-loggedin {
      [ui-sref="purchasedPackages"] {
        font-weight: $font-weight-bold;
      }
    }
  }

  #paypal-form {
    display: none;
  }

  .header-nav-cell {
    .header-nav-cell-item {
      display: inline-block;
      vertical-align: middle;
      margin-left: 1rem;
    }

    .cell-title {
      .line {
        display: block;

        &:nth-child(1) {
          font-weight: $font-weight-bold;
        }

        &:nth-child(2) {
          font-size: 0.8em;
          text-align: center;
        }
      }
    }

    .header-nav-button {
      @include montserrat($font-weight-bold);

      display: inline-block;
      color: #000000;
      background-color: #fefefe;
      border: 1px solid transparent;
      border-radius: .6rem;
      text-transform: uppercase;
      padding: .8rem;
      line-height: 1;
      font-size: 1.5rem;
      transition: color .25s, background-color .25s;

      &[ui-sref="premiumPackages"] {
        font-size: 1.8rem;

        .fa {
          margin-right: 0.3em;
        }

        .magadert {
          font-size: 0.8em;

          .bigger-letters {
            font-size: 1.25em;
          }
        }

        @keyframes header-nav-button-blink {
          from {
            color: #000000;
            background-color: #fefefe;
          }

          to {
            color: #fefefe;
            background-color: #000000;
          }
        }
      }

      &:hover {
        color: #fefefe;
        background-color: #000000;
      }
    }
  }

  &.is_stuck {
    position: fixed;

    .header-cell {
      &.user-top-wrapper {
        .cell-title {
          color: black;
        }
      }
    }

    .header-top {
      .header-top-background {
        opacity: 0;
      }

      ul {
        li {
          a {
            &:not(.btn) {
              color: #404040;
            }
          }
        }
      }

      .user-top-wrapper {
        .user-top-loggedin {
          [uib-dropdown-toggle] {
            color: black;
          }

          .gear-icon-container {
            .black {
              opacity: 1;
            }
          }
        }
      }
    }

    .header-body {
      padding-top: 12px;
      padding-bottom: 12px;

      .sticky-visible {
        visibility: visible;
        transform: scale(1);
        position: static;
      }

      .header-logo-text {
        width: 0;
      }

      .main-menu > li {
        &:before {
          margin-left: 6px;
          margin-right: 6px;
        }

        > a {
          font-size: 1.3rem;
        }

        &.extra-menu-item {
          > a {
            color: $main-color-2;
          }
        }
      }
    }

    .btn-white {
      color: rgba(white, .9);
      background-color: #404040;

      &:hover {
        color: black;
        background-color: rgba(white, .9);
        border: 1px solid #404040;
      }
    }

    .header-nav-button {
      color: #fefefe;
      background-color: #000000;

      &:hover {
        color: #000000;
        background-color: #fefefe;
        border: 1px solid #000000;
      }
    }
  }

  .btn {
    text-transform: uppercase;
    font-weight: 600;
    font-family: $headings-font-family;
    transition: background-color .3s, color .3s;
    padding-top: 6px;
    padding-bottom: 6px;
    line-height: 1;
    font-size: 1.2rem;
    border-radius: 2px;
    border: 1px solid transparent;
  }

  .btn-white {
    color: black;
    background-color: rgba(white, .9);

    &:hover,
    &:focus {
      color: white;
      background-color: rgba(black, .9);
    }
  }

  .btn-blue {
    color: white;
    background-color: rgba($tiszta-szex-color, .9);

    &:hover,
    &:focus {
      color: white;
      background-color: rgba(lighten($tiszta-szex-color, 20%), .9);
    }
  }

  .text-lowercase {
    text-transform: lowercase !important;
  }

  .header-banner {
    padding: 7px;
    background-color: #1c1f20;
  }

  @media (max-width: $screen-md-max) {
    .header-nav-cell {
      .header-nav-button {
        font-size: 1.3rem;

        &[ui-sref="premiumPackages"] {
          font-size: 1.6rem;
        }
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    .header-nav-cell {
      text-align: center;

      .header-nav-button {
        &[ui-sref="premiumPackages"] {
          margin-bottom: .7rem;
        }
      }
    }
  }
}

// search dialog
.ngdialog.ngdialog-theme-default.search-dialog-wrapper {
  padding: 0;
  text-align: center;

  @include dib-mid('> .ngdialog-content');

  .ngdialog-overlay {
    background-color: rgba(0, 0, 0, .9);
  }

  .ngdialog-content {
    text-align: left;
    background: none;
    padding: 0;
    width: 60%;

    search-dialog {
      display: block;

      .input-container {
        border-bottom: 2px solid #909090;
        padding-bottom: 5px;

        .t-cell {
          vertical-align: middle;
        }

        input {
          display: block;
          width: 100%;
          background: none;
          border: none;
          //font-family: $title-font;
          font-size: 3.5rem;
          color: white;

          @include placeholder-style {
            color: #a9a9a9;
          }
        }

        .btn {
          border: none;
          padding: 0;
          font-size: 3.0rem;
          color: #a9a9a9;

          &:hover {
            color: white;
          }
        }
      }
    }

    @media (max-width: $screen-xs-max) {
      width: 90%;
    }
  }

  .close-wrapper {
    position: fixed;
    right: 25px;
    top: 25px;

    .btn {
      font-size: 3.5rem;
      color: #a9a9a9;
      cursor: pointer;

      &:hover {
        color: white;
      }
    }
  }
}
