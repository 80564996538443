@import 'site/variables';
@import 'site/plugins';
@import 'site/fonts';
@import 'site/utility';
@import 'site/mixins';

@import 'site/theme';
@import 'site/general';
@import 'site/buttons';
@import 'site/home';
@import 'site/header';
@import 'site/submenu';
@import 'site/footer';
@import 'site/form';
@import 'site/dialogs';
@import 'site/profile';
@import 'site/article';
@import 'site/pages';
@import 'site/category';
@import 'site/content-page';
@import 'site/author';
@import 'site/toasts';
@import 'site/colors';
@import 'site/fedelzet';
@import 'site/tiszta-szex';
@import 'site/forum';
@import 'site/sidebar';
@import 'site/premium';
@import 'site/banner';
@import 'site/premium-pages';
@import 'site/premium-packages';
@import 'site/premium-package';
@import 'site/purchased-packages';
@import 'site/video-player';

html {
  height: 100%;
}

body {
  a {
    color: #52AA7F;
  }

  .container {
    overflow: hidden;
  }

  .header-top > .container-fluid {
    overflow: visible;
    position: relative;
  }

  &.header-padding {
    padding-top: 114px;

    @media (max-width: $screen-sm-max) {
      padding-top: 78px;
    }
  }

  &.body-w-flowers {
    min-height: 100%;
    background-image: url(/images/mamagesavalaszol_bg.jpg);
    background-attachment: fixed;
    background-position: center center;
  }

  .newsletter-dialog-wrapper {
    .ngdialog-content {
      background-color: white!important;
    }
  }

  .intro-slider-inner {
    .slick-slide {
      > div {
        position: relative;
        width: 100%;
        height: 100%;
        .slide {
          position: relative;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

question-page {
  display: block;

  .above-form-image {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .captcha {
    margin-bottom: 20px;

    > div > div {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .btn {
    font-size: 3rem;
    letter-spacing: 2px;
    padding: 10px 12px;

    $button-bg: #b109ff;

    background-color: $button-bg;
    border-color: darken($button-bg, 15%);
    transition: background-color .3s, border-color .3s;

    &:hover {
      background-color: darken($button-bg, 15%);
      border-color: darken($button-bg, 30%);
    }

    @media (max-width: $screen-md-max) {
      font-size: 2.5rem;
    }

    @media (max-width: $screen-xs-max) {
      font-size: 2rem;
    }
  }
}
