@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin fluid-aspect($ratio: 1 1, $selector: "> :first-child") {
  $selector: unquote($selector);

  padding-bottom: percentage(nth($ratio, 2) / nth($ratio, 1));
  position: relative;

  #{$selector} {
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.centered-huge-img {
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

// http://stackoverflow.com/a/17181946
// http://stackoverflow.com/a/20320475
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder-style {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

.img-responsive-i {
  display: block !important;
  max-width: 100% !important;
  height: auto !important;
}

.full-width {
  width: 100%;
}

.img-round {
  border-radius: 50%;
}

@mixin dib-mid($selector: "> :first-child") {
  $selector: unquote($selector);

  white-space: nowrap;

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  #{$selector} {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
  }
}

.dib-mid {
  @include dib-mid("> *");
}

.v-mid {
  vertical-align: middle;
}

.bordered-img {
  border: $image-border;
}

.t-table {
  display: table;
  width: 100%;
}

.t-cell {
  display: table-cell;
}

.t-cell-shrink {
  width: 1px;
}

.div-img {
  background-size: cover;
  background-position: center;
}

.circled {
  border-radius: 50%;
}

.space-left {
  margin-left: 0.3rem;
}

.space-right {
  margin-right: 0.3rem;
}

.ratio-5-3 {
  @include aspect-ratio(5, 3);
}

.ratio-1-1 {
  @include aspect-ratio(1, 1);
}

.text-bold {
  font-weight: $font-weight-bold !important;
}

// http://stackoverflow.com/a/22799354
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-5ths {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }
}