$font-family-base: 'Open Sans', sans-serif;
$headings-font-family: $title-font;
@import 'bootstrap-custom';

$screen-tn-max: $screen-xs-min - 1px;

.col-tn-1,
.col-tn-2,
.col-tn-3,
.col-tn-4,
.col-tn-5,
.col-tn-6,
.col-tn-7,
.col-tn-8,
.col-tn-9,
.col-tn-10,
.col-tn-11,
.col-tn-12 {
  position: relative;
  // Prevent columns from collapsing when empty
  min-height: 1px;
  // Inner gutter via padding
  padding-left: ($grid-gutter-width / 2);
  padding-right: ($grid-gutter-width / 2);
}

@media (max-width: $screen-tn-max) {
  @include make-grid(tn);
}

// FontAwesome
$fa-font-path: "../../lib/components-font-awesome/fonts";
@import '../../lib/components-font-awesome/scss/font-awesome';

// ngDialog
@import '../../../node_modules/ng-dialog/css/ngDialog';
@import '../../../node_modules/ng-dialog/css/ngDialog-theme-default';

// Bootstrap social
@import '../../../node_modules/bootstrap-social/bootstrap-social.scss';

// angular-loading-bar
@import '../../../node_modules/angular-loading-bar/build/loading-bar';

// Angular Moment Picker
//@import '../../../node_modules/angular-moment-picker/dist/angular-moment-picker';

// Dropzone
@import '../../../node_modules/dropzone/dist/dropzone';
//@import '../../../../NgExhaleForms/scss/dropzone';

// Cropper.js
@import '../../../node_modules/cropperjs/dist/cropper';

//@import '../../lib/animate/';
//@import '../../lib/swiper/dist/css/swiper';

// Slick carousel
//../../../node_modules/slick-carousel/slick/slick.scss
@import '../../../node_modules/slick-carousel/slick/slick.scss';
@import '../../../node_modules/slick-carousel/slick/slick-theme.scss';

// AngularJS-Toaster
@import '../../../node_modules/angularjs-toaster/toaster.scss';

// Sidr
@import 'sidr';

ng-swiper {
  display: block;
  position: relative;

  .swiper-button-prev {
    left: -47px;
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z' fill='#52AA7F'/></svg>");
  }

  .swiper-button-next {
    right: -47px;
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z' fill='#52AA7F'/></svg>");
  }
}

// UI Bootstrap
.glyphicon {
  display: inline-block;
  font-family: FontAwesome !important;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.glyphicon-chevron-left:before {
  content: "\f053";
}
.glyphicon-chevron-right:before {
  content: "\f054";
}
.glyphicon-chevron-up:before {
  content: "\f077";
}
.glyphicon-chevron-up:before {
  content: "\f077";
}

ui-datepicker {
  .dropdown-menu {
    padding: 5px;
  }
}

// Parallax container
[parallax-container] {
  overflow: hidden;
  position: relative;

  [parallax-element] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    //height: 100%;
  }

  .parallax-content {
    position: relative;
    z-index: 2;
  }
}