// https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#Common_weight_name_mapping
$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

@font-face {
  font-family: 'Montserrat';
  src: url('../../fonts/montserrat-hairline.woff2') format('woff2'),
  url('../../fonts/montserrat-hairline.woff') format('woff'),
  url('../../fonts/montserrat-hairline.ttf') format('truetype');
  font-weight: $font-weight-thin;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../fonts/montserrat-semibold.woff2') format('woff2'),
  url('../../fonts/montserrat-semibold.woff') format('woff'),
  url('../../fonts/montserrat-semibold.ttf') format('truetype');
  font-weight: $font-weight-semi-bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../fonts/montserrat-bold.woff2') format('woff2'),
  url('../../fonts/montserrat-bold.woff') format('woff'),
  url('../../fonts/montserrat-bold.ttf') format('truetype');
  font-weight: $font-weight-bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../fonts/montserrat-extrabold.woff2') format('woff2'),
  url('../../fonts/montserrat-extrabold.woff') format('woff'),
  url('../../fonts/montserrat-extrabold.ttf') format('truetype');
  font-weight: $font-weight-extra-bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../fonts/montserrat-regular.woff2') format('woff2'),
  url('../../fonts/montserrat-regular.woff') format('woff'),
  url('../../fonts/montserrat-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../fonts/montserrat-light.woff2') format('woff2'),
  url('../../fonts/montserrat-light.woff') format('woff'),
  url('../../fonts/montserrat-light.ttf') format('truetype');
  font-weight: $font-weight-light;
  font-style: normal;
}

@font-face {
  font-family: 'Transat';
  src: url('../../fonts/transat_black/transatblack.woff2') format('woff2'),
  url('../../fonts/transat_black/transatblack.woff') format('woff'),
  url('../../fonts/transat_black/transatblack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@mixin montserrat($weight)
{
  font-family: 'Montserrat', sans-serif !important;
  font-weight: $weight !important;
}

@mixin transat-black
{
  font-family: 'Transat', sans-serif !important;
  font-weight: 900 !important;
}