.general-page {
  display: block;

  .section-title-wrapper {
    background-color: white;
  }

  section {
    margin-top: 20px;
    margin-bottom: 65px;
  }
}

.results-page {
  .subtitle-info {
    font-family: $title-font;

    .matches-count {
      margin-top: 5px;
      font-size: 1.8rem;

      .result-count,
      .search-query {
        font-weight: bold;
        color: $main-color;
      }

      .result-count {
        margin-right: 5px;
      }

      .search-query {
        margin-left: 5px;
        font-style: italic;

        quotes: '„' '”';

        &:before {
          content: open-quote;
        }

        &:after {
          content: close-quote;
        }
      }
    }
  }

  .result-item {
    margin-bottom: 15px;

    .article-title {
      line-height: 1;
      font-size: 1.8rem;
      margin-bottom: 10px;
      font-family: $title-font;
      text-transform: uppercase;
      font-weight: bold;
    }

    .article-meta {
      margin-bottom: 10px;

      .author-link {
        margin-right: 10px;
      }

      .article-date {
        color: rgba(black, .7);
      }
    }
  }

  @media (max-width: $screen-tn-max) {
    .result-item {
      .article-title {
        margin-top: 10px;
      }
    }
  }
}

tag-page {
  .section-title {
    &:before,
    h2:before {
      content: '#';
    }
  }
}

authors-page {
  .section-title {
    margin-bottom: 20px;
  }

  .post {
    margin-bottom: 25px;

    .author-image {
      @include post-image(1 1);

      margin-bottom: 10px;
    }

    h4 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 10px;
    }

    .author-title,
    .author-introduction {
      font-size: 1.4rem;
      line-height: 1.1;
      height: 3 * 1.1 * 1.4rem; // Limit to 3 lines
      overflow: hidden;
    }

    .author-title {
      margin-bottom: 10px;
    }

    .author-introduction {
      quotes: '„' '”';
      font-style: italic;

      &:before {
        content: open-quote;
      }

      &:after {
        content: close-quote;
      }
    }
  }
}

login-page.general-page,
register-page.general-page,
forgot-password-continue-page.general-page {
  section {
    margin-top: 40px;

    .dialog-wrapper {
      text-align: center;
    }

    register-dialog {
      margin: 0 auto;
    }
  }
}

.extra-content-row {
  font-family: $title-font;
  margin-bottom: 20px;

  .content-date {
    line-height: 96px;
    font-weight: bold;
    font-size: 2.6rem;
    white-space: nowrap;
  }

  .content-box {
    background-color: #212121;
    border-radius: 7px;
    color: #fefefe;
    padding: 23px 15px;
    line-height: 1.2;

    .col:first-child {
      border-right: 1px solid #fefefe;

      .content-title {
        font-size: 2.2rem;
      }

      .content-subtitle {
        font-size: 2rem;
        font-weight: $font-weight-light;
      }

      .content-thumb {
        float: left;
        margin-right: 15px;

        img {
          height: 55px;
        }

        @media (max-width: $screen-tn-max) {
          float: none;
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }

    .content-length,
    .content-buttons .btn {
      display: inline-block;
    }

    .content-length {
      line-height: 51px;
      font-size: 1.6rem;
    }

    .content-buttons {
      float: right;

      .btn {
        background-color: #fefefe;
        color: #212121;
        font-weight: $font-weight-semi-bold;
        font-size: 2.6rem;
        text-transform: uppercase;
        border-radius: 7px;
        padding: 14px 35px 9px;
        border: 1px solid #c4c4c4;
        line-height: 1;
        transition: background-color .2s, color .2s;

        &:hover {
          background-color: transparent;
          color: #fefefe;
        }
      }

      .btn + .btn {
        margin-left: 10px;
      }
    }
  }

  @media (max-width: $screen-md-max) {
    .content-box {
      .col:last-child {
        text-align: center;
      }

      .content-buttons {
        float: none;
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    .content-date {
      line-height: 146px;
    }

    .content-box {
      .col:last-child {
        text-align: center;
      }

      .content-length {
        line-height: 1;
        margin-top: 10px;
        margin-bottom: 15px;
      }

      .content-buttons {
        .btn {
          font-size: 2.3rem;
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    .content-date {
      line-height: 186px;
    }

    .content-box {
      .col:first-child {
        border-right: none;
        text-align: center;
        padding-bottom: 15px;
      }
    }
  }

  @media (max-width: $screen-tn-max) {
    .content-date {
      line-height: 1;
      text-align: center;
      margin-bottom: 15px;
    }

    .content-box {
      .content-length {
        display: block;
        text-align: center;
        line-height: 1;
        margin-bottom: 15px;
      }

      .content-buttons {
        .btn {
          display: block;
          margin: 0 auto 10px;
        }

        .btn + .btn {
          margin-left: 0;
        }
      }
    }
  }
}

downloads-page,
purchased-packages-page {
  .download-thumb {
    float: left;
    margin-right: 15px;
    margin-top: -8px;
    margin-bottom: -10px;
    border: 1px solid #ccc;
  }

  .no-downloads {
    text-align: center;
    text-transform: uppercase;
    font-family: $title-font;
    font-size: 2.2rem;
    color: $tiszta-szex-color;
    margin-top: 20px;
  }
}

.important-buying-note {
  text-align: center;
  color: red;
  font-size: 2rem;
  margin-bottom: 1.8rem;
}

.btn.btn-blue-small {
  white-space: normal;
  text-align: center;
  font-size: 1.8rem;
  font-weight: normal;
  color: white;
  background-color: $tiszta-szex-color;
  transition: color .2s, background-color .2s;

  &:hover, &:focus, &:active {
    background-color: $tiszta-szex-color-light;
    color: white;
  }
}