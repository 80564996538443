main-sidebar.sidr {
  $sidebar-font-size: 15px; // must be px for arithmetic purposes
  $sidebar-button-line-height: 2.8;

  display: block;

  font-family: $title-font;
  text-transform: uppercase;
  font-size: $sidebar-font-size;

  .sidebar-logo {
    .t-table {
      width: 85%;
      margin: 20px auto;

      .t-cell:first-child {
        padding-right: 3px;
      }

      .t-cell:last-child {
        padding-left: 3px;
      }
    }
  }

  .subscription-expires {
    text-align: center;

    .value {
      margin-left: .2em;
    }
  }

  ul.sidebar-user {
    $button-light-side: #75bd9a;
    $button-dark-side: #295640;

    li {
      border-top: 1px solid $button-light-side;
      border-bottom: 1px solid $button-dark-side;
      background-color: $main-color;
    }

    .newsletter {
      width: 98%;
      text-align: center;
    }

    &.user-guest {
      white-space: nowrap;

      li {
        display: inline-block;

        &:first-child {
          border-right: 1px solid $button-dark-side;
        }

        &:last-child {
          border-left: 1px solid $button-dark-side;
        }

        a {
          font-size: 1.3rem;
          padding: 5px 12px;
        }
      }
    }

    &.user-loggedin {
      text-transform: none;

      li {
        text-align: center;

        .text,
        img {
          vertical-align: middle;
        }

        .name {
          margin-left: 5px;
        }

        img {
          border-radius: 50%;
          margin-left: -35px;
          margin-right: 8px;
          width: 35px;
          height: 35px;
        }
      }

      ul {
        border-top: 0;
        border-bottom: 0;
        margin-bottom: 0;


      }
    }
  }

  .user-loggedin-container {
    position: relative;
    overflow: hidden;
    height: $sidebar-font-size * $sidebar-button-line-height + 2 + 2; // + li borders + ul borders
    margin-bottom: 10px;
    transition: height .3s;

    &.open {
      // the last +1 comes from the first item being 1px taller than the rest. I don't know why.
      height: 3 * ($sidebar-font-size * $sidebar-button-line-height + 2) + 2 + 1;

      .user-loggedin {
        .user-dropdown-toggle {
          background-color: $main-color-2;
        }
      }
    }

    .user-loggedin,
    .user-loggedin-dropdown {
      margin-bottom: 0;
    }

    .user-loggedin {
      border-bottom: 0;
      position: relative;
      z-index: 2;

      .user-dropdown-toggle {
        box-shadow: none;
        padding: 0 15px;
        line-height: 2.8;
      }
    }

    .user-loggedin-dropdown {
      border-top: 0;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }

  > ul,
  .user-loggedin-container > ul {
    margin-bottom: 10px;

    li,
    li:hover {
      line-height: $sidebar-button-line-height;
    }
  }
}

#paypal-mobile-clicker {
  padding-left: 13px;
  background-color: $main-color;
}