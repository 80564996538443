#toast-container {
  left: 50%;
  right: auto;
  transform: translateX(-50%);

  .toast {
    width: auto;
    display: inline-block;
    font-size: 1.6rem;
    opacity: .95;

    &:hover {
      opacity: 1;
    }
  }
}