premium-page {
  .subscription-expires-panel {
    .panel-body {
      text-align: center;
    }
  }

  .auto-renew-duration-panel {
    li {
      + li {
        margin-top: 10px;
      }

      .btn {
        text-align: left;

        span:last-child {
          float: right;
        }
      }
    }
  }
}