.btn-blue-clear {
  background-color: $tiszta-szex-color;
  border-radius: 9px;
  color: white;
  font-weight: $font-weight-light;
  padding-top: .9rem;
  padding-bottom: .9rem;

  transition: color .2s, background-color .2s;

  &:hover,
  &:focus {
    background-color: $tiszta-szex-color-light;
    color: white;
  }

  .price {
    font-weight: bold;
  }
}