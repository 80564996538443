category-page {
  .section-title-wrapper {
    background: #fff;
    margin-bottom: 50px;
  }

  .section-title {
    margin-bottom: 15px;

    h2 {
      color: black;

      &:after {
        @extend .category-bg-color;

        content: '';
        display: block;
        width: 56px;
        height: 4px;

        position: absolute;
        left: 6px;
        bottom: -8px;
      }
    }
  }

  category-slider {
    display: block;
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(#ccc, .48);

    .main-slider-wrapper {
      position: relative;

      .slider-nav {
        @extend .category-color;

        $nav-distance-from-edge: 15px;

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        //color: white;
        text-shadow: 0 0 2px black;
        cursor: pointer;
        font-size: 3rem;
        opacity: 0;
        transition: opacity .2s;

        &.slider-nav-prev {
          left: $nav-distance-from-edge;
        }

        &.slider-nav-next {
          right: $nav-distance-from-edge;
        }
      }

      &:hover {
        .slider-nav {
          opacity: 1;
        }
      }
    }

    [class*="col-"] {
      &:first-child {
        padding-right: 7px;
      }

      &:last-child {
        padding-left: 7px;
      }
    }

    //.main-slider,
    //.nav-slider .slide {
    //  border: 1px solid #999;
    //}

    .slider {
      .slide {
        position: relative;

        .slide-background {
          @extend .div-img;
        }

        .slide-content {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 60%;
          background: rgba(white, .95);
          padding: 15px 20px 10px 20px;

          .article-meta {
            font-size: 1.6rem;
            margin-bottom: 10px;

            .author-link {
              margin-right: 7px;
            }

            .article-author {
              color: rgba(black, .7);
            }
          }

          .article-title {
            font-family: $title-font;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 2.4rem;
            margin-bottom: 10px;
            line-height: 1.1;
          }

          .article-summary {
            font-size: 1.6rem;
            color: rgba(black, .7);
          }
        }
      }
    }

    .nav-slider {
      .slick-list {
        margin: -5px 0;
      }

      .slide {
        margin: 5px 0;
        width: 100%; // fix slider initializing issue

        .slide-content {
          width: 82%;
          padding: 8px;

          .article-meta {
            font-size: 1.2rem;
          }

          .article-title {
            font-size: 1.4rem;
            line-height: 1.15;
            margin-bottom: 0;
            word-break: break-word;

            max-height: 2 * 1.4rem * 1.1; // 2 lines
            overflow: hidden;
          }
        }

        &.slick-current {
          border-top-color: transparent;

          .slide-background {
            &:after {
              content: '';
              display: block;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 4px;

              @extend .category-bg-color;
            }
          }
        }
      }
    }

    @media (min-width: $screen-xs-min) {
      .slider {
        .slide {
          .slide-background {
            @include fluid-aspect(5 3);
          }
        }
      }
    }

    @media (max-width: $screen-xs-max) {
      .slider {
        .slide {
          .slide-content {
            width: 90%;
            padding: 15px 10px 10px 10px;

            .article-meta {
              font-size: 1.4rem;
            }

            .article-title {
              font-size: 2rem;
            }

            .article-summary {
              font-size: 1.4rem;
            }
          }
        }
      }
    }

    @media (max-width: $screen-tn-max) {
      .slider {
        .slide {
          .slide-background {
            @include fluid-aspect(3 5);
          }
        }
      }
    }
  }

  .title-middle {
    font-size: 2.4rem;
  }

  .most-viewed-col {
    .title-middle {
      position: relative;

      &:after {
        @extend .category-bg-color;

        content: '';
        display: block;
        width: 56px;
        height: 4px;
        position: absolute;
        left: 0;
        bottom: -3px;
      }
    }

    .tag-list {
      margin-bottom: 20px;
    }

    .post {
      .author-link {
        margin-right: 5px;
      }

      .post-image {
        margin-bottom: 20px;
      }

      h4 {
        text-transform: none;
      }
    }
  }

  .featured-tag-col {
    .title-middle {
      a {
        &:before {
          content: '#';
        }
      }
    }

    .post {
      h4 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .meta {
        line-height: 1.2;

        .author-link {
          margin-right: 5px;
        }
      }


      .post-image {
        margin-bottom: 10px;
      }
    }
  }
}