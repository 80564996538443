tiszta-szex-page {
  font-family: $title-font;

  .intro-text {
    font-size: 1.8rem;
    font-weight: $font-weight-light;
    margin-bottom: 38px;
  }

  .cover-image-wrapper {
    display: inline-block;
    padding: 7px;
    border: 2px solid $tiszta-szex-color;
  }

  .toc-row {
    margin-bottom: 29px;

    .toc-block {
      .toc-title {
        color: $tiszta-szex-color;
        text-transform: uppercase;
        margin-bottom: 20px;
        font-size: 2.5rem;
      }

      > ol {
        list-style: upper-roman;
        padding-left: 25px;
        font-size: 1.7rem;
        line-height: 1.5;

        > li {
          padding-left: 40px;

          li {
            padding-left: 10px;
          }
        }
      }
    }
  }

  .btn-blue,
  .btn-blue-small {
    margin: 0 0 24px;
  }

  .btn-blue {
    background-color: $tiszta-szex-color;
    text-transform: uppercase;
    font-size: 5.2rem;
    border-radius: 9px;
    color: white;
    font-weight: $font-weight-light;
    letter-spacing: 4px;
    padding-top: 18px;
    padding-bottom: 9px;
    transition: color .2s, background-color .2s;

    &:hover,
    &:focus {
      background-color: $tiszta-szex-color-light;
      color: white;
    }

    .price {
      font-weight: bold;
    }
  }

  .registration-warning,
  .download-instructions {
    text-align: center;
    font-size: 2.5rem;
    line-height: 1.2;
    margin-bottom: 35px;
  }

  .registration-warning {
    color: red;
  }

  .download-instructions {
    color: $tiszta-szex-color;
  }

  .paypal-logos {
    .t-table {
      width: auto;
      margin: 0 auto;
    }
  }

  @media (max-width: $screen-md-max) {
    .btn-blue {
      font-size: 4.6rem;
    }
  }

  @media (max-width: $screen-sm-max) {
    .btn-blue {
      letter-spacing: 2px;
    }
  }

  @media (max-width: $screen-tn-max) {
    .paypal-logos {
      .t-table,
      .t-table .t-cell {
        display: block;
      }

      .t-table .t-cell img {
        margin: 0 auto;
      }
    }

    .btn-blue {
      font-size: 2.8rem;
      letter-spacing: 0px;
      white-space: normal;
    }
  }
}