purchased-packages-page {
  .page-header {
    .relative-wrapper {
      height: 21.5rem;
    }

    .background-text {
      font-size: 8rem;
    }
  }

  .page-body {
    background-position-y: 22.4rem;

    h2 {
      margin-top: 5rem;
    }
  }

  @media (max-width: $screen-md-max) {
    .page-header {
      .background-text {
        font-size: 5.2rem;
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    .page-body {
      .package-list-item {

      }
    }
  }

  @media (max-width: $screen-xs-max) {
    .page-header {
      .relative-wrapper {
        height: 17.6rem;
      }
    }

    .page-body {
      background-position-y: 18.5rem;
    }
  }
}