
.transition {
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.translateX {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}

.translateY {
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}
/* Header Style
====================================*/

#header .panel-header {
  background-color: #1a1a1a;
}

#header .header-wrapper {
  height: 100px;
  position: relative;
}

#header .header-wrapper .site-branding {
  position: absolute;
  left: 0px;
  bottom: 20px;
}

#header .header-wrapper .site-branding a {
  // font-family: 'Georgia';
  font-size: 36px;
  font-weight: normal;
  display: inline-block;
  text-decoration: none;
  color: #ffffff;
  text-transform: uppercase;
  line-height: 1em;
}

#header .header-wrapper .site-branding p {
  color: rgba(255, 255, 255, 0.4);
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0px;
  line-height: 1em;
}

#header .header-wrapper .site-branding img {
  width: 337px;
  height: auto;
}

#header .header-wrapper .right-content {
  position: absolute;
  right: 0px;
  bottom: 0px;
  line-height: 65px;
  height: 65px;
}

#header .header-wrapper .right-content .user-profile {
  line-height: 0px;
}

#header .header-wrapper .right-content .user-profile .entry-notify {
  display: inline-block;
  position: relative;
  line-height: 65px;
  float: left;
  margin-right: 20px;
}

#header .header-wrapper .right-content .user-profile .entry-notify img {
  width: 21px;
  opacity: 0.3;
}

#header .header-wrapper .right-content .user-profile .entry-notify span {
  position: absolute;
  display: inline-block;
  width: 14px;
  height: 14px;
  line-height: 12px;
  text-align: center;
  font-size: 9px;
  // font-family: 'Georgia';
  background-color: #fff;
  border-radius: 50%;
  color: #333;
  top: 23px;
  right: -8px;
  text-indent: 1px;
}

#header .header-wrapper .right-content .user-profile .entry-name {
  position: relative;
  line-height: 65px;
  float: left;
}

#header .header-wrapper .right-content .user-profile .entry-name > a {
  font-size: 10px;
  font-family: 'Montserrat';
  font-weight: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
  margin-right: 20px;
  text-decoration: none;
}

#header .header-wrapper .right-content .user-profile .entry-name > a::after {
  content: '';
  font-family: Fontawesome;
  font-size: 16px;
  position: relative;
  top: 2px;
  margin-left: 7px;
}

#header .header-wrapper .right-content .user-profile .entry-name .entry-hover {
  position: absolute;
  top: 100%;
  right: 0px;
  visibility: hidden;
  opacity: 0;
}

#header .header-wrapper .right-content .user-profile .user-avatar {
  display: inline-block;
}

#header .header-wrapper .right-content .user-profile .user-avatar img {
  height: 65px;
  width: auto;
}

#header .header-wrapper .right-content .user-profile .follow-links {
  position: absolute;
  top: 0px;
  left: 100%;
  margin-left: 30px;
  white-space: nowrap;
  line-height: 65px;
  font-size: 10px;
  font-family: 'Montserrat';
  font-weight: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
}
@media screen and (max-width: 1499px) {

  #header .header-wrapper .right-content .user-profile .follow-links {
    display: none;
  }
}

#header .header-wrapper .right-content .user-profile .follow-links a {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.6);
  display: inline-block;
  margin-left: 3px;
}

#header nav.main-nav {
  position: relative;
  // font-family: 'SF UI Display';
  font-size: 14px;
  font-weight: 500;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  padding-top: 20px;
  padding-bottom: 10px;
  z-index: 999;
}

#header nav.main-nav > ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  display: block;
  margin-right: 60px;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

#header nav.main-nav > ul > li {
  display: inline-block;
  margin: 0px;
  padding: 0px;
  position: relative;

}

#header nav.main-nav > ul > li > a {
  color: #000000;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

#header nav.main-nav > ul > li:first-child:not(.menu-item-has-children) > a {
  padding-left: 0;
}

#header nav.main-nav > ul > li.current-menu-item > a {
  font-weight: 700;
}

#header nav.main-nav > ul > li::after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: #1a1a1a;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  height: calc(160%);
}

#header nav.main-nav > ul > li ul {
  display: block;
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  top: 100%;
  left: 0px;
  background-color: #1a1a1a;
  width: 186px;
  margin-top: 10px;
  visibility: hidden;
  opacity: 0;
}

#header nav.main-nav > ul > li ul li {
  padding: 10px 40px;
  line-height: 1em;
  position: relative;
  text-align: left;
}

#header nav.main-nav > ul > li ul li a {
  color: #ffffff;
  text-decoration: none;
  display: block;
  line-height: 1em;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
}

#header nav.main-nav > ul > li ul li:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

#header nav.main-nav > ul > li ul li.menu-item-has-children > a::after {
  content: '';
  font-family: Fontawesome;
  font-size: 15px;
  float: right;
}

#header nav.main-nav > ul > li ul li.menu-item-has-children:hover > ul {
  visibility: visible;
  opacity: 1;
  top: -30px;
  left: 180px;
}

#header nav.main-nav > ul > li.menu-item-has-children:hover > a {
  color: #ffffff;
}

#header nav.main-nav > ul > li.menu-item-has-children:hover::after {
  visibility: visible;
  opacity: 1;
}

#header nav.main-nav > ul > li.menu-item-has-children:hover > ul {
  visibility: visible;
  opacity: 1;
}

#header nav.main-nav > ul > li.menu-item-mega {
  /* Mega Menu Medium*/
}

#header nav.main-nav > ul > li.menu-item-mega > ul > li {
  overflow: hidden;
  padding-left: 0px;
  padding-right: 50px;
  padding-top: 20px;
  padding-bottom: 25px;
}

#header nav.main-nav > ul > li.menu-item-mega > ul > li:hover {
  background-color: transparent;
}

#header nav.main-nav > ul > li.menu-item-mega .mega-menu-lists {
  float: left;
  width: 186px;
}

#header nav.main-nav > ul > li.menu-item-mega .mega-menu-lists a {
  display: block;
  padding-left: 50px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

#header nav.main-nav > ul > li.menu-item-mega .mega-menu-lists a:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

#header nav.main-nav > ul > li.menu-item-mega .mega-menu-panel {
  margin-left: 230px;
  padding-top: 15px;
  color: #ffffff;
}

#header nav.main-nav > ul > li.menu-item-mega .mega-menu-panel a::after {
  display: none;
}

#header nav.main-nav > ul > li.menu-item-mega .mega-menu-panel .mega-menu-column {
  padding-left: 30px;
  padding-right: 30px;
}

#header nav.main-nav > ul > li.menu-item-mega .mega-menu-panel .mega-menu-column + .mega-menu-column::before {
  content: '';
  position: absolute;
  top: 0px;
  width: 2px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  left: 0;
}

#header nav.main-nav > ul > li.menu-item-mega .mega-menu-panel .mega-title {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  font-weight: 500;
  // font-family: 'SF UI Display';
  color: #ffffff;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

#header nav.main-nav > ul > li.menu-item-mega .mega-menu-panel .mega-title::after {
  content: '';
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.1);
}

#header nav.main-nav > ul > li.menu-item-mega .mega-menu-panel .post {



}

#header nav.main-nav > ul > li.menu-item-mega .mega-menu-panel .post .meta span {
  color: #ffffff;
}

#header nav.main-nav > ul > li.menu-item-mega .mega-menu-panel .post p {
  // font-family: 'Georgia';
  font-size: 14px;
  line-height: 1.3em;
  color: rgba(255, 255, 255, 0.7);
}

#header nav.main-nav > ul > li.menu-item-mega .mega-menu-panel .post.ps-medium {
  margin-bottom: 30px;
}

#header nav.main-nav > ul > li.menu-item-mega .mega-menu-panel .post.ps-medium h4 a {
  font-size: 16px;
  line-height: 20px;
  padding: 0px;
}

#header nav.main-nav > ul > li.menu-item-mega .mega-menu-panel .post.ps-large {
  margin-bottom: 25px;
}

#header nav.main-nav > ul > li.menu-item-mega .mega-menu-panel .post.ps-large a.video-player {
  position: absolute;
}

#header nav.main-nav > ul > li.menu-item-mega .mega-menu-panel .post.ps-large a.video-player::after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -6px;
  border-left: solid 9px #fff;
  border-top: solid 6px transparent;
  border-bottom: solid 6px transparent;
  margin-left: -3px;
  color: #fff;
  display: inline-block !important;
}

#header nav.main-nav > ul > li.menu-item-mega .mega-menu-panel .post.ps-large h4 a {
  font-size: 18px;
  line-height: 20px;
}

#header nav.main-nav > ul > li.menu-item-mega .mega-menu-panel .post.ps-small p {
  margin-bottom: 15px;
}

#header nav.main-nav > ul > li.menu-item-mega .mega-menu-panel .post.ps-small p a {
  font-size: 14px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.7);
}

#header nav.main-nav > ul > li.menu-item-mega.mm-medium .mega-menu-lists {
  float: none;
  width: auto;
  display: block;
  padding-left: 65px;
}

#header nav.main-nav > ul > li.menu-item-mega.mm-medium .mega-menu-lists a {
  display: inline-block;
  padding: 0px;
  margin-right: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}

#header nav.main-nav > ul > li.menu-item-mega.mm-medium .mega-menu-lists a::after {
  content: '';
  margin-left: 10px;
  font-family: Fontawesome;
}

#header nav.main-nav > ul > li.menu-item-mega.mm-medium .mega-menu-lists a:hover {
  background: transparent;
}

#header nav.main-nav > ul > li.menu-item-mega.mm-medium .mega-menu-panel {
  margin-left: 50px;
  margin-top: 25px;
}

#header nav.main-nav > ul > li.menu-item-mega.mm-medium .mega-menu-panel .post.ps-large {
  margin-bottom: 0px;
}

#header .right-content,
#header .left-content {
  position: absolute;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-top: 4px;
}

#header .right-content a,
#header .left-content a {
  display: inline-block;
  text-decoration: none;
}

#header .right-content a + a,
#header .left-content a + a {
  margin-left: 12px;
}

#header .right-content a img,
#header .left-content a img {
  width: 14px;
}

#header .left-content {
  position: relative;
  right: auto;
  left: 0;
}

#header .search-panel {
  position: relative;
  margin-right: 80px;
  visibility: hidden;
  opacity: 0;
  height: 0px;
  -webkit-transform: translateY(5px);
  -moz-transform: translateY(5px);
  transform: translateY(5px);
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

#header .search-panel form input {
  border: none;
  box-shadow: none;
  width: 100%;
  // font-family: 'Georgia';
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
  height: 48px;
  line-height: 48px;
}

#header .search-panel form button {
  display: none;
}

#header #search_handler {
  color: #000000;
  position: relative;
}

#header #search_handler img {
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

#header #search_handler::after {
  content: '';
  font-family: Fontawesome;
  font-size: 18px;
  position: absolute;
  top: 50%;
  margin-top: -13px;
  left: 0px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  transform: translateY(10px);
}

#header.show-search:not(.header-sticky) nav.main-nav > ul {
  height: 0px;
  visibility: hidden;
  overflow: 0;
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
}

#header.show-search .search-panel {
  display: block;
  position: relative;
  visibility: visible;
  opacity: 1;
  height: auto;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}

#header.show-search #search_handler::after {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}

#header.show-search #search_handler img {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
}

#header .panel-ticker {
  position: relative;
}

#header .panel-ticker .tt-el-ticker {
  margin-top: 30px;
  margin-bottom: 30px;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

#header .panel-ticker .tt-el-ticker strong {
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0px;
  margin-right: 5px;
}

#header .panel-ticker .tt-el-ticker .entry-arrows {
  margin-right: 5px;
}

#header .panel-ticker .tt-el-ticker .entry-arrows a {
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
}

#header .panel-ticker .tt-el-ticker .entry-arrows a img {
  width: 6px;
  position: relative;
  top: -1px;
}

#header .panel-ticker .tt-el-ticker .entry-ticker {
  font-size: 16px;
  // font-family: 'Georgia';
  font-weight: normal;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.75);
}

#header .panel-ticker .tt-el-ticker .entry-ticker span {
  visibility: hidden;
  opacity: 0;
}

#header .panel-ticker .tt-el-ticker .typed-cursor {
  font-size: 18px;
  margin-left: 3px;
}

#header.header-sticky .header-wrapper {
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

#header.header-sticky .header-wrapper .site-branding {
  position: relative;
  left: auto;
  float: left;
}

#header.header-sticky .header-wrapper .site-branding img {
  height: 23px;
  width: auto;
}

#header.header-sticky .header-wrapper {
  height: 85px;
}

#header.header-sticky .site-branding {
  bottom: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

#header.header-sticky .site-branding img {
  width: auto;
}

#header.header-sticky .post-title {
  font-size: 16px;
  line-height: 40px;
  font-family: 'Montserrat';
  color: #000000;
  text-transform: uppercase;
  margin-left: 40px;
}

#header.header-sticky .right-content {
  margin: 0;
  width: 120px;
  text-align: right;
  z-index: 1010;
}

#header.header-sticky nav.main-nav {
  border: none;
}

#header.header-sticky .tools {
  display: inline-block;
  position: relative;
}

#header.header-sticky .search-panel {
  background: #fff;
  width: 320px;
  position: absolute;
  right: 0;
  top: 75px;
  margin-right: 15px;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

#header.header-sticky .search-panel input {
  padding: 0 20px;
}

#header.header-sticky .search-panel img {
  display: none;
}

#header.header-sticky #search_handler::after {
  margin-top: -30px;
}

#header.sticky {
  background: #fafafa;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

#header .scroll-window-indicator {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  z-index: 1110;
}

#header .scroll-window-indicator .width {
  background-color: rgba(0, 0, 0, 0.7);
  height: 2px;
  width: 0;
}
/* Elements
====================================*/
/* Animate Element
------------------------------------ */

.beauty-hover {
  background: none;
  position: relative;
  overflow: hidden;
}

.beauty-hover:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: #f84432;
  z-index: -1;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.beauty-hover:hover:after {
  height: 0;
}
/* Address
------------------------------------ */

address abbr[title],
address abbr[data-original-title] {
  position: relative;
  padding-left: 35px;
  display: block;
  border-bottom: none;
}

address abbr[title] span,
address abbr[data-original-title] span {
  position: absolute;
  left: 0;
  top: 20px;
  font-size: 16px;
  line-height: 0;
  color: #4d4d4d;
}

address abbr[title] + abbr[title] {
  border-top: 1px solid;
  border-color: rgba(77, 77, 77, 0.1);
  margin-top: 10px;
  padding-top: 10px;
}

address abbr:first-child span {
  top: 12px;
}
/* Element
------------------------------------ */

.simple-tab-space .tab-panel {
  position: relative;
}

.simple-tab-space .tab-content {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  transform: translateY(20px);
}

.simple-tab-space .tab-content.active {
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  transition-delay: 0.2s;
  visibility: visible;
  opacity: 1;
  position: relative;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}

.simple-tab-space .tab-title {
  margin: 20px 0;
}

.simple-tab-space .tab-title a {
  float: left;
  font-family: 'Montserrat';
  text-transform: uppercase;
  text-decoration: none;
  font-size: 17px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.4);
  margin: 10px 0;
}

.simple-tab-space .tab-title a + a {
  margin-left: 15px;
  padding-left: 15px;
  border-left: 2px solid rgba(0, 0, 0, 0.3);
}

.simple-tab-space .tab-title a.active {
  color: #000;
}
/* Element information
------------------------------------ */

.tt-el-info {
  position: relative;
  display: inline-block;
  margin-top: 20px;
}

.tt-el-info h4 {
  font-size: 28px;
  font-family: 'Montserrat';
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 1em;
  margin: 0px;
  padding: 0px;
  color: #000000;
}

.tt-el-info p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.75);
  line-height: 1em;
  margin-top: 5px;
}

.tt-el-info.inline-style {
  margin-top: 0;
}

.tt-el-info.inline-style h4,
.tt-el-info.inline-style p {
  display: inline-block;
  margin: 0;
}

.tt-el-info.inline-style h4 + p {
  margin-left: 5px;
}

.tt-el-info + .tt-el-info {
  margin-left: 20px;
}
@media screen and (max-width: 376px) {

  .tt-el-info + .tt-el-info {
    margin-left: 0;
  }
}
/* Placeholder & Ads
------------------------------------ */

.ads-placeholder {
  width: 100%;
  padding: 90px 0;
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: 36px;
  line-height: 36px;
  font-family: Montserrat;
  text-transform: uppercase;
}

.ads-placeholder.small {
  text-transform: none;
  font-size: 18px;
}
/* Border line
------------------------------------ */

.border-line {
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  margin: 20px 0;
}

.border-line-1 {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin: 20px 0;
}
/* Title
------------------------------------ */

.title-border {
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  display: inline-block;
  margin-bottom: 20px;
  padding-bottom: 17px;
}

.block-title {
  font-size: 50px;
  font-weight: 700;
  font-family: Montserrat;
  color: $main-color;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 19px;
  position: relative;

  &.back-small:after {
    font-size: 92px;
    top: -30px;
  }
}

.block-title::after {
  content: attr(data-title);
  position: absolute;
  opacity: .1;
  font-size: 13rem;
  top: -54px;
  left: -14px;
  color: black;
}
@media screen and (max-width: 768px) {

  .block-title {
    white-space: nowrap;
  }

  .block-title::after {
    font-size: 100px;
    top: -36px;
  }
}
@media screen and (max-width: 600px) {

  .block-title {
    font-size: 40px;
  }

  .block-title::after {
    font-size: 70px;
    top: -27px;
  }
}

h3.block-title {
  font-size: 36px;
}

h3.block-title:after {
  font-size: 120px;
  top: -54px;
}

h3.block-title .category-more.text-right {
  top: 18px;
}
@media screen and (max-width: 768px) {

  h3.block-title .category-more.text-right {
    top: 38px;
  }
}

h4.block-title {
  font-size: 24px;
}

h4.block-title:after {
  font-size: 100px;
  top: -48px;
}

h4.block-title .category-more.text-right {
  top: 8px;
}
@media screen and (max-width: 768px) {

  h4.block-title .category-more.text-right {
    top: 18px;
  }
}

.title-middle {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  font-family: 'Montserrat';
  text-transform: uppercase;
  margin-top: 0;
  width: 100%;
}

.title-middle .category-more {
  margin-top: 7px;
  font-size: 12px;
}

.title-middle .category-more img {
  height: 12px;
}

.title-middle .category-more.text-right {
  position: relative;
  float: right;
  top: auto;
}
/* Category block
------------------------------------ */

.category-block {
  margin-bottom: 20px;
}

//.category-block a:not(.label) {
//  color: #000;
//  text-decoration: none;
//  line-height: 20px;
//}

.category-block a:not(.label):hover {
  color: rgba(0, 0, 0, 0.8);
}

.category-block .post {
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 1.1em;
}

.category-block .post p,
.category-block .post h6 a {
  //font-family: Georgia;
  font-size: 14px;
}

.category-block .post.first p {
  color: rgba(0, 0, 0, 0.7);
}

.category-block.articles .first.post {
  margin-bottom: 35px;
}

.category-block.articles .first.bordered {
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  padding-bottom: 20px;
}

.category-block.articles .first h4 {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 700;
}

.category-block.articles .first.text-bigger h4 {
  font-size: 26px;
  line-height: 1.1em;
}

.category-block.articles .first.text-bigger p {
  font-size: 16px;
  line-height: 26px;
}

.m-dimension-carousel .category-block {
  margin-bottom: 0px;
  //overflow-x: hidden;
}

.m-dimension-carousel .category-block .post {
  margin-bottom: 0px;
}

.m-dimension-carousel .category-block.articles .post.first {
  margin-bottom: 0px;
}
/* General post block
------------------------------------ */

.post {
  position: relative;
}

.post h4 {
  text-transform: uppercase;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 18px;
  color: #000;
  margin: 0;
  margin-bottom: 10px;
}

.post .image {
  position: relative;
  background-position: center top;
  background-size: cover;
  margin-bottom: 20px;
}

.post .image img {
  width: 100%;
  height: auto;
}

.post .image .video-player,
.post .image .video-player-small {
  width: 44px;
  height: 44px;
  text-align: center;
  color: #fff;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
  bottom: -22px;
  right: 25px;
  z-index: 800;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.post .image .video-player:after,
.post .image .video-player-small:after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -6px;
  border-left: solid 9px #fff;
  border-top: solid 6px transparent;
  border-bottom: solid 6px transparent;
  margin-left: -3px;
}

.post .image .video-player:hover,
.post .image .video-player-small:hover {
  background-color: #333;
}

.post .image .video-player-small {
  width: 28px;
  height: 28px;
  bottom: -15px;
}

.post .image .video-player-small:after {
  margin-top: -4px;
  border-left: solid 6px #FFF;
  border-top: solid 4px transparent;
  border-bottom: solid 4px transparent;
  margin-left: -2px;
}

.post .image .video-player-inside {
  left: 15px;
  bottom: 15px;
}

.post .image .video-player-center {
  margin-left: -23px;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.post .image .video-player-large {
  width: 68px;
  height: 68px;
  margin-left: -34px;
  top: 50%;
  border-width: 3px;
}

.post .image .video-player-large:after {
  margin-top: -9px;
  border-left: solid 14px #FFF;
  border-top: solid 10px transparent;
  border-bottom: solid 10px transparent;
  margin-left: -4px;
}

.post .image-thumb {
  float: left;
  width: 80px;
  height: 80px;
  margin-right: 20px;
}

.post .image-small {
  float: left;
  width: 44px;
  height: 44px;
  margin-right: 20px;
}

.post .image-medium {
  height: 135px;
}

.post .image-large {
  height: 435px;
}

.post .video-frame:hover {
  cursor: pointer;
}

.post p:last-child,
.post h4:last-child,
.post img:last-child {
  margin-bottom: 0;
}

.post.cart-hover {
  position: relative;
}

.post.cart-hover .post-title {
  position: absolute;
  bottom: 15px;
  left: 20px;
  right: 10px;
  z-index: 810;
  font-size: 18px;
  line-height: 20px;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.post.cart-hover .post-title a {
  color: #f9f9f9;
}

.post.cart-hover .post-title a:hover {
  color: #fff;
}

.post.cart-hover:hover .post-title {
  bottom: 25px;
}
/* Meta
------------------------------------ */

.meta {
  line-height: 1;
  font-size: 12px;
  margin-bottom: 10px;
  /* Meta circle treasure
    ------------------------------------ */
}

.meta .author {
  color: #000;
}

.meta .date {
  color: rgba(0, 0, 0, 0.7);
}

.meta span + span {
  margin-left: 5px;
}

.meta.inline-meta {
  line-height: 44px;
  margin-bottom: 20px;
}

.meta.small-meta {
  line-height: 36px;
  margin-bottom: 15px;
  font-size: 14px;
}

.meta.small-meta .image,
.meta.small-meta .image-thumb {
  width: 36px;
  height: 36px;
  margin-right: 15px;
}

.meta.bullet-style {
  position: relative;
  padding-left: 25px;
}

.meta.bullet-style:before {
  content: '';
  display: block;
  border: 2px solid red;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 3px;
}
/* Regular label
------------------------------------ */

.label {
  padding: 8px 15px;
  margin-right: 10px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  border-radius: 0;
  font-family: 'Montserrat';
}

.label:hover {
  color: #fff;
}
/* Trending
------------------------------------ */

.trending .post {
  position: relative;
  margin-bottom: 45px;
}

.trending h4 {
  font-size: 16px;
}

.trending .image {
  height: 100px;
  margin-bottom: 25px;
}

.trending .fancy-number {
  position: absolute;
  right: 0;
  top: 100px;
  font-size: 150px;
  font-family: Montserrat;
  font-weight: bold;
  color: rgba($main-color, 0.3);
  z-index: 0;
  line-height: 105px;
  letter-spacing: -13px;
}
/* More link
------------------------------------ */

.category-more {
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  text-transform: none;
  color: #000;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.category-more img {
  height: 16px;
  margin-left: 10px;
}

.category-more:hover {
  text-decoration: none;
  opacity: 0.7;
  color: #000;
}

.category-more.text-right {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 30px;
}
@media screen and (max-width: 768px) {

  .category-more.text-right {
    top: 45px;
  }
}
/* Pagination
------------------------------------ */

.pagination-next-prev {
  text-align: center;
}

.pagination-next-prev .arrow-link {
  margin: 0 18px;
  position: relative;
  background-image: none;
}

.pagination-next-prev .arrow-link img {
  height: 20px;
}

.pagination-next-prev.bordered {
  position: relative;
  overflow: hidden;
}

.pagination-next-prev.bordered:before {
  content: '';
  position: absolute;
  height: 2px;
  background: rgba(0, 0, 0, 0.2);
  width: 50%;
  left: 0;
  top: 50%;
  -webkit-transform: translateX(-60px);
  -moz-transform: translateX(-60px);
  transform: translateX(-60px);
}

.pagination-next-prev.bordered:after {
  content: '';
  position: absolute;
  height: 2px;
  background: rgba(0, 0, 0, 0.2);
  width: 50%;
  right: 0;
  top: 50%;
  -webkit-transform: translateX(60px);
  -moz-transform: translateX(60px);
  transform: translateX(60px);
}
/* News slider
------------------------------------ */

.ms-tabs-template .ms-tabs-vertical-template {
  max-width: 1170px;
}

.news-slider {
  margin-bottom: 20px;
}

.news-slider > .master-slider {
  border: 1px solid #999;
}

.news-slider .ms-inner-controls-cont,
.news-slider .ms-container {
  margin: 0;
}

.news-slider .ms-tabs .ms-thumb-frame {
  background-color: #fff;
  text-shadow: none;
}

.news-slider .ms-tabs .ms-thumb {
  padding: 0;
  border: 1px solid #999;
}

.news-slider .ms-thumb {
  position: relative;
}

.news-slider .ms-thumb .image {
  height: 180px;
  background-size: cover;
  position: relative;
  margin-bottom: 0;
}

.news-slider .ms-thumb .meta {
  float: left;
  background-color: #fff;
  padding: 15px 15px 5px 0;
  z-index: 900;
  position: relative;
  margin-bottom: 0;
}

.news-slider .ms-thumb h4 {
  float: left;
  margin-bottom: 0;
  font-weight: bold;
}

.news-slider .thumb-meta {
  background-color: #fff;
  width: 220px;
  z-index: 800;
  position: absolute;
  left: 0;
  bottom: -1px;
  padding-left: 8px;
  padding-bottom: 8px;
}

.news-slider .box {
  left: -3px;
  width: 60%;
  bottom: -3px;
  background: #fff;
  padding: 35px 30px 3px 4px;
  padding-left: 12px !important;
  padding-bottom: 12px !important;
}

.news-slider .box p,
.news-slider .box h4,
.news-slider .box .meta {
  float: left;
}

.news-slider .box .meta {
  font-size: 16px;
}

.news-slider .box h4 {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bold;
  font-family: "Montserrat";
  color: #000;
}

.news-slider .box p {
  margin: 0;
  //font-family: "Georgia";
  color: rgba(0, 0, 0, 0.7);
  font-size: 18px;
  line-height: 24px;
}

.news-slider .ms-view {
  background-color: #fff;
}

.news-slider .ms-tabs.ms-dir-v.ms-align-right .ms-thumb-ol {
  top: 12px;
  width: 0;
  z-index: 600;
  border: none;
}

.news-slider .ms-tabs.ms-dir-v.ms-align-right .ms-thumb-frame-selected .ms-thumb-ol {
  width: 100%;
  height: 5px;
  background: $main-color;
  left: 0;
  top: 12px;
}

.news-slider .ms-thumb-frame-selected .image {
  color: #fff;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.news-slider.photo-news-slider .ms-thumb {
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.news-slider.photo-news-slider .ms-thumb .image {
  height: 130px;
  color: #fff;
}

.news-slider.photo-news-slider .ms-thumb .meta {
  margin-top: -19px;
  padding: 10px 15px 0 0;
}

.news-slider.photo-news-slider .ms-thumb h4 {
  margin-top: 5px;
}

.news-slider.photo-news-slider .ms-thumb-frame-selected .image:before {
  content: '';
  position: absolute;
  z-index: 56;
  top: 50%;
  left: 80px;
  margin-top: -9px;
  border-left: solid 12px #fff;
  border-top: solid 9px transparent;
  border-bottom: solid 9px transparent;
}

.news-slider.photo-news-slider .ms-thumb-frame-selected .image:after {
  content: 'SELECTED';
  position: absolute;
  line-height: 130px;
  text-align: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 55;
}

.news-slider:not(.news-slider-hover) .ms-thumb-list.ms-dir-v {
  margin-right: -10px;
}

.news-slider.news-slider-hover .ms-thumb-list {
  right: 120px !important;
  width: 260px !important;
}

.news-slider.news-slider-hover .ms-thumb-list.ms-dir-v {
  height: 80%;
  margin-top: 10%;
  max-height: 400px;
}

.news-slider.news-slider-hover .ms-tabs.ms-dir-v .ms-thumb-frame {
  margin-left: 0;
}

.news-slider.news-slider-hover .ms-thumb-frame {
  width: 260px !important;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.6);
}

.news-slider.news-slider-hover .thumb-meta {
  background-color: transparent;
  position: relative;
  width: auto;
}

.news-slider.news-slider-hover .ms-thumb .meta {
  background-color: transparent;
}

.news-slider.news-slider-hover .box {
  left: 65px;
  width: 60%;
  max-width: 640px;
  bottom: 65px;
  background: none;
  padding: 0;
  color: #fff;
}

.news-slider.news-slider-hover .box h4 {
  font-size: 36px;
  font-weight: 400;
}

.news-slider.news-slider-hover h4 {
  font-size: 18px;
}

.news-slider.news-slider-hover h4,
.news-slider.news-slider-hover h4 a,
.news-slider.news-slider-hover .meta span,
.news-slider.news-slider-hover p {
  color: #fff;
}

.news-slider.news-slider-hover .ms-tabs.ms-dir-v.ms-align-right .ms-thumb-frame-selected .ms-thumb-ol {
  width: 100%;
  height: 2px;
  left: 0;
  top: auto;
  bottom: -2px;
}

.news-slider.news-slider-hover .slide-pattern {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 8;
  -webkit-transform: translateZ(0.4px);
  -moz-transform: translateZ(0.4px);
  -ms-transform: translateZ(0.4px);
  -o-transform: translateZ(0.4px);
  transform: translateZ(0.4px);
  position: absolute;
  top: 0;
}

.news-slider.news-slider-hover .slide-pattern.tint {
  background: url("images/news-slider-tint.png") no-repeat;
  background-size: cover;
}
/* Gallery slider
------------------------------------ */

.gallery-slider .ms-thumb-list.ms-dir-h {
  padding-top: 20px;
}

.gallery-slider .ms-thumb {
  position: relative;
}

.gallery-slider .ms-thumb .image {
  height: 60px;
  background-size: cover;
  position: relative;
  margin-bottom: 0;
}

.gallery-slider .ms-tabs .ms-thumb {
  padding: 0;
}

.gallery-slider .ms-tabs .ms-thumb-frame {
  background: none;
  text-shadow: none;
  border: none;
  opacity: 0.3;
  margin-top: 0;
}

.gallery-slider .ms-tabs .ms-thumb-frame.ms-thumb-frame-selected {
  opacity: 1;
}

.gallery-slider .ms-tabs .ms-thumb-frame .ms-thumb-ol {
  border: none;
}

.gallery-slider .ms-layer {
  font-family: 'Montserrat';
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  font-size: 24px;
  bottom: 25px;
  left: 30px;
}

.gallery-slider .ms-layer a {
  color: #fff;
}

.gallery-slider .ms-layer a:hover {
  color: rgba(255, 255, 255, 0.9);
}

.gallery-slider .ms-layer span {
  font-size: 70%;
  font-weight: 400;
}

.gallery-slider .slide-pattern {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 8;
  position: absolute;
  top: 0;
  background-position: center top;
}

.gallery-slider .slide-pattern.tint {
  background: url("images/gallery-slider-tint.png") repeat-x;
}

.gallery-slider.movie-slider .slide-pattern {
  background-position: center bottom;
}

.gallery-slider .ms-sbar.ms-dir-h {
  background-color: rgba(0, 0, 0, 0.3);
  left: 0;
  right: 0;
  height: 2px;
}

.gallery-slider .image-copyright {
  font-size: 12px;
  // font-family: 'SF UI Display';
  font-style: italic;
  color: rgba(0, 0, 0, 0.4);
}
/* Fullscreen & Fullwidth section
------------------------------------ */

.fullscreen-section {
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  min-height: 600px;
}

.fullscreen-section > .overlay {
  position: absolute;
  background-color: #000;
  opacity: 0.7;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 0;
}

.fullwidth-section {
  min-height: 200px;
}
/* Twitter share able
------------------------------------ */

.twitter-shareable {
  padding: 0 3px;
  background: #f0f0f0;
}

.twitter-shareable:after {
  content: "";
  font-family: FontAwesome;
  margin-left: 5px;
  color: #000;
}

.twitter-shareable:hover {
  cursor: pointer;
  background: #efefef;
}
/* Entertainment
====================================*/
@media screen and (max-width: 1199px) {

  #header.header-news nav.main-nav > ul {
    position: relative;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }

  #header.header-news nav.main-nav > ul::-webkit-scrollbar {
    display: none;
  }

  #header.header-news nav.main-nav > ul > li ul {
    display: none;
  }
}
@media screen and (max-width: 720px) {

  #header.header-news .panel-header .header-wrapper .right-content {
    display: none;
  }
}
/* Entertainment
====================================*/

#header {

}

#header.header-entertainment .header-wrapper {
  height: 76px;
  padding: 0 50px;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

#header.header-entertainment .header-wrapper .site-branding {
  position: relative;
  left: auto;
  bottom: auto;
  float: left;
}

#header.header-entertainment .header-wrapper .site-branding img {
  height: 23px;
  width: auto;
}

#header.header-entertainment .header-wrapper .right-content,
#header.header-entertainment .header-wrapper .user-profile,
#header.header-entertainment .header-wrapper .user-profile .follow-links {
  position: relative;
  float: right;
  left: auto;
}

#header.header-entertainment .header-wrapper .user-profile .entry-notify span {
  background: #f84432;
  color: #fff;
}

#header.header-entertainment .header-wrapper .user-profile .entry-name > a,
#header.header-entertainment .header-wrapper .user-profile .follow-links > a {
  color: #fff;
}

#header.header-entertainment #search_handler::after {
  top: 1px;
  margin-top: 0px;
  color: #fff;
}

#header.header-entertainment .search-panel {
  position: absolute;
  top: 0px;
  left: -260px;
  width: 220px;
  height: 100%;
  opacity: 0;
}
@media screen and (max-width: 425px) {

  #header.header-entertainment .search-panel {
    left: -200px;
    width: 140px;
  }
}

#header.header-entertainment .search-panel input[type=text] {
  background: transparent;
  position: absolute;
  top: -3px;
  left: 24px;
  right: 0px;
  height: 100%;
  color: #fff;
  font-family: 'Montserrat';
  font-size: 13px;
}

#header.header-entertainment .search-panel input[type=text]::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}

#header.header-entertainment .search-panel input[type=text]:-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}

#header.header-entertainment .search-panel input[type=text]::-moz-placeholder {
  color: rgba(255, 255, 255, 0.9);
}

#header.header-entertainment .search-panel input[type=text]:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.9);
}

#header.header-entertainment .search-panel img {
  width: 14px;
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -2px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

#header.header-entertainment.show-search .header-wrapper {
  transform: translateX(220px);
}

#header.header-entertainment.show-search .search-panel {
  opacity: 1;
}
@media screen and (max-width: 1199px) {

  #header.header-entertainment .tt-info-weather {
    display: none;
  }
}
@media screen and (max-width: 991px) {

  #header.header-entertainment .tt-info-date {
    display: none;
  }
}
@media screen and (max-width: 767px) {

  #header.header-entertainment .user-profile {
    display: none;
  }

  #header.header-entertainment .header-wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }
}
/* Entertainment
------------------------------------ */

.entertainment-layout {
  background-color: #f0f0f0;
}

.entertainment-layout .category-more {
  // font-family: 'SF UI Display';
}

.header-entertainment {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(20, 20, 20, 0.3);
  z-index: 900;
}

.header-entertainment.darker {
  background-color: rgba(20, 20, 20, 0.8);
}

.header-entertainment .left-content {
  float: left;
}

.header-entertainment .tt-el-info h4 {
  color: #ffffff;
}

.header-entertainment .tt-el-info p {
  color: rgba(255, 255, 255, 0.85);
}

.header-entertainment .user-profile {
  margin-left: 30px;
}

.header-entertainment .tools {
  float: left;
}

.header-entertainment .tools a {
  line-height: 60px;
}

.header-entertainment .site-branding {
  margin-left: 30px;
  line-height: 67px;
}
/* Entertainment slider
------------------------------------ */

.entertainment-slider {

}

.entertainment-slider .ms-layer {
  //margin-bottom: 80px;
  top: 50% !important;
  bottom: auto !important;
  transform: translateY(-50%);
  margin-top: 39px;
}

.entertainment-slider .ms-layer .meta {
  font-size: 1.6rem;
  font-weight: 300;
  // font-family: 'SF UI Display';
}

.entertainment-slider .ms-layer .meta span {
  position: relative;
}

.entertainment-slider .ms-layer .meta span + span {
  margin-left: 15px;
  padding-left: 25px;
}

.entertainment-slider .ms-layer .meta span + span:before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $main-color;
  position: absolute;
  left: 0;
  margin-top: 4px;
}

.entertainment-slider .ms-layer h2 {
  font-size: 4.8rem;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  font-family: $headings-font-family;
  margin-top: 10px;
}

.entertainment-slider .ms-layer h2 span {
  background-color: #f84432;
  color: #fff;
  padding: 5px 15px;
  border-radius: 15px;
  position: relative;
  top: -9px;
}

.entertainment-slider .ms-layer p {
  font-size: 1.8rem;
  margin: 10px 0;
  max-width: 800px;
  // font-family: 'Georgia';
}

.entertainment-slider .button {
  background-color: #f84432;
  margin-top: 100px;
  margin-bottom: 40px;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
  border-color: #f84432;
}

.entertainment-slider .button:hover {
  background-color: rgba(248, 68, 50, 0.05);
}

.entertainment-slider .button + .button {
  margin-left: 30px;
}

.entertainment-slider .button i {
  margin-right: 10px;
}

.entertainment-slider .ms-tabs .ms-thumb-frame {
  background-color: #fff;
  text-shadow: none;
}

.entertainment-slider .ms-tabs .ms-thumb {
  padding: 0;
}

.entertainment-slider .ms-thumb {
  position: relative;
  text-align: right;
}

.entertainment-slider .ms-thumb .image {
  height: 180px;
  background-size: cover;
  position: relative;
  margin-bottom: 0;
}

.entertainment-slider .ms-thumb .meta {
  background-color: #fff;
  padding: 0 0 15px;
  z-index: 900;
  position: relative;
  margin-bottom: 0;
}

.entertainment-slider .ms-thumb h4 {
  margin-bottom: 0;
  font-weight: bold;
}

.entertainment-slider .ms-thumb p {
  padding: 10px 0;
}

.entertainment-slider .thumb-meta {
  background-color: #fff;
  width: 220px;
  z-index: 800;
  position: absolute;
  left: 0;
  bottom: -1px;
}

.entertainment-slider .ms-tabs.ms-dir-v.ms-align-right .ms-thumb-ol {
  top: 12px;
  width: 0;
  z-index: 600;
  border: none;
}

.entertainment-slider .ms-thumb-frame-selected .image {
  color: #fff;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.entertainment-slider.news-slider-hover .ms-thumb-list {
  right: 120px !important;
  width: 260px !important;
  padding-right: 30px;
  border-right: 2px solid $main-color;
}

.entertainment-slider.news-slider-hover .ms-thumb-list.ms-dir-v {
  //height: 70%;
  //margin-top: 10%;
  //max-height: 600px;
  max-height: 90%;
  height: 30em;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 39px;
}

.entertainment-slider.news-slider-hover .ms-tabs.ms-dir-v .ms-thumb-frame {
  margin-left: 0;
}

.entertainment-slider.news-slider-hover .ms-thumb-frame {
  width: 260px !important;
  background-color: transparent;
  border: none;
}

.entertainment-slider.news-slider-hover .thumb-meta {
  background-color: transparent;
  position: relative;
  width: auto;
}

.entertainment-slider.news-slider-hover .ms-thumb .meta {
  background-color: transparent;
}

.entertainment-slider.news-slider-hover h4 {
  font-size: 18px;
}

.entertainment-slider.news-slider-hover h4,
.entertainment-slider.news-slider-hover .meta span,
.entertainment-slider.news-slider-hover p {
  color: #fff;
}

.entertainment-slider.news-slider-hover p {
  line-height: 1.4em;
  //margin-bottom: -30px;
}

.entertainment-slider.news-slider-hover .ms-tabs.ms-dir-v.ms-align-right .ms-thumb-frame-selected .ms-thumb-ol {
  width: 100%;
  height: 2px;
  left: 0;
  top: auto;
  bottom: -2px;
}

.entertainment-slider .circle-chart {
  width: 115px;
  height: 115px;
  margin-left: 50px;
  bottom: 10px;
}

.entertainment-slider .circle-chart .circles-text {
  font-size: 32px !important;
  line-height: 0.6 !important;
}
@media screen and (min-width: 992px) and (max-width: 1199px) {

  .entertainment-slider .ms-tabs.ms-dir-v.ms-align-right {
    height: 60%;
    margin-top: 15%;
    right: 60px !important;
  }

  .entertainment-slider .ms-layer {
    left: 60px !important;
    width: 60%;
  }
}
@media screen and (max-width: 991px) {

  .entertainment-slider .ms-tabs.ms-dir-v.ms-align-right {
    display: none;
  }

  .entertainment-slider .ms-layer {
    position: relative !important;
    display: block;
    margin-left: 60px;
    margin-top: 180px !important;
  }
}
@media screen and (max-width: 767px) {

  .entertainment-slider .ms-layer {
    margin-top: 130px !important;
  }

  .entertainment-slider .ms-layer .meta {
    font-size: 14px;
  }

  .entertainment-slider .ms-layer h2 {
    font-size: 40px;
  }

  .entertainment-slider .ms-layer p {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .entertainment-slider .ms-layer .circle-chart {
    display: none;
  }
}
@media screen and (max-width: 599px) {

  .entertainment-slider .ms-layer > div {
    display: none;
  }

  .entertainment-slider .ms-layer h2 {
    font-size: 30px;
  }

  .entertainment-slider .ms-layer p {
    display: none;
  }
}
/* Movie Slider
------------------------------------ */

.movie-section {
  padding: 100px 0;
  background-image: url('images/entertainment/section-5-background.jpg');
  background-size: cover;
}

.movie-section .block-title,
.movie-section .category-more {
  color: #fff;
}
/* Movie slider
------------------------------------ */

.movie-slider {

}

.movie-slider .ms-thumb .image {
  height: 154px;
}

.movie-slider .label {
  padding-left: 30px;
  font-size: 18px;
  margin-top: 70px;
  background-color: #f84432;
}

.movie-slider .ms-layer.right-side {
  width: 360px !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  left: auto !important;
  padding: 70px;
}

.movie-slider .ms-layer.right-side h3 {
  font-size: 40px;
  line-height: 44px;
}

.movie-slider h3,
.movie-slider h4 {
  margin-bottom: 20px;
}

.movie-slider .rate {
  font-size: 18px;
}

.movie-slider .one-half {
  width: 50%;
  float: left;
}

.movie-slider .meta span {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  // font-family: 'SF UI Display';
  text-transform: none;
  font-weight: 300;
}

.movie-slider .excerpt {
  // font-family: 'Georgia';
  font-size: 16px;
  font-weight: normal;
  text-transform: none;
  line-height: 1.9em;
}

.movie-slider .border-top {
  padding-top: 25px;
  margin-top: 20px;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
}

.movie-slider .ms-nav-prev {
  margin-left: -100px;
}

.movie-slider .ms-nav-prev:after {
  content: "PREVIOUS MOVIE";
  font-size: 14px;
  color: #fff;
  display: block;
  width: 130px;
  margin-left: -99px;
  text-align: right;
  margin-top: 35px;
}

.movie-slider .ms-nav-next {
  margin-right: -100px;
  opacity: 1 !important;
}

.movie-slider .ms-nav-next:after {
  content: "NEXT MOVIE";
  font-size: 14px;
  color: #fff;
  display: block;
  width: 130px;
  margin-right: -99px;
  margin-top: 35px;
}

.movie-slider .icon-link {
  font-size: 14px;
  color: #fff;
  text-transform: none;
  font-weight: 100;
  margin-top: 25px;
  display: inline-block;
}

.movie-slider .icon-link img {
  margin-left: 5px;
}

.movie-slider .icon-link + .icon-link {
  margin-left: 15px;
}

.movie-slider .circle-chart {
  width: 100px;
  height: 100px;
  -webkit-transform: translateX(30px) translateY(30px);
  -moz-transform: translateX(30px) translateY(30px);
  transform: translateX(30px) translateY(30px);
}

.movie-slider .circle-chart .circles-text {
  font-size: 26px !important;
  line-height: .6 !important;
}

.movie-slider .bottom {
  position: relative;
  top: 140px;
  left: 60px;
}
@media screen and (max-width: 991px) {

  .movie-slider .movie-slider-meta {
    width: 100%;
  }

  .movie-slider .movie-slider-circle {
    display: none;
  }
}
@media screen and (max-width: 767px) {

  .movie-slider .ms-layer.right-side h3 {
    font-size: 26px;
    line-height: 1.2em;
  }

  .movie-slider .meta span {
    font-size: 12px;
  }

  .movie-slider .excerpt {
    font-size: 14px;
    padding-top: 10px;
    margin-top: 5px;
  }
}
@media screen and (max-width: 700px) {

  .movie-slider .ms-layer.right-side {
    width: 85% !important;
    text-align: right;
  }
}
@media screen and (max-width: 600px) {

  .movie-slider .ms-layer.right-side h3 {
    margin-bottom: 5px;
  }

  .movie-slider .meta,
  .movie-slider .excerpt {
    display: none;
  }

  .movie-slider .icon-link {
    margin-top: 5px;
  }
}
/* Masonry layout
------------------------------------ */

.en-carousel-block .post {
  position: relative;
  width: 100%;
  height: 360px;
}

.en-carousel-block .post h4 {
  font-size: 26px;
  line-height: 30px;
  font-weight: 400;
  -webkit-transition: all .25s;
  -moz-transition: all .25s;
  transition: all .25s;
}

.en-carousel-block .post h4.font36 {
  font-size: 36px;
  line-height: 1.1;
  font-weight: 400;
  margin: 15px 0;
}

.en-carousel-block .post-content {
  padding: 35px;
  z-index: 2;
  position: relative;
}

.en-carousel-block .masonry-item {
  margin-bottom: 25px !important;
}

.en-carousel-block .masonry-item .meta .author {
  color: #000;
}

.en-carousel-block .masonry-item.col-md-6 .post-content {
  padding: 70px;
}

.en-carousel-block .masonry-item.col-md-6 h4 {
  max-width: 50%;
}

.en-carousel-block .image {
  width: 100%;
  height: 360px;
  position: absolute;
  top: 0;
  left: 0;
}

.en-carousel-block .meta {
  display: inline-block;
  border-bottom: 2px solid $main-color;
  padding-bottom: 15px;
  margin-bottom: 20px;
  // font-family: 'SF UI Display';
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 100;
}

.en-carousel-block .meta + h4 {
  margin-top: 20px;
}

.en-carousel-block .meta.white {
  border-color: #fff;
}

.en-carousel-block .hahah-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
}

.en-carousel-block .meta-bottom {
  position: relative;
  height: 100%;
}

.en-carousel-block .meta-bottom .meta {
  position: absolute;
  bottom: 30px;
}

.en-carousel-block .half-height {
  height: 167px;
}

.en-carousel-block .half-height .post-content {
  padding-top: 30px;
}

.en-carousel-block .half-height .image {
  height: 167px;
}

.en-carousel-block .half-height .meta {
  padding-bottom: 10px;
}

.en-carousel-block .half-height .meta + h4 {
  margin-top: 15px;
}

.en-carousel-block .text-light {
  color: #fff;
}

.en-carousel-block .text-light h4,
.en-carousel-block .text-light a {
  color: #fff;
}

.en-carousel-block .text-light h4:hover,
.en-carousel-block .text-light a:hover {
  color: rgba(255, 255, 255, 0.9);
}

.en-carousel-block .text-light .meta .author {
  color: #fff;
}
/* Masonry layout
------------------------------------ */

.masonry-layout1.little-space.row {
  margin-left: -10px;
  margin-right: -10px;
}

.masonry-layout1.little-space .col-lg-1,
.masonry-layout1.little-space .col-lg-10,
.masonry-layout1.little-space .col-lg-11,
.masonry-layout1.little-space .col-lg-12,
.masonry-layout1.little-space .col-lg-2,
.masonry-layout1.little-space .col-lg-3,
.masonry-layout1.little-space .col-lg-4,
.masonry-layout1.little-space .col-lg-5,
.masonry-layout1.little-space .col-lg-6,
.masonry-layout1.little-space .col-lg-7,
.masonry-layout1.little-space .col-lg-8,
.masonry-layout1.little-space .col-lg-9,
.masonry-layout1.little-space .col-md-1,
.masonry-layout1.little-space .col-md-10,
.masonry-layout1.little-space .col-md-11,
.masonry-layout1.little-space .col-md-12,
.masonry-layout1.little-space .col-md-2,
.masonry-layout1.little-space .col-md-3,
.masonry-layout1.little-space .col-md-4,
.masonry-layout1.little-space .col-md-5,
.masonry-layout1.little-space .col-md-6,
.masonry-layout1.little-space .col-md-7,
.masonry-layout1.little-space .col-md-8,
.masonry-layout1.little-space .col-md-9,
.masonry-layout1.little-space .col-sm-1,
.masonry-layout1.little-space .col-sm-10,
.masonry-layout1.little-space .col-sm-11,
.masonry-layout1.little-space .col-sm-12,
.masonry-layout1.little-space .col-sm-2,
.masonry-layout1.little-space .col-sm-3,
.masonry-layout1.little-space .col-sm-4,
.masonry-layout1.little-space .col-sm-5,
.masonry-layout1.little-space .col-sm-6,
.masonry-layout1.little-space .col-sm-7,
.masonry-layout1.little-space .col-sm-8,
.masonry-layout1.little-space .col-sm-9,
.masonry-layout1.little-space .col-xs-1,
.masonry-layout1.little-space .col-xs-10,
.masonry-layout1.little-space .col-xs-11,
.masonry-layout1.little-space .col-xs-12,
.masonry-layout1.little-space .col-xs-2,
.masonry-layout1.little-space .col-xs-3,
.masonry-layout1.little-space .col-xs-4,
.masonry-layout1.little-space .col-xs-5,
.masonry-layout1.little-space .col-xs-6,
.masonry-layout1.little-space .col-xs-7,
.masonry-layout1.little-space .col-xs-8,
.masonry-layout1.little-space .col-xs-9 {
  padding-left: 10px;
  padding-right: 10px;
}

.masonry-layout1.little-space .masonry-item {
  margin-bottom: 20px;
  overflow: hidden;
}

.masonry-layout1.little-space .post .image {
  margin-bottom: 0px;
}

.masonry-layout1.little-space .post .meta-holder {
  background-color: rgba(0, 0, 0, 0.5);
}

.masonry-layout1.little-space .grid-size.sv {
  margin-bottom: 20px;
}

.masonry-layout1.little-space .grid-size.svb {
  margin-bottom: -10px;
}

.masonry-item img {
  width: 100%;
  height: auto;
}

.masonry-item .entry-hover {
  position: absolute;
  bottom: 25px;
  left: 25px;
  right: 25px;
}

.masonry-item .label {
  margin-bottom: 10px;
  font-size: 12px;
  display: inline-block;
}

.masonry-item h4 {
  font-size: 20px;
  line-height: 24px;
}

.masonry-item .meta .author,
.masonry-item .meta .date {
  color: #e3e3e3;
}

.masonry-item .circle-chart {
  width: 36px;
  height: 36px;
}

.masonry-layout1 h4 a {
  color: #fff;
  font-weight: 400;
  text-decoration: none;
}
/* Testimonial slider
------------------------------------ */

.testimonial-slider.fs-blog-carousel {
  position: relative;
  margin-bottom: 25px;
}

.testimonial-slider.fs-blog-carousel .swiper-holder {
  background: #fff;
  margin-top: 60px;
}

.testimonial-slider.fs-blog-carousel img {
  max-width: 60%;
  margin-top: -60px;
}

.testimonial-slider.fs-blog-carousel .swiper-slider {
  position: relative;
}

.testimonial-slider.fs-blog-carousel blockquote {
  position: absolute;
  right: 10%;
  top: 50%;
  text-transform: uppercase;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-right: 30px;
  max-width: 50%;
  width: 380px;
  border: none;
}

.testimonial-slider.fs-blog-carousel blockquote cite {
  border-bottom: 2px solid #f84432;
  display: inline-block;
  text-transform: none;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.testimonial-slider.fs-blog-carousel blockquote:before {
  left: -80px;
  font-size: 160px;
  top: 96px;
}

.testimonial-slider.fs-blog-carousel .fs-pager {
  width: 150px;
  position: absolute;
  bottom: 20px;
  right: 0;
  z-index: 900;
}

.testimonial-slider.fs-blog-carousel .fs-pager span {
  font-size: 24px;
}

.testimonial-slider.fs-blog-carousel .fs-pager:before,
.testimonial-slider.fs-blog-carousel .fs-pager:after {
  display: none;
}

.testimonial-slider.fs-blog-carousel .swiper-button-prev.swiper-button-prev {
  left: -60px;
}

.testimonial-slider.fs-blog-carousel .swiper-button-next.swiper-button-next {
  right: -60px;
}
@media screen and (max-width: 767px) {

  .testimonial-slider.fs-blog-carousel blockquote {
    font-size: 20px;
    line-height: 1.4em;
  }

  .testimonial-slider.fs-blog-carousel blockquote cite {
    margin-bottom: 14px;
  }
}
@media screen and (max-width: 600px) {

  .testimonial-slider.fs-blog-carousel blockquote {
    font-size: 9px;
    line-height: 1.4em;
    padding-right: 0px;
    text-transform: none;
  }

  .testimonial-slider.fs-blog-carousel blockquote cite {
    padding-bottom: 0px;
    margin-bottom: 4px;
    font-size: 12px;
  }

  .testimonial-slider.fs-blog-carousel .fs-pager {
    bottom: -6px;
    width: 100px;
  }

  .testimonial-slider.fs-blog-carousel .fs-pager span {
    font-size: 14px;
  }
}
/* PIE
------------------------------------ */

.pie {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  background-color: #2f3e46;
  font-size: 44px;
  text-align: center;
  border-radius: 50%;
}

.slice-right {
  position: absolute;
  z-index: 11;
  background-color: #ff913b;
  width: 100%;
  height: 100%;
  clip: rect(0, 20px, 20px, 20px);
  border-radius: 50%;
}

.slice-left {
  position: absolute;
  z-index: 11;
  background-color: #ff913b;
  width: 100%;
  height: 100%;
  clip: rect(20px, 20px, 40px, 20px);
  border-radius: 50%;
}

.percent {
  position: absolute;
  z-index: 20;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  background: #1d2225;
  border-radius: 50%;
}

.number {
  position: absolute;
  z-index: 30;
  width: 100%;
  height: 100%;
  padding-top: 50%;
  line-height: 0;
  font-size: .3em;
  color: #485c65;
}

.slice-right {
  -webkit-animation-delay: 1.2s;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-name: right-slice;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
}

.slice-left {
  -webkit-animation-delay: 1.5s;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-name: left-slice;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
}
@-webkit-keyframes right-slice {

  from {
    clip: rect(0, 50%, 20px, 20px);
  }

  50% {
    clip: rect(0, 40px, 20px, 20px);
  }

  to {
    clip: rect(0, 40px, 40px, 20px);
  }
}
@-webkit-keyframes left-slice {

  from {
    clip: rect(20px, 20px, 40px, 20px);
  }

  50% {
    clip: rect(20px, 20px, 40px, 0);
  }

  to {
    clip: rect(0.3em, 20px, 40px, 0);
  }
}
/* Entertainment block
------------------------------------ */

.post img {
  max-width: 100%;
}

.post .bigger-meta {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.post .bigger-meta .author-image {
  top: -40px;
  position: absolute;
}

.post .bigger-meta .meta {
  margin-left: 80px;
}

.post .meta-holder {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 30px;
}

.post.boxoffice-style {

}

.post.boxoffice-style .pie {
  float: left;
}

.post.boxoffice-style .bigger-meta {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.post.boxoffice-style .bigger-meta::before {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 120%;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);

  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);

  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);

  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#d9000000', GradientType=0);

}

.post.boxoffice-style .bigger-meta .meta-holder {
  padding: 0 5px 25px 20px;
}

.post.boxoffice-style .bigger-meta span {
  line-height: 40px;
  color: rgba(255, 255, 255, 0.9);
  margin-left: 12px;
}

.post.boxoffice-style .image {
  margin-bottom: 18px;
  overflow: hidden;
}

.post.boxoffice-style .label {
  top: 0;
}

.post.boxoffice-style h4 {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 400;
}

.post.boxoffice-style .rate {
  color: #9a9a9a;
  line-height: 1;
}

.post.boxoffice-style .rate i {
  margin-right: 2px;
  margin-bottom: 10px;
}

.post.boxoffice-style .circle-chart {
  width: 36px;
  bottom: -13px;
}

.post.boxoffice-style:hover .bigger-meta {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}
/* Entertainment block
------------------------------------ */

.en-block .meta {
  font-size: 18px;
}

.en-block .meta .label {
  display: inline-block;
}

.en-block .meta.small-meta {
  font-size: 14px;
}

.en-block .meta.mb0 {
  margin-bottom: 0;
}

.en-block .post h4 {
  font-size: 22px;
}

.en-block .post p {
  font-size: 16px;
  line-height: 30px;
  color: #4d4d4d;
  margin: 0;
}

.en-block .category-more {
  color: #000;
  font-size: 16px;
  padding-top: 20px;
  display: inline-block;
}

.en-block .social-links a {
  margin-left: 10px;
  font-size: 14px;
}

.post h4.font14 {
  font-size: 14px;
  line-height: 1;
}

.post h4.font16 {
  font-size: 16px;
  line-height: 1;
}

.post h4.font18 {
  font-size: 18px;
  line-height: 22px;
}

.post h4.font20 {
  font-size: 20px;
  line-height: 24px;
}

.post h4.font22 {
  font-size: 22px;
  line-height: 26px;
}

.post h4.font24 {
  font-size: 24px;
  line-height: 28px;
}

.post h4.font28 {
  font-size: 28px;
  line-height: 32px;
}
@media screen and (max-width: 768px) {

  .post h4.font28 {
    font-size: 22px;
    line-height: 26px;
  }
}

.post h4.font32 {
  font-size: 32px;
  line-height: 36px;
}

.image .label {
  position: absolute;
  left: 0;
  top: 25px;
  padding-left: 25px;
  color: #fff;
  z-index: 800;
}
/* Quote post layout
------------------------------------ */
@media screen and (max-width: 786px) {

  .post.quote {
    margin-bottom: 40px;
  }
}

.post.quote blockquote {
  margin-left: 0;
  padding-left: 0;
  border: 0;
  font-size: 24px;
  text-transform: uppercase;
}

.post.quote blockquote:before {
  font-size: 80px;
  left: -35px;
  top: 10px;
}
@media screen and (max-width: 768px) {

  .post.quote blockquote:before {
    left: -20px;
  }
}

.post.quote .label {
  margin-left: 50px;
}

.post.quote .author {
  position: relative;
}

.post.quote .author .image {
  position: relative;
  z-index: 1;
}

.post.quote .author .label {
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 0;
  padding: 12px 15px 12px 43px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all .25s;
  -moz-transition: all .25s;
  transition: all .25s;

  max-width: 215px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post.quote:hover .author .label {
  padding: 12px 25px 12px 53px;

  text-overflow: clip;
  max-width: 500px;
}

.post.cart-style .image-thumb {
  width: 54px;
  height: 54px;
}

.post.cart-style .label {
  font-size: 14px;
  color: #000;
  padding: 0;
  background: none;
  line-height: 36px;
  position: relative;
  display: inline-block;
  margin-top: 4px;
}

.post.cart-style .label:before {
  content: '';
  display: block;
  position: absolute;
  top: -4px;
  left: 0;
  width: 10px;
  height: 4px;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.post.cart-style:hover .label:before {
  width: 20px;
}

.post.cart-style p {
  font-size: 14px;
  line-height: 24px;
}

.post.cart-style .image-thumb {
  margin-bottom: 20px;
}

.post.cart-style .meta {
  font-size: 14px;
}
/* Image grid & Gallery style
------------------------------------ */

.image-grid {
  margin-top: 20px;
  clear: both;
}

.image-grid .image {
  width: 48%;
  margin-bottom: 4%;
  float: left;
}

.image-grid .image:nth-child(2n+1) {
  margin-right: 4%;
}

.image-grid.col-3 .image {
  width: 32%;
  margin-right: 2%;
  margin-bottom: 2%;
}

.image-grid.col-3 .image:nth-child(3n+3) {
  margin-right: 0;
}

.image-grid.col-3 .image:nth-child(3n+4) {
  clear: left;
}

.image-grid.col-4 .image {
  width: 23.5%;
  margin-right: 2%;
  margin-bottom: 2%;
}

.image-grid.col-4 .image:nth-child(4n+4) {
  margin-right: 0;
}

.image-grid.col-4 .image:nth-child(4n+5) {
  clear: left;
}

/* Content
====================================*/

html {
  overflow-x: hidden;
}

body {
  // font-family: 'SF UI Display';
  //font-size: 14px;
  //line-height: 28px;
  //letter-spacing: 0.2px;
  //background-color: #ffffff;
  //color: #4d4d4d;
  //overflow-x: hidden;
}

body ::selection {
  background-color: #333;
  color: #fff;
}

//body.entertainment-content ::selection {
//  background-color: #f84432;
//  color: #fff;
//}
//
//body.fashion-content ::selection {
//  background-color: #1a1a1a;
//  color: #fff;
//}

a {
  color: #000;
}

a:focus {
  outline: none;
  text-decoration: none;
}

a:hover {
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
}

//strong,
//u {
//  color: #000000;
//}

input:focus,
button:focus,
select:focus,
textarea:focus {
  outline: none;
}

.button,
button,
input[type=button],
input[type=submit] {
  box-shadow: none;
  color: #f70100;
  border: 1px solid #f70100;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Montserrat';
  font-style: normal;
  text-transform: uppercase;
  padding: 10px 35px;
  border-radius: 30px;
  background-color: transparent;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.button:hover,
button:hover,
input[type=button]:hover,
input[type=submit]:hover {
  text-decoration: none;
  background-color: #f70100;
  color: #fff;
}

.button.full,
button.full,
input[type=button].full,
input[type=submit].full {
  width: 100%;
}

.button.fill,
button.fill,
input[type=button].fill,
input[type=submit].fill {
  background-color: #f70100;
  color: #000000;
}

.button.fill:hover,
button.fill:hover,
input[type=button].fill:hover,
input[type=submit].fill:hover {
  background-color: transparent;
  color: #f70100;
  border: 1px solid #f70100;
}

.button.fill.black,
button.fill.black,
input[type=button].fill.black,
input[type=submit].fill.black {
  background-color: #222222;
  border-color: #222222;
  color: #fff;
}

.button.fill.black:hover,
button.fill.black:hover,
input[type=button].fill.black:hover,
input[type=submit].fill.black:hover {
  background-color: transparent;
  color: #000000;
}

.button.rectangle,
button.rectangle,
input[type=button].rectangle,
input[type=submit].rectangle {
  border-radius: 0px;
}

.button.small,
button.small,
input[type=button].small,
input[type=submit].small {
  font-weight: 300;
  font-size: 12px;
  padding: 12px 35px;
  line-height: 1em;
}

.button.text-light,
button.text-light,
input[type=button].text-light,
input[type=submit].text-light {
  color: #fff;
}

.button.text-light,
button.text-light,
input[type=button].text-light,
input[type=submit].text-light {
  color: #fff;
}

.clear-left {
  clear: left;
}

.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}

.hover-zoom {
  overflow: hidden;
}

.hover-zoom .image {
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.hover-zoom:hover .image {
  transform: scale(1.05);
}

.hover-dark .image,
.hover-light .image {
  position: relative;
}

.hover-dark .image::after,
.hover-light .image::after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  visibility: hidden;
  opacity: 0;
  z-index: 777;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.hover-dark:hover .image::after,
.hover-light:hover .image::after {
  visibility: visible;
  opacity: 1;
}

.hover-light .image::after {
  background-color: rgba(255, 255, 255, 0.3);
}
/* Custom Bootstrap 5 Columns
------------------------------------ */

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-15 {
  width: 20%;
  float: left;
}
@media (min-width: 768px) {

  .col-sm-15 {
    width: 20%;
    float: left;
  }

  .row-has-5-columns {
    margin-left: -10px;
    margin-right: -10px;
  }
}
@media (min-width: 992px) {

  .col-md-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 1200px) {

  .col-lg-15 {
    width: 20%;
    float: left;
  }
}
/* Circle chart
------------------------------------ */

.circle-chart {
  position: relative;
  display: inline-block;
  font-size: 16px;
}

.circle-chart .circles-wrp {
  line-height: 0px;
}

.circle-chart svg {
  width: 100%;
}

.circle-chart .circles-text {
  position: absolute;
  left: 50% !important;
  top: 50% !important;
  width: auto !important;
  height: auto !important;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  font-size: 14px !important;
  font-weight: 100;
  //font-family: 'Open sans';
  color: #fff;
}

.circle-chart .circles-text small {
  font-size: 55%;
  // font-family: 'SF UI Display';
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
}

.push-menu {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10000;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;


}

.push-menu .pm-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 0;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.push-menu .pm-container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 300px;
  height: 100%;
  background-color: #fff;
  border-right: 1px solid #e3e3e3;
  -webkit-transform: translateX(-300px);
  -moz-transform: translateX(-300px);
  transform: translateX(-300px);
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.push-menu .pm-container .pm-wrap {
  margin: 44px;
  margin-bottom: 35px;
  padding-top: 60px;
  position: relative;
}

.push-menu .pm-container .pm-wrap .close-menu {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.push-menu .pm-container .pm-wrap .close-menu::before,
.push-menu .pm-container .pm-wrap .close-menu::after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 4px;
  background-color: #000;
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -2px;
}

.push-menu .pm-container .pm-wrap .close-menu::before {
  transform: rotate(-45deg);
}

.push-menu .pm-container .pm-wrap .close-menu::after {
  transform: rotate(45deg);
}

.push-menu .pm-container .pm-wrap .big-menu {
  margin-bottom: 25px;
}

.push-menu .pm-container .pm-wrap .big-menu ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

.push-menu .pm-container .pm-wrap .big-menu ul li {
  padding-top: 7px;
  padding-bottom: 7px;
}

.push-menu .pm-container .pm-wrap .big-menu ul li a {
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 25px;
  color: #000;
}

.push-menu .pm-container .pm-wrap .small-menu ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

.push-menu .pm-container .pm-wrap .small-menu ul li {
  padding-top: 2px;
  padding-bottom: 2px;
}

.push-menu .pm-container .pm-wrap .small-menu ul li a {
  // font-family: 'SF UI Display';
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.7px;
  color: #999;
}

.push-menu .pm-container .bottom-content {
  position: absolute;
  left: 44px;
  right: 44px;
  bottom: 44px;
  border-top: 2px solid #000;
  padding-top: 35px;
}

.push-menu .pm-container .bottom-content .widget + .widget {
  margin-top: 20px;
}

.push-menu .pm-container .bottom-content .widget .widget-title {
  // font-family: 'SF UI Display';
  font-weight: 600;
  font-size: 14px;
  text-transform: none;
  letter-spacing: 0.5px;
  color: #000;
  margin: 0px;
  padding: 0px;
  margin-bottom: 3px;
}

.push-menu .pm-container .bottom-content .widget p {
  // font-family: 'SF UI Display';
  font-weight: 200;
  font-size: 14px;
  color: #bbb;
  margin: 0px;
  line-height: 1.6em;
}

.push-menu.pm-right .pm-container {
  left: auto;
  right: 0px;
  -webkit-transform: translateX(300px);
  -moz-transform: translateX(300px);
  transform: translateX(300px);
}

.push-menu.show-pm {
  visibility: visible;
  opacity: 1;
}

.push-menu.show-pm .pm-overlay {
  opacity: 1;
}

.push-menu.show-pm .pm-container {
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  transform: translateX(0px);
}

.push-menu.pm-entertainment .pm-overlay {
  background-color: rgba(0, 0, 0, 0.8);
}

.push-menu.pm-entertainment .pm-container {
  background-color: #101010;
  border-right-color: #111;
  width: 340px;
  -webkit-transform: translateX(-340px);
  -moz-transform: translateX(-340px);
  transform: translateX(-340px);
}

.push-menu.pm-entertainment .pm-container::before {
  content: '';
  background-image: url(images/pattern-en.png);
  background-repeat: repeat;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  opacity: 0.02;
}

.push-menu.pm-entertainment .pm-container .pm-viewport {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.push-menu.pm-entertainment .pm-container .pm-viewport::-webkit-scrollbar {
  display: none;
}

.push-menu.pm-entertainment .pm-container .pm-wrap {
  padding-top: 0px;
  padding-bottom: 40px;
}

.push-menu.pm-entertainment .pm-container .pm-wrap::before {
  content: '';
  position: absolute;
  right: -44px;
  bottom: -44px;
  width: 176px;
  height: 280px;
  background-image: url(images/pm-en-bottom-img.png);
  background-repeat: no-repeat;
  background-position: left top;
}

.push-menu.pm-entertainment .pm-container .pm-wrap .close-menu {
  display: none;
}

.push-menu.pm-entertainment .pm-container .pm-wrap .search-form {
  display: block;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 13px;
  margin-bottom: 45px;
  padding-right: 20px;
}

.push-menu.pm-entertainment .pm-container .pm-wrap .search-form input {
  background: transparent;
  width: 100%;
  color: #fff;
  font-family: 'Montserrat';
  font-size: 16px;
  border: none;
}

.push-menu.pm-entertainment .pm-container .pm-wrap .search-form input::-webkit-input-placeholder {
  color: #ffffff;
}

.push-menu.pm-entertainment .pm-container .pm-wrap .search-form input:-moz-placeholder {
  color: #ffffff;
}

.push-menu.pm-entertainment .pm-container .pm-wrap .search-form input::-moz-placeholder {
  color: #ffffff;
}

.push-menu.pm-entertainment .pm-container .pm-wrap .search-form input:-ms-input-placeholder {
  color: #ffffff;
}

.push-menu.pm-entertainment .pm-container .pm-wrap .search-form button {
  display: block;
  position: absolute;
  width: 14px;
  height: 14px;
  background-color: transparent;
  border: none;
  top: 0px;
  right: 0px;
  padding: 0px;
}

.push-menu.pm-entertainment .pm-container .pm-wrap .search-form button img {
  width: 100%;
}

.push-menu.pm-entertainment .pm-container .pm-wrap .author-info {
  overflow: hidden;
  margin-bottom: 45px;
}

.push-menu.pm-entertainment .pm-container .pm-wrap .author-info img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  float: left;
}

.push-menu.pm-entertainment .pm-container .pm-wrap .author-info .auth-name {
  margin-left: 150px;
  margin-top: 30px;
}

.push-menu.pm-entertainment .pm-container .pm-wrap .author-info .auth-name h4 {
  font-family: 'Montserrat';
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0px;
}

.push-menu.pm-entertainment .pm-container .pm-wrap .author-info .auth-name h4 a {
  color: #ec4343;
}

.push-menu.pm-entertainment .pm-container .pm-wrap .author-info .auth-name p {
  // font-family: 'SF UI Display';
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.3px;
}

.push-menu.pm-entertainment .pm-container .pm-wrap .author-info .auth-name p a {
  color: #fff;
}

.push-menu.pm-entertainment .pm-container .pm-wrap h4.pm-en-title {
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: normal;
  text-transform: uppercase;
  margin: 0px;
  color: rgba(255, 255, 255, 0.4);
  letter-spacing: 0.3px;
  margin-bottom: 30px;
}

.push-menu.pm-entertainment .pm-container .pm-wrap nav.big-menu {
  margin-bottom: 45px;
}

.push-menu.pm-entertainment .pm-container .pm-wrap nav.big-menu ul li {
  padding-left: 30px;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 7px;
}

.push-menu.pm-entertainment .pm-container .pm-wrap nav.big-menu ul li::before {
  content: '';
  background-color: rgba(36, 36, 36, 0.6);
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  left: -100px;
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateX(-340px);
  -moz-transform: translateX(-340px);
  transform: translateX(-340px);
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.push-menu.pm-entertainment .pm-container .pm-wrap nav.big-menu ul li a {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  letter-spacing: 0.3px;
  overflow: hidden;
  display: block;
  position: relative;
  z-index: 10;
}

.push-menu.pm-entertainment .pm-container .pm-wrap nav.big-menu ul li a::after {
  content: '';
  font-family: Fontawesome;
  font-size: 18px;
  color: #fff;
  float: right;
  margin-right: 20px;
  margin-top: 3px;
}

.push-menu.pm-entertainment .pm-container .pm-wrap nav.big-menu ul li a i {
  display: inline-block;
  width: 23px;
  height: 23px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.4;
  top: 4px;
  position: relative;
  margin-right: 22px;
}

.push-menu.pm-entertainment .pm-container .pm-wrap nav.big-menu ul li:hover::before {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  transform: translateX(0px);
}

.push-menu.pm-entertainment .pm-container .pm-wrap .pm-en-recent {
  margin-bottom: 45px;
}

.push-menu.pm-entertainment .pm-container .pm-wrap .pm-en-recent .er-item {
  display: block;
  margin-bottom: 26px;
}

.push-menu.pm-entertainment .pm-container .pm-wrap .pm-en-recent img {
  max-width: 100%;
}

.push-menu.pm-entertainment .pm-container .pm-wrap .pm-socials {
  font-size: 18px;
  padding-left: 30px;
  margin-bottom: 30px;
}

.push-menu.pm-entertainment .pm-container .pm-wrap .pm-socials a {
  color: #fff;
  display: inline-block;
  margin-right: 20px;
}

.push-menu.pm-entertainment .pm-container .pm-wrap .pm-go-home a {
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.4);
  text-transform: uppercase;
  display: block;
  line-height: 1.8em;
  position: relative;
}

.push-menu.pm-entertainment .pm-container .pm-wrap .pm-go-home a i {
  display: inline-block;
  width: 22px;
  height: 22px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 1;
  position: relative;
  top: 4px;
  margin-right: 15px;
}

.push-menu.pm-entertainment.pm-right .pm-container {
  left: auto;
  right: 0px;
  -webkit-transform: translateX(340px);
  -moz-transform: translateX(340px);
  transform: translateX(340px);
}

.push-menu.pm-entertainment.show-pm .pm-container {
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  transform: translateX(0px);
}

.push-menu.pm-news .pm-overlay {
  background-color: rgba(255, 255, 255, 0.8);
}

.push-menu.pm-news .pm-container {
  background-color: #f9f9f9;
  border-right-color: #111;
  width: 330px;
  box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.25);
  -webkit-transform: translateX(330px);
  -moz-transform: translateX(330px);
  transform: translateX(330px);
}

.push-menu.pm-news .pm-container::before {
  content: '';
  background-image: url(images/pattern-en.png);
  background-repeat: repeat;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  opacity: 0.02;
}

.push-menu.pm-news .pm-container .pm-viewport {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.push-menu.pm-news .pm-container .pm-viewport::-webkit-scrollbar {
  display: none;
}

.push-menu.pm-news .pm-container .pm-wrap {
  padding-top: 0px;
}

.push-menu.pm-news .pm-container .pm-wrap::before {
  content: '';
  position: absolute;
  right: -44px;
  bottom: -44px;
  width: 125px;
  height: 278px;
  background-image: url(images/pm-en-bottom-img.png);
  background-repeat: no-repeat;
  background-position: left top;
}

.push-menu.pm-news .pm-container .pm-wrap .close-menu {
  display: none;
}

.push-menu.pm-news .pm-container .pm-wrap .search-form {
  display: block;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 13px;
  margin-bottom: 45px;
  padding-right: 20px;
}

.push-menu.pm-news .pm-container .pm-wrap .search-form input {
  background: transparent;
  width: 100%;
  color: #fff;
  font-family: 'Montserrat';
  font-size: 16px;
  border: none;
}

.push-menu.pm-news .pm-container .pm-wrap .search-form input::-webkit-input-placeholder {
  color: #ffffff;
}

.push-menu.pm-news .pm-container .pm-wrap .search-form input:-moz-placeholder {
  color: #ffffff;
}

.push-menu.pm-news .pm-container .pm-wrap .search-form input::-moz-placeholder {
  color: #ffffff;
}

.push-menu.pm-news .pm-container .pm-wrap .search-form input:-ms-input-placeholder {
  color: #ffffff;
}

.push-menu.pm-news .pm-container .pm-wrap .search-form button {
  display: block;
  position: absolute;
  width: 14px;
  height: 14px;
  background-color: transparent;
  border: none;
  top: 0px;
  right: 0px;
  padding: 0px;
}

.push-menu.pm-news .pm-container .pm-wrap .search-form button img {
  width: 100%;
}

.push-menu.pm-news .pm-container .pm-wrap .author-info {
  overflow: hidden;
  margin-bottom: 45px;
}

.push-menu.pm-news .pm-container .pm-wrap .author-info img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  float: left;
}

.push-menu.pm-news .pm-container .pm-wrap .author-info .auth-name {
  margin-left: 145px;
  margin-top: 30px;
}

.push-menu.pm-news .pm-container .pm-wrap .author-info .auth-name h4 {
  font-family: 'Montserrat';
  font-size: 19px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0px;
}

.push-menu.pm-news .pm-container .pm-wrap .author-info .auth-name h4 a {
  color: #000;
}

.push-menu.pm-news .pm-container .pm-wrap .author-info .auth-name p {
  // font-family: 'SF UI Display';
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.3px;
}

.push-menu.pm-news .pm-container .pm-wrap .author-info .auth-name p a {
  color: rgba(0, 0, 0, 0.4);
}

.push-menu.pm-news .pm-container .pm-wrap h4.pm-en-title {
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: normal;
  text-transform: uppercase;
  margin: 0px;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0.3px;
  margin-bottom: 30px;
}

.push-menu.pm-news .pm-container .pm-wrap nav.big-menu {
  margin-bottom: 45px;
}

.push-menu.pm-news .pm-container .pm-wrap nav.big-menu ul li {
  padding-left: 0px;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 10px;
}

.push-menu.pm-news .pm-container .pm-wrap nav.big-menu ul li::before {
  content: '';
  background-color: rgba(216, 216, 216, 0.6);
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  left: -100px;
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateX(330px);
  -moz-transform: translateX(330px);
  transform: translateX(330px);
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.push-menu.pm-news .pm-container .pm-wrap nav.big-menu ul li a {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: normal;
  color: #000;
  letter-spacing: 0.3px;
  overflow: hidden;
  display: block;
  position: relative;
  z-index: 10;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.push-menu.pm-news .pm-container .pm-wrap nav.big-menu ul li a::before {
  content: '';
  font-family: Fontawesome;
  font-size: 18px;
  color: #000;
  margin-right: 60px;
  margin-top: 3px;
}

.push-menu.pm-news .pm-container .pm-wrap nav.big-menu ul li a i {
  display: inline-block;
  width: 23px;
  height: 23px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.4;
  top: 4px;
  position: relative;
  margin-right: 22px;
}

.push-menu.pm-news .pm-container .pm-wrap nav.big-menu ul li:hover a {
  -webkit-transform: translateX(-15px);
  -moz-transform: translateX(-15px);
  transform: translateX(-15px);
}

.push-menu.pm-news .pm-container .pm-wrap nav.big-menu ul li:hover::before {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateX(70px);
  -moz-transform: translateX(70px);
  transform: translateX(70px);
}

.push-menu.pm-news .pm-container .pm-wrap .pm-en-recent {
  margin-bottom: 45px;
}

.push-menu.pm-news .pm-container .pm-wrap .pm-en-recent .er-item {
  display: block;
  margin-bottom: 26px;
}

.push-menu.pm-news .pm-container .pm-wrap .pm-en-recent img {
  max-width: 100%;
}

.push-menu.pm-news .pm-container .pm-wrap .pm-socials {
  font-size: 16px;
  padding-left: 30px;
  margin-bottom: 30px;
}

.push-menu.pm-news .pm-container .pm-wrap .pm-socials a {
  color: #1b1b1b;
  display: inline-block;
}

.push-menu.pm-news .pm-container .pm-wrap .pm-socials a + a {
  margin-left: 20px;
}

.push-menu.pm-news .pm-container .pm-wrap .pm-go-home a {
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  display: block;
  line-height: 1.8em;
  position: relative;
}

.push-menu.pm-news .pm-container .pm-wrap .pm-go-home a i {
  display: inline-block;
  width: 22px;
  height: 22px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 1;
  position: relative;
  top: 4px;
  margin-right: 15px;
}

.push-menu.pm-news.pm-right .pm-container {
  left: auto;
  right: 0px;
  -webkit-transform: translateX(340px);
  -moz-transform: translateX(340px);
  transform: translateX(340px);
}

.push-menu.pm-news.show-pm .pm-container {
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  transform: translateX(0px);
}

.animated-blocks .ab-item {
  //visibility: hidden;
  //opacity: 0;
}

.animated-blocks .ab-item.animated {
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.animated-blocks .ab-item.ab-visible {
  visibility: visible;
  opacity: 1;
}
/* Blog
====================================*/

.content-area {
  padding-top: 60px;
  padding-bottom: 60px;
}
/* Single post
------------------------------------ */

.single {
  // font-family: 'Georgia';
  font-size: 16px;
}

//.single .post-title {
//  font-size: 32px;
//  line-height: 40px;
//  text-transform: uppercase;
//  font-weight: bold;
//  margin: 35px 0 25px;
//}

.single h1,
.single h2,
.single h3,
.single h4,
.single h5 {
  font-family: 'Montserrat';
  color: #000000;
}

//.single .entry-details {
//  text-align: center;
//  color: rgba(0, 0, 0, 0.7);
//  // font-family: 'SF UI Display';
//  font-size: 12px;
//  word-wrap: break-word;
//}

.single .entry-details h5,
.single .entry-details .entry-views {
  font-family: 'Montserrat';
  font-size: 18px;
}

.single .entry-details h5 {
  margin: 0;
}

.single .entry-details a {
  color: #000;
}

.single .entry-details > div {
  //position: relative;
}

.single .entry-details > div + div {
  //margin-top: 15px;
  //padding-top: 15px;
}

//.single .entry-details > div + div::before {
//  content: "";
//  display: block;
//  //position: absolute;
//  //top: 0;
//  width: 80px;
//  height: 1px;
//  //left: 50%;
//  //-webkit-transform: translateX(-50%);
//  //-moz-transform: translateX(-50%);
//  //transform: translateX(-50%);
//  background-color: rgba(0, 0, 0, 0.1);
//  margin: 10px 5px 10px auto;
//}

//.single .entry-details .entry-social {
//  font-size: 18px;
//  padding-top: 20px;
//}
//
//.single .entry-details .entry-social a {
//  display: block;
//  margin-bottom: 5px;
//}

.single article h2:first-child {
  margin-top: 0;
}
/* Blockquote
------------------------------------ */

blockquote {
  font-size: 26px;
  line-height: 32px;
  font-family: 'Montserrat';
  color: #000;
  margin: 35px 0 40px 65px;
  padding: 0 0 0 30px;
  position: relative;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

blockquote cite {
  display: block;
  font-size: 18px;
  font-weight: 300;
  // font-family: 'SF UI Display';
  font-style: normal;
  margin-bottom: 15px;
}

blockquote:before {
  content: '“';
  font-family: PlayfairDisplaySC;
  font-size: 240px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 25px;
  top: 60px;
}
/* Comments
====================================*/

#comments {
  margin-top: 50px;
  margin-bottom: 80px;
}

#comments .comments-wrapper .comments-title {
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 30px;
  letter-spacing: 1.5px;
  line-height: 1em;
  text-transform: uppercase;
  margin-bottom: 20px;
}

#comments .comments-wrapper ol.comment-list {
  margin: 0;
  padding: 0 0 0 60px;
  list-style-type: none;
  margin-bottom: 30px;
}

#comments .comments-wrapper ol.comment-list li {
  overflow: hidden;
  display: block;
}

#comments .comments-wrapper ol.comment-list li article {
  overflow: hidden;
  display: block;
  width: 100%;
  padding-bottom: 30px;
}

#comments .comments-wrapper ol.comment-list li article .avatar {
  width: 64px;
  height: 64px;
  float: left;
}

#comments .comments-wrapper ol.comment-list li article .comment-body {
  margin-left: 85px;
}

#comments .comments-wrapper ol.comment-list li article .comment-body .meta-data {
  overflow: hidden;
  position: relative;
  line-height: 1;
  margin-bottom: 10px;
}

#comments .comments-wrapper ol.comment-list li article .comment-body .meta-data .comment-author {
  font-size: 16px;
  font-family: 'Montserrat';
  color: #000000;
  position: relative;
}

#comments .comments-wrapper ol.comment-list li article .comment-body .meta-data .comment-date {
  font-size: 13px;
  color: #000000;
  display: inline-block;
}

#comments .comments-wrapper ol.comment-list li article .comment-body .meta-data .comment-reply {
  margin-left: 20px;
}

#comments .comments-wrapper ol.comment-list li article .comment-body .meta-data .comment-reply a {
  font-size: 13px;
  font-weight: 700;
  line-height: 10px;
  display: inline-block;
  letter-spacing: 0.4px;
  color: #f70100;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

#comments .comments-wrapper ol.comment-list li article .comment-body .meta-data .comment-reply a::before {
  content: '';
  font-family: Fontawesome;
  position: relative;
  margin-right: 3px;
}

#comments .comments-wrapper ol.comment-list li article .comment-body .comment-content {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
  color: rgba(77, 77, 77, 0.8);
  // font-family: 'Georgia';
}

#comments .comments-wrapper ol.comment-list li li .avatar {
  width: 48px;
  height: 48px;
}

#comments .comments-wrapper ol.comment-list li li .comment-body {
  margin-left: 69px;
}

#comments .comments-wrapper ol.comment-list li ul,
#comments .comments-wrapper ol.comment-list li ol {
  padding-left: 85px;
}

#respond .comment-reply-title {
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 30px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  letter-spacing: 0px;
  margin-bottom: 40px;
}

#respond input,
#respond textarea {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  letter-spacing: 1px;
  border-radius: 0px;
  padding: 15px 20px;
  margin-bottom: 25px;
}

#respond textarea {
  height: 200px;
}

#respond .form-submit > span {
  display: block;
  font-size: 12px;
  margin-bottom: 20px;
}

#respond .form-submit input[type="submit"] {
  width: 280px;
  border: 1px solid rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0.3);
  font-weight: 500;
  color: #fff;
  letter-spacing: 1px;
  border-radius: 0px;
}

#respond .form-submit input[type="submit"]:hover {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.3);
}
/* Page
====================================*/

.mfp-image-holder .mfp-close:hover,
.mfp-iframe-holder .mfp-close:hover:hover {
  background: none;
}
/* Layout
====================================*/
/* Boxed layout
------------------------------------ */

.boxed-layout {
  background-color: #ccc;
}

.boxed-layout > .wrapper {
  background-color: #fff;
  width: 1440px;
  margin: 0 auto;
}
/* Boxed block
------------------------------------ */

.boxed {
  background-color: #fff;
}

.boxed.wide-space {
  padding: 60px;
}
@media screen and (max-width: 768px) {

  .boxed.wide-space {
    padding: 30px;
  }
}

.boxed.wide-space .row {
  margin-left: -25px;
  margin-right: -25px;
}

.boxed.wide-space .col-lg-1,
.boxed.wide-space .col-lg-10,
.boxed.wide-space .col-lg-11,
.boxed.wide-space .col-lg-12,
.boxed.wide-space .col-lg-2,
.boxed.wide-space .col-lg-3,
.boxed.wide-space .col-lg-4,
.boxed.wide-space .col-lg-5,
.boxed.wide-space .col-lg-6,
.boxed.wide-space .col-lg-7,
.boxed.wide-space .col-lg-8,
.boxed.wide-space .col-lg-9,
.boxed.wide-space .col-md-1,
.boxed.wide-space .col-md-10,
.boxed.wide-space .col-md-11,
.boxed.wide-space .col-md-12,
.boxed.wide-space .col-md-2,
.boxed.wide-space .col-md-3,
.boxed.wide-space .col-md-4,
.boxed.wide-space .col-md-5,
.boxed.wide-space .col-md-6,
.boxed.wide-space .col-md-7,
.boxed.wide-space .col-md-8,
.boxed.wide-space .col-md-9,
.boxed.wide-space .col-sm-1,
.boxed.wide-space .col-sm-10,
.boxed.wide-space .col-sm-11,
.boxed.wide-space .col-sm-12,
.boxed.wide-space .col-sm-2,
.boxed.wide-space .col-sm-3,
.boxed.wide-space .col-sm-4,
.boxed.wide-space .col-sm-5,
.boxed.wide-space .col-sm-6,
.boxed.wide-space .col-sm-7,
.boxed.wide-space .col-sm-8,
.boxed.wide-space .col-sm-9,
.boxed.wide-space .col-xs-1,
.boxed.wide-space .col-xs-10,
.boxed.wide-space .col-xs-11,
.boxed.wide-space .col-xs-12,
.boxed.wide-space .col-xs-2,
.boxed.wide-space .col-xs-3,
.boxed.wide-space .col-xs-4,
.boxed.wide-space .col-xs-5,
.boxed.wide-space .col-xs-6,
.boxed.wide-space .col-xs-7,
.boxed.wide-space .col-xs-8,
.boxed.wide-space .col-xs-9 {
  padding-left: 25px;
  padding-right: 25px;
}

.boxed.wide-space + .boxed {
  margin-top: 40px;
}

.boxed .en-block .post + .post {
  margin-top: 50px;
}

.boxed.post {
  padding: 40px 30px;
}
/* Border line
------------------------------------ */

.vertical-middle {
  line-height: 44px;
}

.vertical-middle.border-bottom {
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
}
/* Fashion
====================================*/

#header {

}

#header.header-fashion {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 9999;
}

#header.header-fashion .panel-header {
  background: transparent;
}

#header.header-fashion .header-wrapper {
  position: relative;
  display: table;

}

#header.header-fashion .header-wrapper .site-branding {
  position: relative;
  top: 0px;
  left: 0px;
  width: auto;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  padding-right: 50px;
}

#header.header-fashion .header-wrapper nav.main-nav {
  display: table-cell;
  width: 100%;
  text-align: left;
  border: none;
  vertical-align: middle;
  padding: 0px;
}

#header.header-fashion .header-wrapper nav.main-nav > ul > li ul {
  background-color: #f9f9f9;
}

#header.header-fashion .header-wrapper nav.main-nav > ul > li ul li a {
  color: #000000;
  letter-spacing: 0px;
}

#header.header-fashion .header-wrapper nav.main-nav > ul > li.menu-item-has-children:hover::before {
  content: '';
  font-family: Fontawesome;
  font-size: 8px;
  line-height: 8px;
  color: #000000;
  position: absolute;
  bottom: 1px;
  left: 0px;
  width: 100%;
  text-align: center;
}

#header.header-fashion .header-wrapper nav.main-nav > ul > li.menu-item-has-children:hover::after {
  background-color: transparent;
}

#header.header-fashion .header-wrapper nav.main-nav > ul > li.menu-item-has-children:hover > a {
  color: #000000;
}

#header.header-fashion .header-wrapper nav.main-nav > ul > li.menu-item-has-children:hover > ul {
  -webkit-transform: translateX(-25%);
  -moz-transform: translateX(-25%);
  transform: translateX(-25%);
}

#header.header-fashion .header-wrapper .right-content {
  position: relative;
  width: auto;
  display: table-cell;
  text-align: right;
  vertical-align: middle;
  height: auto;
  line-height: 32px;
  min-width: 80px;
}

#header.header-fashion .header-wrapper .right-content .search-handler,
#header.header-fashion .header-wrapper .right-content .burger-menu {
  display: none;
}
@media screen and (max-width: 1439px) {

  #header.header-fashion .header-wrapper .right-content .search-handler,
  #header.header-fashion .header-wrapper .right-content .burger-menu {
    display: inline-block;
  }
}
@media screen and (max-width: 767px) {

  #header.header-fashion .header-wrapper {
    width: 100%;
  }

  #header.header-fashion .header-wrapper nav.main-nav {
    display: none;
  }
}

#header.header-fashion .search-panel {
  display: inline-block;
  visibility: visible;
  opacity: 1;
  width: 300px;
  height: auto;
  position: relative;
  margin: 0px;
  padding-right: 70px;
  line-height: 1em;
  border-bottom: 1px solid #000000;
  border-top: 1px solid transparent;
  overflow: hidden;
  top: inherit;
}
@media screen and (max-width: 1439px) {

  #header.header-fashion .search-panel {
    display: none;
  }
}

#header.header-fashion .search-panel input[type=text] {
  width: 100%;
  background: transparent;
  font-family: 'Montserrat';
  font-size: 13px;
  font-weight: normal;
  color: #000000;
  height: 30px;
  line-height: 30px;
  padding: 0px;
  margin: 0px;
}

#header.header-fashion .search-panel input[type=text]::-webkit-input-placeholder {
  color: #fff;
}

#header.header-fashion .search-panel input[type=text]:-moz-placeholder {
  color: #fff;
}

#header.header-fashion .search-panel input[type=text]::-moz-placeholder {
  color: #fff;
}

#header.header-fashion .search-panel input[type=text]:-ms-input-placeholder {
  color: #fff;
}

#header.header-fashion .search-panel button {
  position: absolute;
  top: 0px;
  right: 0px;
  display: block;
  border: none;
  padding: 0px;
  background: none;
  color: #000;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  height: 30px;
  line-height: 30px;
}

#header.header-fashion #burger_menu {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #000000;
  display: block;
  width: 135px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

#header.header-fashion #burger_menu img {
  width: 14px;
}
@media screen and (max-width: 1439px) {

  #header.header-fashion #burger_menu {
    display: none;
  }
}
/* Fashion Slide
==============================*/

.fs-slide {
  position: relative;


}

.fs-slide .fs-item {
  position: relative;
  padding-top: 280px;
  padding-bottom: 160px;
}

.fs-slide .fs-entry-bg {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 0;
}

.fs-slide .fs-entry-item {
  margin-right: 50px;
}

.fs-slide .fs-entry-item .fs-title {
  position: relative;
  // font-family: 'SF UI Display';
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #000000;
  margin: 0px;
}

.fs-slide .fs-entry-item .fs-title::before {
  content: attr(data-label);
  position: absolute;
  left: 0px;
  bottom: 20px;
  font-size: 160px;
  font-family: 'Montserrat';
  font-weight: bold;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  line-height: 0.72em;
}

.fs-slide .fs-entry-item .fs-title::after {
  content: '';
  width: 26px;
  height: 5px;
  display: block;
  background-color: #000000;
  margin-top: 25px;
  margin-bottom: 30px;
}

.fs-slide .fs-entry-item h3 {
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 56px;
  line-height: 60px;
  color: #000000;
  margin: 0px;
  margin-bottom: 25px;
}

.fs-slide .fs-entry-item h3 span {
  position: relative;
}

.fs-slide .fs-entry-item h3 span::after {
  content: '';
  height: 2px;
  width: 96%;
  background-color: #000000;
  position: absolute;
  bottom: 2px;
  left: 2%;
}

.fs-slide .fs-entry-item p {
  // font-family: 'Georgia';
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0px;
  color: #000000;
  margin: 0px;
  margin-bottom: 60px;
}

.fs-slide .fs-entry-item a.read-more {
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: normal;
  color: #000000;
  letter-spacing: 0px;
  text-decoration: underline;
  display: inline-block;
  position: relative;
}

.fs-slide .fs-arrows a {
  position: absolute;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
  background-color: #000000;
  color: #ffffff;
  display: inline-block;
  width: 120px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  top: 50%;
  z-index: 100;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.fs-slide .fs-arrows a i {
  font-size: 18px;
  position: relative;
  top: 1px;
}

.fs-slide .fs-arrows a.fs-arrow-prev {
  left: 0px;
}

.fs-slide .fs-arrows a.fs-arrow-prev i {
  margin-right: 4px;
}

.fs-slide .fs-arrows a.fs-arrow-next {
  right: 0px;
}

.fs-slide .fs-arrows a.fs-arrow-next i {
  margin-left: 4px;
}

.fs-slide .fs-arrows.arrows-bottom {
  position: absolute;
  bottom: 0px;
  right: 50%;
  width: 50%;
}

.fs-slide .fs-arrows.arrows-bottom a {
  transform: none;
  top: auto;
  bottom: 0px;
  width: 43px;
  height: 45px;
}

.fs-slide .fs-arrows.arrows-bottom a.fs-arrow-prev {
  background-color: #ffffff;
  color: #000000;
  bottom: 65px;
  left: auto;
  right: 138px;
}

.fs-slide .fs-arrows.arrows-bottom a.fs-arrow-prev i {
  margin: 0px;
}

.fs-slide .fs-arrows.arrows-bottom a.fs-arrow-next {
  bottom: 65px;
  right: 90px;
}

.fs-slide .fs-arrows.arrows-bottom a.fs-arrow-next::after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 110px;
  background-color: #000000;
  z-index: 0;
}

.fs-slide .fs-arrows.arrows-bottom a.fs-arrow-next i {
  margin: 0px;
  position: relative;
  z-index: 10;
}

.fs-slide .fs-arrows:not(.arrows-bottom) {

}
@media screen and (max-width: 1399px) {

  .fs-slide .fs-arrows:not(.arrows-bottom) {
    display: none;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {

  .fs-slide .fs-entry-item .fs-title::before {
    font-size: 120px;
  }

  .fs-slide .fs-entry-item h3 {
    font-size: 46px;
    line-height: 1.1em;
  }

  .fs-slide .fs-entry-item p {
    font-size: 16px;
    line-height: 1.4em;
    margin-bottom: 40px;
  }

  .fs-slide .fs-entry-item a.read-more {
    font-size: 20px;
  }
}
@media screen and (max-width: 991px) {

  .fs-slide .fs-entry-bg {
    width: 100%;
    opacity: 0.5;
  }

  .fs-slide .fs-entry-item .fs-title::before {
    font-size: 120px;
  }

  .fs-slide .fs-entry-item h3 {
    font-size: 46px;
    line-height: 1.1em;
  }

  .fs-slide .fs-entry-item p {
    font-size: 16px;
    line-height: 1.4em;
    margin-bottom: 40px;
  }

  .fs-slide .fs-entry-item a.read-more {
    font-size: 20px;
  }
}
/* Fashion Carousel
==============================*/

.fs-blog-carousel .fs-title {
  font-size: 24px;
  font-family: 'Montserrat';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0px;
  color: #000000;
  margin: 0px;
  margin-bottom: 15px;
}

.fs-blog-carousel .fs-pager {
  text-align: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 12px;
}

.fs-blog-carousel .fs-pager::before,
.fs-blog-carousel .fs-pager::after {
  content: '';
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 50%;
  height: 2px;
  width: 50%;
}

.fs-blog-carousel .fs-pager::before {
  left: 0px;
  -webkit-transform: translateX(-75px);
  -moz-transform: translateX(-75px);
  transform: translateX(-75px);
}

.fs-blog-carousel .fs-pager::after {
  right: 0px;
  -webkit-transform: translateX(75px);
  -moz-transform: translateX(75px);
  transform: translateX(75px);
}

.fs-blog-carousel .fs-pager span {
  display: inline-block;
  // font-family: 'Georgia';
  font-size: 16px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.5);
  width: 150px;
  text-align: center;
}

.fs-blog-carousel .fs-pager span a {
  display: inline-block;
}

.fs-blog-carousel .fs-pager span a img {
  width: 14px;
  opacity: 0.8;
}

.fs-blog-carousel .fs-pager span a.fs-arrow-prev {
  margin-right: 12px;
}

.fs-blog-carousel .fs-pager span a.fs-arrow-next {
  margin-left: 12px;
}

.fs-blog-carousel .fs-pager span a.swiper-button-disabled img {
  opacity: 0.3;
}

.fs-blog-carousel .fs-pager span .fs-current-index,
.fs-blog-carousel .fs-pager span .fs-current-total {
  font-style: normal;
}

.fs-blog-carousel .swiper-button-prev,
.fs-blog-carousel .swiper-button-next {
  background: none;
  background-color: #000000;
  color: #ffffff;
  display: block;
  text-align: center;
  width: 43px;
  height: 45px;
  line-height: 45px;
  position: absolute;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.fs-blog-carousel .swiper-button-prev span,
.fs-blog-carousel .swiper-button-next span {
  display: inline-block;
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0px;
  text-transform: uppercase;
  width: 0px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.fs-blog-carousel .swiper-button-prev i,
.fs-blog-carousel .swiper-button-next i {
  font-size: 18px;
}

.fs-blog-carousel .swiper-button-prev.swiper-button-prev,
.fs-blog-carousel .swiper-button-next.swiper-button-prev {
  left: -40px;
}

.fs-blog-carousel .swiper-button-prev.swiper-button-next,
.fs-blog-carousel .swiper-button-next.swiper-button-next {
  right: -40px;
}

.fs-blog-carousel .swiper-button-prev:hover,
.fs-blog-carousel .swiper-button-next:hover {
  width: 90px;
}

.fs-blog-carousel .swiper-button-prev:hover span,
.fs-blog-carousel .swiper-button-next:hover span {
  width: auto;
  visibility: visible;
  opacity: 1;
}

.fs-blog-carousel .swiper-button-prev:hover.swiper-button-prev,
.fs-blog-carousel .swiper-button-next:hover.swiper-button-prev {
  left: -50px;
}

.fs-blog-carousel .swiper-button-prev:hover.swiper-button-prev span,
.fs-blog-carousel .swiper-button-next:hover.swiper-button-prev span {
  margin-left: 3px;
}

.fs-blog-carousel .swiper-button-prev:hover.swiper-button-next,
.fs-blog-carousel .swiper-button-next:hover.swiper-button-next {
  right: -50px;
}

.fs-blog-carousel .swiper-button-prev:hover.swiper-button-next span,
.fs-blog-carousel .swiper-button-next:hover.swiper-button-next span {
  margin-right: 3px;
}
/* Fashion Blog Item
==============================*/

.fs-blog-item {
  position: relative;
}

.fs-blog-item > a {
  display: inline-block;
}

.fs-blog-item img {
  width: 100%;
  height: auto;
}

.fs-blog-item h4 {
  font-size: 16px;
  // font-family: 'SF UI Display';
  font-weight: 400;
  letter-spacing: 0.3px;
  margin: 0px;
  margin-top: 20px;
  line-height: 1.2em;
}

.fs-blog-item h4 a {
  color: #000000;
}

.fs-blog-item .fs-label {
  display: inline-block;
  background-color: #000;
  color: #fff;
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 10px;
  letter-spacing: 0px;
  text-transform: uppercase;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 12px 16px;
  line-height: 1em;
}

.fs-blog-item.boxed-title .entry-title {
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px 30px;
  background-color: #fff;
  position: relative;
  margin-top: -50px;
}
@media screen and (max-width: 480px) {

  .fs-blog-item.boxed-title .entry-title {
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px 15px;
  }
}

.fs-blog-item.boxed-title .entry-title h4 {
  margin-top: 0px;
}

.fs-blog-item.boxed-title .entry-title .read-more {
  margin: 0px;
  margin-top: 5px;
}

.fs-blog-item.boxed-title .entry-title .read-more a {
  color: #000000;
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 13px;
  text-decoration: underline;
  letter-spacing: 0px;
}
/* Fashion Blog Filter
==============================*/

.fs-post-filter {
  overflow: hidden;
  margin-bottom: 25px;
}

.fs-post-filter h4 {
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 46px;
  margin: 0px;
  text-transform: uppercase;
  color: #000000;
  line-height: 49px;
  letter-spacing: 0px;
  float: left;
}

.fs-post-filter ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  display: block;
  float: right;
  line-height: 49px;
}

.fs-post-filter ul li {
  display: inline-block;
}

.fs-post-filter ul li + li {
  margin-left: 30px;
}

.fs-post-filter ul li a {
  font-size: 13px;
  font-family: 'Montserrat';
  font-weight: normal;
  color: #000000;
  opacity: 0.4;
  display: inline-block;
  position: relative;
}

.fs-post-filter ul li.active a {
  font-weight: bold;
  opacity: 1;
}

.fs-post-filter ul li.active a::after {
  content: '';
  position: absolute;
  left: 0px;
  bottom: 5px;
  height: 2px;
  width: 100%;
  background-color: #000000;
}

.fs-post-filter.bordered {
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.fs-post-filter.bordered h4 {
  font-size: 24px;
}

.fs-post-filter.bordered ul li.active a::after {
  display: none;
}

.fs-post-filter.big-title {
  overflow: inherit;
  margin-bottom: 45px;
}

.fs-post-filter.big-title::after {
  content: '';
  clear: both;
  position: relative;
  display: block;
}

.fs-post-filter.big-title h4 {
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 50px;
  position: relative;
}

.fs-post-filter.big-title h4::after {
  content: attr(data-title);
  position: absolute;
  opacity: .1;
  font-size: 160px;
  top: -20px;
  left: -14px;
}
/* Fashion Grid Blog
==============================*/

.fs-grid-posts .fs-grid-item {
  margin-bottom: 50px;
}

.fs-grid-posts .fs-grid-item .fs-entry-image {
  display: block;
}

.fs-grid-posts .fs-grid-item .fs-entry-image img {
  width: 100%;
}

.fs-grid-posts .fs-grid-item .fs-entry-meta {
  // font-family: 'SF UI Display';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  margin-top: 15px;
}

.fs-grid-posts .fs-grid-item .fs-entry-meta span {
  display: inline-block;
}

.fs-grid-posts .fs-grid-item .fs-entry-meta span + span::before {
  content: '|';
  margin-left: 10px;
  margin-right: 10px;
}

.fs-grid-posts .fs-grid-item .fs-entry-meta span a {
  color: rgba(0, 0, 0, 0.9);
}

.fs-grid-posts .fs-grid-item h3 {
  font-family: 'Montserrat';
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  margin: 0px;
  margin-top: 12px;
  line-height: 1.3em;
}

.fs-grid-posts .fs-grid-item h3 a {
  color: #000000;
}

.fs-grid-posts .fs-grid-item .read-more {
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: normal;
  margin: 0px;
  margin-top: 12px;
}

.fs-grid-posts .fs-grid-item .read-more a {
  color: #000000;
  text-decoration: underline;
}

.fs-grid-posts .fs-grid-item.fs-large h3 {
  font-size: 24px;
  line-height: 32px;
}

.fs-grid-posts .fs-grid-item.fs-large .read-more {
  font-size: 15px;
}

.fs-sidebar .widget {
  margin-bottom: 50px;
}

.fs-sidebar .widget .widget-title {
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0px;
  color: #000000;
  margin: 0px;
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 3px solid rgba(0, 0, 0, 0.4);
}

.fs-sidebar .widget.widget_search .widget-title {
  padding-bottom: 0px;
  border: 0px;
}

.fs-sidebar .widget.widget_search form {
  position: relative;
  padding: 0px;
  padding-right: 70px;
  border: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.fs-sidebar .widget.widget_search form input[type=text] {
  font-family: 'Montserrat';
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  height: 32px;
  line-height: 32px;
  letter-spacing: 0px;
  border: 0px;
  width: 100%;
  text-transform: none;
}

.fs-sidebar .widget.widget_search form input[type=submit] {
  font-family: 'Montserrat';
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0px;
  border: 0px;
  border-radius: 0px;
  padding: 0px;
  margin: 0px;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 32px;
  line-height: 32px;
  width: auto;
}

.fs-sidebar .widget .fs-recent-post .fs-rp-item {
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.fs-sidebar .widget .fs-recent-post .fs-rp-item:last-child {
  border-bottom: 0px;
}

.fs-sidebar .widget .fs-recent-post .fs-rp-item .entry-image {
  float: left;
}

.fs-sidebar .widget .fs-recent-post .fs-rp-item .entry-image a {
  display: inline-block;
}

.fs-sidebar .widget .fs-recent-post .fs-rp-item .entry-image img {
  width: 92px;
  height: 92px;
}

.fs-sidebar .widget .fs-recent-post .fs-rp-item .entry-rp {
  margin-left: 110px;
}

.fs-sidebar .widget .fs-recent-post .fs-rp-item .entry-rp .entry-meta {
  // font-family: 'SF UI Display';
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2em;
}

.fs-sidebar .widget .fs-recent-post .fs-rp-item .entry-rp .entry-meta span {
  display: inline-block;
}

.fs-sidebar .widget .fs-recent-post .fs-rp-item .entry-rp .entry-meta span + span::before {
  content: '|';
  margin-left: 5px;
  margin-right: 5px;
}

.fs-sidebar .widget .fs-recent-post .fs-rp-item .entry-rp .entry-meta span:last-child {
  display: none;
}

.fs-sidebar .widget .fs-recent-post .fs-rp-item .entry-rp .entry-meta span a {
  color: rgba(0, 0, 0, 0.9);
}

.fs-sidebar .widget .fs-recent-post .fs-rp-item h4 {
  // font-family: 'SF UI Display';
  font-size: 17px;
  font-weight: 300;
  margin: 0px;
  margin-top: 5px;
}

.fs-sidebar .widget .fs-recent-post .fs-rp-item h4 a {
  color: #000000;
}

.fs-sidebar .widget .fs-recent-post .fs-rp-item .read-more {
  margin: 0px;
  font-family: 'Montserrat';
  font-size: 13px;
  font-weight: normal;
  line-height: 1.3em;
  margin-top: 5px;
}

.fs-sidebar .widget .fs-recent-post .fs-rp-item .read-more a {
  color: #000000;
  text-decoration: underline;
}

.fs-sidebar .widget .fs-recent-post .fs-rp-item.no-thumb .entry-rp {
  margin-left: 0px;
}

.fs-sidebar .widget .fs-recent-post .fs-rp-item.no-thumb .entry-rp span:last-child {
  display: inline-block;
}

.fs-sidebar .widget ul {
  // font-family: 'SF UI Display';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.fs-sidebar .widget ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.fs-sidebar .widget ul li:last-child {
  border-bottom: 0px;
}

.fs-sidebar .widget ul li a {
  display: block;
  overflow: hidden;
  color: #000000;
  line-height: 1.8em;
}

.fs-sidebar .widget ul li a span {
  float: right;
  color: rgba(0, 0, 0, 0.7);
}

.fs-sidebar .widget ul li a span::before {
  content: '(';
}

.fs-sidebar .widget ul li a span::after {
  content: ')';
}

.fs-sidebar .widget img {
  max-width: 100%;
  height: auto;
}

.fs-sidebar .widget .fs-tags {
  // font-family: 'SF UI Display';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
  text-transform: lowercase;
}

.fs-sidebar .widget .fs-tags a {
  color: rgba(0, 0, 0, 0.5);
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.5);
  line-height: 1em;
  padding: 6px 12px;
  margin-right: 8px;
  margin-bottom: 10px;
}

.fs-sidebar .widget-aboutme img {
  margin-bottom: 22px;
}

.fs-sidebar .widget-aboutme h5 {
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: normal;
  color: #000000;
  letter-spacing: 0px;
  margin: 0px;
}

.fs-sidebar .widget-aboutme p {
  margin: 0px;
}

.fs-sidebar .widget-aboutme .about-text {
  font-size: 14px;
  // font-family: 'SF UI Display';
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 15px;
  margin-bottom: 10px;
}

.fs-sidebar .widget-aboutme .read-more a {
  font-size: 15px;
  font-family: 'Montserrat';
  font-weight: 400;
  color: #000000;
  text-decoration: underline;
  letter-spacing: 0px;
}
@media screen and (max-width: 426px) {

  .fs-post-table .fs-table-item {
    margin-bottom: 40px;
  }

  .fs-post-table .fs-table-item div[class*=col-] + div[class*=col-] {
    margin-top: 20px;
  }
}

.fs-post-table .fs-table-item .fs-table-bg {
  background-repeat: no-repeat;
  background-position: center-top;
  background-size: cover;
}

.fs-post-table .fs-table-item .fs-table-bg img {
  width: 100%;
}

.fs-post-table .fs-table-item .fs-table-content {
  display: inline-block;
  padding: 100px;
}
@media screen and (max-width: 786px) {

  .fs-post-table .fs-table-item .fs-table-content {
    padding: 30px;
  }
}
@media screen and (max-width: 426px) {

  .fs-post-table .fs-table-item .fs-table-content {
    padding: 0;
  }
}

.fs-post-table .fs-table-item .fs-table-content h4 {
  margin: 0px;
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 26px;
  line-height: 1.2em;
  letter-spacing: 0px;
}
@media screen and (max-width: 786px) {

  .fs-post-table .fs-table-item .fs-table-content h4 {
    font-size: 20px;
  }
}

.fs-post-table .fs-table-item .fs-table-content h4 a {
  color: #000000;
}

.fs-post-table .fs-table-item .fs-table-content .read-more {
  margin: 0px;
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0px;
  margin-top: 25px;
}

.fs-post-table .fs-table-item .fs-table-content .read-more a {
  color: #000000;
  text-decoration: underline;
}

.fs-post-table .fs-table-item .fs-table-meta {
  width: 100%;
  // font-family: 'SF UI Display';
  font-weight: 400;
  font-size: 14px;
}
@media screen and (min-width: 769px) {

  .fs-post-table .fs-table-item .fs-table-meta {
    position: absolute;
    bottom: 30px;
    left: 0px;
    padding-left: 50px;
  }
}

.fs-post-table .fs-table-item .fs-table-meta a {
  color: rgba(0, 0, 0, 0.9);
}

.fs-post-table .fs-table-item .row {
  display: table;
  position: relative;
  width: 100%;
  margin: 0px;
}
@media screen and (min-width: 769px) {

  .fs-post-table .fs-table-item .row > div {
    display: table-cell;
    position: relative;
    width: 50%;
    vertical-align: middle;
    padding: 0px;
    float: none;
  }
}

.fs-post-table .fs-table-item.fs-media-right .fs-table-content {
  text-align: right;
}

.fs-post-table .fs-table-item.fs-media-right .fs-table-meta {
  padding-left: 0px;
}
@media screen and (min-width: 769px) {

  .fs-post-table .fs-table-item.fs-media-right .fs-table-meta {
    padding-right: 50px;
  }
}

.sticky-parent {
  display: block;
  position: relative;
}

.fs-media-play a {
  display: inline-block;
  background-color: #000;
  color: #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  font-size: 10px;
}

.fs-media-play a i {
  position: relative;
  left: 1px;
}
@media screen and (min-width: 992px) {

  .fs-with-sidebar > .col-sm-9 {
    padding-right: 45px;
  }
}

.fs-blog-item a,
.fs-grid-item:not(.fs-large) a {
  overflow: hidden;
}

.fs-blog-item a > img,
.fs-grid-item:not(.fs-large) a > img {
  transition: all .35s ease;
}

.fs-blog-item a > img:hover,
.fs-grid-item:not(.fs-large) a > img:hover {
  transform: scale(1.05);
}
/* Footer
====================================*/
/* Background colors
------------------------------------ */

.footer-row {
  margin-top: 30px;
  margin-bottom: 50px;
}
@media screen and (max-width: 768px) {

  .footer-row {
    margin-bottom: 20px;
  }

  .footer-row div[class*=col-],
  .footer-row .widget {
    margin-bottom: 30px;
  }

  .footer-row div:last-child {
    margin-bottom: 0;
  }
}

.sub-footer {
  color: #414141;
}

.sub-footer a {
  color: #414141;
}

.sub-footer a:hover {
  color: #5a5a5a;
}

.sub-footer p,
.sub-footer ul {
  margin-top: 0;
  margin-bottom: 0;
}

.sub-footer ul li {
  position: relative;
}

.sub-footer ul li + li {
  margin-left: 5px;
  padding-left: 10px;
}

.sub-footer ul li + li:before {
  content: "|";
  position: absolute;
  left: -5px;
  top: 0;
}

.sub-footer .copyright-text i {
  font-size: 24px;
  line-height: 0;
  margin-right: 10px;
}

.sub-footer .copyright-text i:hover {
  cursor: pointer;
}
@media screen and (max-width: 768px) {

  .sub-footer {
    text-align: center;
  }

  .sub-footer ul li + li {
    margin: 0;
    padding: 0 5px;
  }

  .sub-footer ul li + li:before {
    display: none;
  }

  .sub-footer .copyright-text {
    margin: 20px 0 0;
    line-height: 1.5;
  }
}

.footer-instagram .row {
  margin: 0px;
}

.footer-instagram .row > div {
  padding: 0px;
}

.footer-instagram h4 {
  font-size: 24px;
  font-family: 'Montserrat';
  font-weight: normal;
  letter-spacing: 0px;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  display: inline-block;
  padding-bottom: 15px;
}

.footer-instagram img {
  width: 100%;
}
/* Widgets
====================================*/
/* Widget
============================================== */

.widget + .widget {
  margin-top: 40px;
}

.widget .widget-title {
  text-transform: uppercase;
}

.widget ul {
  padding: 0;
}

.widget ul li {
  list-style-type: none;
  line-height: 2.5em;
}

.widget ul li a {
  color: #949494;
}

.widget ul li a:hover {
  text-decoration: none;
  color: #c7c7c7;
}

.widget.widget_recent_posts ul li {
  padding-left: 20px;
  line-height: 1.4em;
  margin: 15px 0;
  position: relative;
}

.widget.widget_recent_posts ul li:before {
  content: '';
  display: block;
  border: 1px solid #f84432;
  position: absolute;
  left: 0;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  top: 5px;
}

.widget .social-links {
  display: block;
}

.widget .social-links a {
  display: inline-block;
  color: #949494;
  font-size: 16px;
  margin-right: 15px;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.widget .social-links a:hover {
  color: #c7c7c7;
}
/* Sidebar
============================================== */

.sidebar {
  padding-left: 35px;
}

.sidebar.pull-left {
  padding-left: 15px;
  padding-right: 35px;
}

.sidebar .widget-title {
  text-transform: uppercase;
  font-size: 20px;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #000000;
  margin: 0px;
  margin-bottom: 30px;
}

.sidebar .widget-title span {
  display: inline-block;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 15px;
}

.sidebar .widget-title.color-1 span {
  border-color: #f84432;
}

.sidebar .widget-title.color-2 span {
  border-color: #1976d2;
}

.sidebar .widget-title.color-3 span {
  border-color: #ffa018;
}

.sidebar ul li {
  line-height: 2.5em;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: normal;
  letter-spacing: 0.3px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.sidebar ul li a {
  color: #000000;
}

.sidebar ul li a:hover {
  text-decoration: none;
  color: #333333;
}

.pull-right + .sidebar {
  padding-left: 15px;
  padding-right: 35px;
}

.widget + .widget {
  margin-top: 40px;
}

.widget img {
  max-width: 100%;
  height: auto;
}

.widget select {
  width: 100%;
}

.widget ul {
  padding: 0;
}

.widget ul li {
  list-style-type: none;
}

.widget ul li a {
  line-height: 1.2em;
}

.widget ul li ul {
  margin-left: 20px;
}

.widget ul li p {
  line-height: 1.2em;
  // font-family: 'Georgia';
}

.widget.no-border ul li {
  margin-bottom: 30px;
  border: none;
}

.widget.categories ul li span {
  float: right;
}

.widget.recent-posts ul li {
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.widget.recent-posts ul li span {
  font-size: 12px;
  // font-family: 'Georgia';
}

.widget .widget-tags a {
  background-color: rgba(0, 0, 0, 0.07);
  padding: 5px 10px;
  margin-bottom: 5px;
  font-family: 'Montserrat';
  color: #000;
  display: inline-block;
  text-transform: lowercase;
  text-decoration: none;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.widget .widget-tags a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.widget .rssSummary {
  font-size: 14px;
  font-weight: normal;
  // font-family: 'Georgia';
  color: rgba(0, 0, 0, 0.7);
  line-height: 1.8em;
}

.widget #wp-calendar,
.widget table {
  width: 100%;
}

.widget .search_form {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: block;
  padding-left: 20px;
  padding-right: 50px;
}

.widget .search_form input {
  display: block;
  border: 0px;
  height: 42px;
  line-height: 42px;
  width: 100%;
  // font-family: 'SF UI Display';
  font-size: 10px;
  font-weight: 300;
  font-style: italic;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: transparent;
}

.widget .search_form button {
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 0px;
  background-color: #e6e6e6;
  height: 42px;
  padding-left: 15px;
  padding-right: 15px;
  border: 0px;
  line-height: 42px;
  padding-top: 0px;
  padding-bottom: 0px;
  color: rgba(0, 0, 0, 0.5);
  border-left: 1px solid rgba(0, 0, 0, 0.3);
}

.widget .tagcloud a {
  color: #000000;
}

.widget ul li:last-child {
  border: none;
}

.subscribe-form {
  position: relative;
  border-radius: 0px;
}

.subscribe-form input {
  width: 100%;
  max-width: 280px;
  height: 42px;
  font-size: 10px;
  text-transform: uppercase;
  font-style: italic;
  background: #2a2a2a;
  border: none;
  padding-left: 20px;
  letter-spacing: 1px;
}

.subscribe-form button {
  height: 42px;
  width: 44px;
  color: #fff;
  background: #666666;
  font-size: 18px;
  border: none;
  border-radius: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.subscribe-form.envelope input {
  // font-family: 'SF UI Display';
  font-style: italic;
  background-color: #1f1f1f;
}

.subscribe-form.envelope button {
  background-color: #1a1a1a;
  color: #fff;
}

.light .widget-title {
  color: #333333;
}

.light .subscribe-form input {
  background: transparent;
  border: 1px solid #a2a2a2;
}

.light .subscribe-form button {
  color: #a2a2a2;
  background-color: #e6e6e6;
  border: 1px solid #a2a2a2;
}

/* Travel
====================================*/

.travel-item {
  background-color: #fff;
}

.travel-item .entry-img {
  margin: 0px;
  position: relative;
}

.travel-item .entry-img .entry-link {
  display: block;
  position: relative;
  overflow: hidden;
}

.travel-item .entry-img .entry-link img {
  width: 100%;
  height: auto;
  -webkit-transition: all 0.45s ease;
  -moz-transition: all 0.45s ease;
  transition: all 0.45s ease;
}

.travel-item .entry-img .entry-link:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
}

.travel-item .entry-info {
  padding: 35px;
}

.travel-item .entry-info h3 {
  line-height: 1.2em;
  font-size: 15px;
  font-family: 'Montserrat';
  font-weight: normal;
  color: #000000;
  text-transform: uppercase;
  margin: 0px;
  position: relative;
  padding-right: 60px;
}

.travel-item .entry-info h3 .price {
  display: inline-block;
  color: #f62332;
  position: absolute;
  right: 0px;
  top: 0px;
}

.travel-item .entry-info .info {
  // font-family: 'SF UI Display';
  font-size: 13px;
  line-height: 1.8em;
  color: rgba(77, 77, 77, 0.8);
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.travel-item .entry-info .readmore {
  text-align: center;
}

.travel-item .entry-info .readmore a.button {
  font-size: 12px;
  padding: 3px 20px;
}

.travel-item-boxed {
  position: relative;
  overflow: hidden;
}

.travel-item-boxed a.entry-link {
  display: block;
  position: relative;
  cursor: ew-resize;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-transition-duration: 0.35s;
  -moz-transition-duration: 0.35s;
  transition-duration: 0.35s;
}

.travel-item-boxed a.entry-link::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 100px;

  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);

  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);

  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);

  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#bd000000', GradientType=0);

}

.travel-item-boxed a.entry-link::after {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 50%;

  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.74) 100%);

  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.74) 100%);

  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.74) 100%);

  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#bd000000', GradientType=0);

}

.travel-item-boxed .img-spacer {
  width: 100%;
}

.travel-item-boxed .price {
  font-family: 'Montserrat';
  font-weight: bold;
  color: #fff;
  top: 50px;
  left: 60px;
  position: absolute;
}

.travel-item-boxed .entry-info {
  position: absolute;
  left: 60px;
  right: 60px;
  bottom: 50px;
}

.travel-item-boxed .entry-info::before {
  content: '';
  width: 19px;
  height: 27px;
  position: absolute;
  top: 0px;
  left: -27px;
  background-image: url(images/pin-bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
}

.travel-item-boxed .entry-info h3 {
  font-size: 24px;
  font-weight: bold;
  font-family: 'Montserrat';
  margin: 0px;
}

.travel-item-boxed .entry-info h3 a {
  color: #fff;
}

.travel-item-boxed .entry-info .desc {
  font-size: 14px;
  line-height: 1.4;
  font-family: 'Montserrat';
  font-weight: normal;
  color: #d2d2d2;
  margin-top: 5px;
}

.travel-item-boxed:hover a.entry-link {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
}

.carousel-travel {
  position: relative;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.carousel-travel.loaded {
  visibility: visible;
  opacity: 1;
}

.carousel-travel .swiper-button-prev,
.carousel-travel .swiper-button-next {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #f70100;
  text-align: center;
  line-height: 50px;
}

.carousel-travel .swiper-button-prev::before,
.carousel-travel .swiper-button-next::before {
  font-family: Fontawesome;
  font-size: 25px;
  color: #f70100;
}

.carousel-travel .swiper-button-prev {
  background: none;
  left: -70px;
}

.carousel-travel .swiper-button-prev::before {
  content: '';
}

.carousel-travel .swiper-button-next {
  background: none;
  right: -70px;
}

.carousel-travel .swiper-button-next::before {
  content: '';
}

.carousel-travel .swiper-slide:nth-child(2n) .travel-item-boxed .entry-info::before {
  background-image: url(images/pin-red.png);
}
/* Helpers
====================================*/

.inner-table {
  display: table;
  width: 100%;
  height: 100%;
}

.inner-table .inner-cell {
  display: table-cell;
  vertical-align: middle;
}

.text-light {
  color: #fff;
}

.text-light.title-lg,
.text-light.title-md {
  color: #fff;
}

.width-auto {
  width: auto;
}

.text-thin {
  font-weight: 100;
}

.border-radius {
  border-radius: 50%;
}
/* Background colors
------------------------------------ */

.background-gray {
  background-color: #f9f9f9;
}
/* Predefined heights
------------------------------------ */

.height-tall {
  height: 720px;
}

.height-middle {
  height: 540px;
}

.height-short {
  height: 320px;
}

.full-size {
  width: 100%;
  max-width: 100%;
}
/* Font size
------------------------------------ */

.font12 {
  font-size: 12px;
}

.font14 {
  font-size: 14px;
}

.font16 {
  font-size: 16px;
}

.font18 {
  font-size: 18px;
}

.font20 {
  font-size: 20px;
}

.font22 {
  font-size: 22px;
}

.font24 {
  font-size: 24px;
}

.font26 {
  font-size: 26px;
}

.font28 {
  font-size: 28px;
}

.font30 {
  font-size: 30px;
}

.font32 {
  font-size: 32px;
}

.font36 {
  font-size: 36px;
}

.font40 {
  font-size: 40px;
}

.font44 {
  font-size: 44px;
}

.font48 {
  font-size: 48px;
}
/* Color
------------------------------------ */

.color-brand {
  color: #f70100;
}

.text-brand {
  color: #f70100;
}

.color-1 {
  color: #f84432;
}

.color-1 .label {
  background: $main-color;
  color: #fff;
}

.color-1 .label:before {
  background: #f84432;
}

.color-1 .meta:before {
  border-color: #f84432;
}

.color-2 .label {
  background: $main-color;
  color: #fff;
}

.color-2 .label:before {
  background: #1976d2;
}

.color-2 .meta:before {
  border-color: #1976d2;
}

.color-3 .label {
  background: $main-color;
  color: #fff;
}

.color-3 .label:before {
  background: #ffa018;
}

.color-3 .meta:before {
  border-color: #ffa018;
}

.color-4 .label {
  background: $main-color;
  color: #fff;
}

.color-4 .label:before {
  background: #505050;
}

.color-4 .meta:before {
  border-color: #505050;
}

.bgred {
  background: #f84432;
  color: #fff;
}

.bgblue {
  background: #1976d2;
  color: #fff;
}

.bgyellow {
  background: #ffa018;
  color: #fff;
}

.bgwhite {
  background: #fff;
  color: #000;
}

.bgwhite h4 a,
.bgwhite a,
.bgwhite .meta .author,
.bgwhite .meta .date {
  color: #000;
}
/* Padding
------------------------------------ */

.p1 {
  padding: 10px;
}

.p2 {
  padding: 20px;
}

.p3 {
  padding: 30px;
}

.p4 {
  padding: 40px;
}

.p5 {
  padding: 50px;
}

.p6 {
  padding: 60px;
}

.p7 {
  padding: 70px;
}

.p8 {
  padding: 80px;
}

.p9 {
  padding: 90px;
}

.p10 {
  padding: 100px;
}

.p11 {
  padding: 110px;
}

.p12 {
  padding: 120px;
}

.p13 {
  padding: 130px;
}

.p14 {
  padding: 140px;
}

.p15 {
  padding: 150px;
}

.p16 {
  padding: 160px;
}

.p0 {
  padding: 0px;
}

.pt1 {
  padding-top: 10px;
}

.pt2 {
  padding-top: 20px;
}

.pt3 {
  padding-top: 30px;
}

.pt4 {
  padding-top: 40px;
}

.pt5 {
  padding-top: 50px;
}

.pt6 {
  padding-top: 60px;
}

.pt7 {
  padding-top: 70px;
}

.pt8 {
  padding-top: 80px;
}

.pt9 {
  padding-top: 90px;
}

.pt10 {
  padding-top: 100px;
}

.pt11 {
  padding-top: 110px;
}

.pt12 {
  padding-top: 120px;
}

.pt13 {
  padding-top: 130px;
}

.pt14 {
  padding-top: 140px;
}

.pt15 {
  padding-top: 150px;
}

.pt16 {
  padding-top: 160px;
}

.pt0 {
  padding-top: 0px;
}

.pr1 {
  padding-right: 10px;
}

.pr2 {
  padding-right: 20px;
}

.pr3 {
  padding-right: 30px;
}

.pr4 {
  padding-right: 40px;
}

.pr5 {
  padding-right: 50px;
}

.pr6 {
  padding-right: 60px;
}

.pr7 {
  padding-right: 70px;
}

.pr8 {
  padding-right: 80px;
}

.pr9 {
  padding-right: 90px;
}

.pr10 {
  padding-right: 100px;
}

.pr11 {
  padding-right: 110px;
}

.pr12 {
  padding-right: 120px;
}

.pr13 {
  padding-right: 130px;
}

.pr14 {
  padding-right: 140px;
}

.pr15 {
  padding-right: 150px;
}

.pr16 {
  padding-right: 160px;
}

.pr0 {
  padding-right: 0px;
}

.pb1 {
  padding-bottom: 10px;
}

.pb2 {
  padding-bottom: 20px;
}

.pb3 {
  padding-bottom: 30px;
}

.pb4 {
  padding-bottom: 40px;
}

.pb5 {
  padding-bottom: 50px;
}

.pb6 {
  padding-bottom: 60px;
}

.pb7 {
  padding-bottom: 70px;
}

.pb8 {
  padding-bottom: 80px;
}

.pb9 {
  padding-bottom: 90px;
}

.pb10 {
  padding-bottom: 100px;
}

.pb11 {
  padding-bottom: 110px;
}

.pb12 {
  padding-bottom: 120px;
}

.pb13 {
  padding-bottom: 130px;
}

.pb14 {
  padding-bottom: 140px;
}

.pb15 {
  padding-bottom: 150px;
}

.pb16 {
  padding-bottom: 160px;
}

.pb0 {
  padding-bottom: 0px;
}

.pl1 {
  padding-left: 10px;
}

.pl2 {
  padding-left: 20px;
}

.pl3 {
  padding-left: 30px;
}

.pl4 {
  padding-left: 40px;
}

.pl5 {
  padding-left: 50px;
}

.pl6 {
  padding-left: 60px;
}

.pl7 {
  padding-left: 70px;
}

.pl8 {
  padding-left: 80px;
}

.pl9 {
  padding-left: 90px;
}

.pl10 {
  padding-left: 100px;
}

.pl11 {
  padding-left: 110px;
}

.pl12 {
  padding-left: 120px;
}

.pl13 {
  padding-left: 130px;
}

.pl14 {
  padding-left: 140px;
}

.pl15 {
  padding-left: 150px;
}

.pl16 {
  padding-left: 160px;
}

.pl0 {
  padding-left: 0px;
}

.ph1 {
  padding-left: 10px;
  padding-right: 10px;
}

.ph2 {
  padding-left: 20px;
  padding-right: 20px;
}

.ph3 {
  padding-left: 30px;
  padding-right: 30px;
}

.ph4 {
  padding-left: 40px;
  padding-right: 40px;
}

.ph5 {
  padding-left: 50px;
  padding-right: 50px;
}

.ph6 {
  padding-left: 60px;
  padding-right: 60px;
}

.ph7 {
  padding-left: 70px;
  padding-right: 70px;
}

.ph8 {
  padding-left: 80px;
  padding-right: 80px;
}

.ph9 {
  padding-left: 90px;
  padding-right: 90px;
}

.ph10 {
  padding-left: 100px;
  padding-right: 100px;
}

.ph11 {
  padding-left: 110px;
  padding-right: 101px;
}

.ph12 {
  padding-left: 120px;
  padding-right: 120px;
}

.ph13 {
  padding-left: 130px;
  padding-right: 130px;
}

.ph14 {
  padding-left: 140px;
  padding-right: 140px;
}

.ph15 {
  padding-left: 150px;
  padding-right: 150px;
}

.ph16 {
  padding-left: 160px;
  padding-right: 160px;
}

.ph0 {
  padding-left: 0px;
  padding-right: 0px;
}

.phl0 {
  padding-left: 0px;
}

.phr0 {
  padding-right: 0px;
}

.pv1 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pv2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pv3 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.pv4 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pv5 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.pv6 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.pv7 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.pv8 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.pv9 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.pv10 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pv11 {
  padding-top: 110px;
  padding-bottom: 101px;
}

.pv12 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.pv13 {
  padding-top: 130px;
  padding-bottom: 130px;
}

.pv14 {
  padding-top: 140px;
  padding-bottom: 140px;
}

.pv15 {
  padding-top: 150px;
  padding-bottom: 150px;
}

.pv16 {
  padding-top: 160px;
  padding-bottom: 160px;
}

.pv0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.pvt0 {
  padding-top: 0px;
}

.pvb0 {
  padding-bottom: 0px;
}
/* Margin
------------------------------------ */

.m1 {
  margin: 10px;
}

.m2 {
  margin: 20px;
}

.m3 {
  margin: 30px;
}

.m4 {
  margin: 40px;
}

.m5 {
  margin: 50px;
}

.m6 {
  margin: 60px;
}

.m7 {
  margin: 70px;
}

.m8 {
  margin: 80px;
}

.m9 {
  margin: 90px;
}

.m10 {
  margin: 100px;
}

.m11 {
  margin: 110px;
}

.m12 {
  margin: 120px;
}

.m13 {
  margin: 130px;
}

.m14 {
  margin: 140px;
}

.m15 {
  margin: 150px;
}

.m16 {
  margin: 160px;
}

.m0 {
  margin: 0;
}

.ml1 {
  margin-left: 10px;
}

.ml2 {
  margin-left: 20px;
}

.ml3 {
  margin-left: 30px;
}

.ml4 {
  margin-left: 40px;
}

.ml5 {
  margin-left: 50px;
}

.ml6 {
  margin-left: 60px;
}

.ml7 {
  margin-left: 70px;
}

.ml8 {
  margin-left: 80px;
}

.ml9 {
  margin-left: 90px;
}

.ml10 {
  margin-left: 100px;
}

.ml11 {
  margin-left: 110px;
}

.ml12 {
  margin-left: 120px;
}

.ml13 {
  margin-left: 130px;
}

.ml14 {
  margin-left: 140px;
}

.ml15 {
  margin-left: 150px;
}

.ml16 {
  margin-left: 160px;
}

.ml0 {
  margin-left: 0;
}

.mr1 {
  margin-right: 10px;
}

.mr2 {
  margin-right: 20px;
}

.mr3 {
  margin-right: 30px;
}

.mr4 {
  margin-right: 40px;
}

.mr5 {
  margin-right: 50px;
}

.mr6 {
  margin-right: 60px;
}

.mr7 {
  margin-right: 70px;
}

.mr8 {
  margin-right: 80px;
}

.mr9 {
  margin-right: 90px;
}

.mr10 {
  margin-right: 100px;
}

.mr11 {
  margin-right: 110px;
}

.mr12 {
  margin-right: 120px;
}

.mr13 {
  margin-right: 130px;
}

.mr14 {
  margin-right: 140px;
}

.mr15 {
  margin-right: 150px;
}

.mr16 {
  margin-right: 160px;
}

.mr0 {
  margin-right: 0;
}

.mb1 {
  margin-bottom: 10px;
}

.mb2 {
  margin-bottom: 20px;
}

.mb3 {
  margin-bottom: 30px;
}

.mb4 {
  margin-bottom: 40px;
}

.mb5 {
  margin-bottom: 50px;
}

.mb6 {
  margin-bottom: 60px;
}

.mb7 {
  margin-bottom: 70px;
}

.mb8 {
  margin-bottom: 80px;
}

.mb9 {
  margin-bottom: 90px;
}

.mb10 {
  margin-bottom: 100px;
}

.mb11 {
  margin-bottom: 110px;
}

.mb12 {
  margin-bottom: 120px;
}

.mb13 {
  margin-bottom: 130px;
}

.mb14 {
  margin-bottom: 140px;
}

.mb15 {
  margin-bottom: 150px;
}

.mb16 {
  margin-bottom: 160px;
}

.mb0 {
  margin-bottom: 0;
}

.mt1 {
  margin-top: 10px;
}

.mt2 {
  margin-top: 20px;
}

.mt3 {
  margin-top: 30px;
}

.mt4 {
  margin-top: 40px;
}

.mt5 {
  margin-top: 50px;
}

.mt6 {
  margin-top: 60px;
}

.mt7 {
  margin-top: 70px;
}

.mt8 {
  margin-top: 80px;
}

.mt9 {
  margin-top: 90px;
}

.mt10 {
  margin-top: 100px;
}

.mt11 {
  margin-top: 110px;
}

.mt12 {
  margin-top: 120px;
}

.mt13 {
  margin-top: 130px;
}

.mt14 {
  margin-top: 140px;
}

.mt15 {
  margin-top: 150px;
}

.mt16 {
  margin-top: 160px;
}

.mt0 {
  margin-top: 0;
}

.mv1 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mv2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mv3 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mv4 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mv5 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mv6 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.mv7 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.mv8 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.mv9 {
  margin-top: 90px;
  margin-bottom: 90px;
}

.mv10 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.mv11 {
  margin-top: 110px;
  margin-bottom: 101px;
}

.mv12 {
  margin-top: 120px;
  margin-bottom: 120px;
}

.mv13 {
  margin-top: 130px;
  margin-bottom: 130px;
}

.mv14 {
  margin-top: 140px;
  margin-bottom: 140px;
}

.mv15 {
  margin-top: 150px;
  margin-bottom: 150px;
}

.mv16 {
  margin-top: 160px;
  margin-bottom: 160px;
}

.mv0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mvt0 {
  margin-top: 0;
}

.mvb0 {
  margin-bottom: 0;
}

.mh1 {
  margin-left: 10px;
  margin-right: 10px;
}

.mh2 {
  margin-left: 20px;
  margin-right: 20px;
}

.mh3 {
  margin-left: 30px;
  margin-right: 30px;
}

.mh4 {
  margin-left: 40px;
  margin-right: 40px;
}

.mh5 {
  margin-left: 50px;
  margin-right: 50px;
}

.mh6 {
  margin-left: 60px;
  margin-right: 60px;
}

.mh7 {
  margin-left: 70px;
  margin-right: 70px;
}

.mh8 {
  margin-left: 80px;
  margin-right: 80px;
}

.mh9 {
  margin-left: 90px;
  margin-right: 90px;
}

.mh10 {
  margin-left: 100px;
  margin-right: 100px;
}

.mh11 {
  margin-left: 110px;
  margin-right: 101px;
}

.mh12 {
  margin-left: 120px;
  margin-right: 120px;
}

.mh13 {
  margin-left: 130px;
  margin-right: 130px;
}

.mh14 {
  margin-left: 140px;
  margin-right: 140px;
}

.mh15 {
  margin-left: 150px;
  margin-right: 150px;
}

.mh16 {
  margin-left: 160px;
  margin-right: 160px;
}

.mh0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mhl0 {
  margin-left: 0px;
}

.mhr0 {
  margin-right: 0px;
}
/* Background
------------------------------------ */

.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

.bg-contain {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
}

.bg-center-top {
  background-position: center top;
}

.bg-center-center {
  background-position: center center;
}

.bg-center-bottom {
  background-position: center bottom;
}

.bg-left-top {
  background-position: left center;
}

.bg-left-center {
  background-position: left center;
}

.bg-right-top {
  background-position: right center;
}

.bg-right-center {
  background-position: right center;
}
/* Border
------------------------------------ */

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.border-top.color-1 {
  border-color: #f84432;
}

.border-top.color-2 {
  border-color: #1976d2;
}

.border-top.color-3 {
  border-color: #ffa018;
}

.border-top2 {
  border-top: 2px solid rgba(0, 0, 0, 0.2);
}

.border-top2.color-1 {
  border-color: #f84432;
}

.border-top2.color-2 {
  border-color: #1976d2;
}

.border-top2.color-3 {
  border-color: #ffa018;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.border-bottom.color-1 {
  border-color: #f84432;
}

.border-bottom.color-2 {
  border-color: #1976d2;
}

.border-bottom.color-3 {
  border-color: #ffa018;
}

.border-bottom2 {
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}

.border-bottom2.color-1 {
  border-color: $main-color;
}

.border-bottom2.color-2 {
  border-color: $main-color;
}

.border-bottom2.color-3 {
  border-color: $main-color;
}
/* Align
------------------------------------ */

.valign-top {
  vertical-align: top;
}

.valign-middle {
  vertical-align: middle;
}

.valign-bottom {
  vertical-align: bottom;
}

.visible-hidden {
  visibility: hidden;
}
/* Opacity
------------------------------------ */
@keyframes blink {

  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-webkit-keyframes blink {

  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-moz-keyframes blink {

  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
/* Music
====================================*/


#header {

}

#header.header-music .panel-header {
  background: transparent;
}

#header.header-music #burger_menu {
  background-color: #ff4f4f;
}


.fs-slide.ms-slide .fs-item {
  position: relative;
  padding-top: 264px;
  padding-bottom: 176px;
}

.fs-slide.ms-slide .fs-item::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(images/pattern-music.png);
  background-repeat: repeat;
  background-position: center center;
  opacity: 0.05;
}

.fs-slide.ms-slide .fs-entry-item .fs-title {
  position: relative;
  // font-family: 'SF UI Display';
  font-weight: 300;
  font-size: 20px;
  letter-spacing: 0px;
  text-transform: none;
  margin-bottom: 55px;
}

.fs-slide.ms-slide .fs-entry-item .fs-title::after {
  display: none;
}

.fs-slide.ms-slide .fs-entry-item .fs-title::before {
  position: relative;
  display: inline-block;
  width: 2px;
  height: 26px;
  background-color: #f70100;
  bottom: 0px;
  margin-right: 12px;
}

.fs-slide.ms-slide .fs-entry-item h3 {
  font-family: 'Montserrat';
  font-size: 54px;
  font-weight: bold;
  line-height: 56px;
  letter-spacing: 0px;
  text-transform: uppercase;
  margin-bottom: 60px;
}

.fs-slide.ms-slide .fs-entry-item h3 span {
  position: relative;
  color: #fff;
  z-index: 1;
  padding-left: 10px;
  padding-right: 10px;
}

.fs-slide.ms-slide .fs-entry-item h3 span::after {
  display: none;
}

.fs-slide.ms-slide .fs-entry-item h3 span::before {
  content: '';
  background-color: rgba(247, 1, 0, 0.7);
  position: absolute;
  top: 3px;
  bottom: 3px;
  left: 0px;
  right: 0px;
  z-index: -1;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.fs-slide.ms-slide .fs-entry-item h3 span:hover::before {
  background-color: rgba(247, 1, 0, 0.9);
}

.fs-slide.ms-slide .fs-entry-item a.read-more {
  font-family: 'Montserrat';
  font-size: 18px;
  font-weight: normal;
  text-decoration: none;
  border: 2px solid #f70100;
  color: #f70100;
  padding: 12px 40px;
  border-radius: 40px;
  overflow: hidden;
  position: relative;
  z-index: 10;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.fs-slide.ms-slide .fs-entry-item a.read-more::before {
  content: '';
  position: absolute;
  background-color: rgba(247, 1, 0, 0.7);
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: translateY(-100%);
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.fs-slide.ms-slide .fs-entry-item a.read-more:hover {
  color: #fff;
}

.fs-slide.ms-slide .fs-entry-item a.read-more:hover::before {
  visibility: visible;
  opacity: 1;
  transform: translateY(0%);
}


.boxoffice-style.ms-style {
  margin-bottom: 45px;
}

.boxoffice-style.ms-style .label {
  background-color: #f70100;
  width: 42px;
  height: 22px;
  line-height: 22px;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  padding: 0px;
  margin: 0px;
}

.boxoffice-style.ms-style h4 {
  margin-bottom: 5px;
}

.boxoffice-style.ms-style h5 {
  font-size: 13px;
  font-family: 'Montserrat';
  font-weight: normal;
  margin: 0px;
  opacity: 0.7;
}

.boxoffice-style.ms-style .ms-meta {
  position: relative;
  padding-right: 32px;
}

.boxoffice-style.ms-style .ms-meta a.ms-love {
  display: inline-block;
  width: 16px;
  position: absolute;
  top: -2px;
  right: 8px;
}

.boxoffice-style.ms-style .ms-meta a.ms-love.active svg path {
  fill: #f70100;
}

.boxoffice-style.ms-style.text-light .ms-meta h4 a,
.boxoffice-style.ms-style.text-light .ms-meta h5 a {
  color: #fff;
}

.boxoffice-style.ms-style.text-light a.ms-love svg path {
  fill: #fff;
}


.simple-tab-space.ms-style .tab-title a {
  text-transform: none;
  // font-family: 'SF UI Display';
  font-weight: 300;
  font-size: 20px;
  line-height: 1em;
  letter-spacing: 0px;
  position: relative;
  color: #000000;
  padding-left: 10px;
  opacity: 0.4;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.simple-tab-space.ms-style .tab-title a::before {
  content: '';
  width: 2px;
  height: 16px;
  background-color: #000000;
  position: absolute;
  left: 0px;
  bottom: 2px;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

.simple-tab-space.ms-style .tab-title a.active {
  opacity: 1;
}

.simple-tab-space.ms-style .tab-title a.active::before {
  height: 26px;
  background-color: #f70100;
}

.simple-tab-space.ms-style .tab-title a + a {
  border: 0px;
  margin-left: 40px;
  padding-left: 10px;
}

.simple-tab-space.ms-style.text-light .tab-title a {
  color: #fff;
}

.simple-tab-space.ms-style.text-light .tab-title a::before {
  background-color: #fff;
}

.simple-tab-space.ms-style.text-light .tab-title a.active::before {
  background-color: #f70100;
}

#music_player {
  height: 66px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #333;
  z-index: 1000;
  background-image: url(images/music/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  box-shadow: 3px 5.196px 36px 0px rgba(0, 0, 0, 0.8);
}

#music_player audio {
  opacity: 0;
}

#music_player .ms-table {
  width: 100%;




}

#music_player .ms-table > tr > td,
#music_player .ms-table > tbody > tr > td {
  vertical-align: middle;
  height: 66px;
  text-align: left;
}

#music_player .ms-table .ms-buttons {
  width: 180px;
  line-height: 66px;
}

#music_player .ms-table .ms-buttons a {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 34px;
  float: left;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

#music_player .ms-table .ms-buttons a + a {
  margin-left: 30px;
}

#music_player .ms-table .ms-buttons a svg {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

#music_player .ms-table .ms-buttons a svg path {
  fill: #fff;
}

#music_player .ms-table .ms-buttons .ms-play svg {
  width: 20px;
  height: 26px;
}

#music_player .ms-table .ms-buttons .ms-prev,
#music_player .ms-table .ms-buttons .ms-next {
  opacity: 0.8;
}

#music_player .ms-table .ms-buttons .ms-prev:hover,
#music_player .ms-table .ms-buttons .ms-next:hover {
  opacity: 1;
}

#music_player .ms-table .ms-buttons .ms-prev svg,
#music_player .ms-table .ms-buttons .ms-next svg {
  width: 20px;
  height: 14px;
}

#music_player .ms-table .ms-wrap {
  width: 480px;
}

#music_player .ms-table .ms-controls .ms-entry-controls {
  position: relative;
  line-height: 0px;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item {
  display: inline-block;
  line-height: 0px;


}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item + .ec-item {
  margin-left: 5px;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item > a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  position: relative;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item > a svg {
  width: 24px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item > a svg path {
  fill: rgba(255, 255, 255, 0.3);
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item > a.active svg path {
  fill: #e72971;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-volume {
  position: relative;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-volume .ec-volume-control {
  position: absolute;
  bottom: 25px;
  left: -6px;
  width: 38px;
  height: 146px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-volume .ec-volume-control::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 119px;
  background-color: #0c0c0c;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-volume .ec-volume-control .ec-vol-el {
  width: 2px;
  height: 94px;
  position: absolute;
  top: 13px;
  left: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  margin-left: -1px;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-volume .ec-volume-control .ec-vol-el .ui-slider-range {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background: #e72971;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-volume .ec-volume-control .ec-vol-el .ui-slider-handle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e72971;
  position: absolute;
  left: -4px;
  margin-bottom: -5px;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-volume .ec-volume-control .ec-vol-el .ui-slider-handle:focus {
  outline: none;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-volume:hover .ec-volume-control {
  visibility: visible;
  opacity: 1;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist {
  position: relative;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control {
  position: absolute;
  width: 324px;
  height: 453px;
  left: -240px;
  bottom: 25px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control::before {
  content: '';
  background-color: #090c14;
  position: absolute;
  bottom: 27px;
  top: 0px;
  left: 0px;
  right: 0px;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-head {
  display: block;
  position: relative;
  overflow: hidden;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 30px;
  padding-bottom: 20px;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-head h5 {
  font-family: 'Montserrat';
  font-size: 18px;
  font-weight: normal;
  color: #fff;
  letter-spacing: 0px;
  text-transform: uppercase;
  float: left;
  margin: 0px;
  line-height: 1em;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-head .ms-control-shuffle {
  display: inline-block;
  float: right;
  position: relative;
  top: 1px;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-head .ms-control-shuffle svg {
  width: 24px;
  height: 16px;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-head .ms-control-shuffle svg path {
  fill: rgba(255, 255, 255, 0.2);
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-head .ms-control-shuffle.active svg path {
  fill: #e72971;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-list-container {
  max-height: 348px;
  overflow: auto;
  position: relative;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-list-container::-webkit-scrollbar {
  display: none;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-list {
  width: 100%;
  position: relative;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-list tr {
  border-top: 1px solid rgba(255, 255, 255, 0.07);
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-list tr td {
  color: #fff;
  vertical-align: middle;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-list tr td a {
  color: #fff;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-list tr td.td-num {
  width: 86px;
  text-align: center;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-list tr td.td-title {
  padding-top: 10px;
  padding-bottom: 10px;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-list tr td.td-title > a {
  margin-right: 40px;
  font-size: 14px;
  line-height: 1.3em;
  display: inline-block;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-list tr td.td-title .pl-item-title {
  margin-right: 5px;
  font-family: 'Montserrat';
  font-weight: normal;
  letter-spacing: 0px;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-list tr td.td-title .pl-item-duration {
  opacity: 0.2;
  // font-family: 'SF UI Display';
  font-weight: 500;
  letter-spacing: 0px;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-list tr td.td-title .pl-item-artist {
  display: block;
  opacity: 0.2;
  // font-family: 'SF UI Display';
  font-weight: 500;
  letter-spacing: 0.3px;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-list tr td span.number {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 25px;
  height: 25px;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-list tr td span.number::before {
  content: attr(data-value);
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: normal;
  color: rgba(255, 255, 255, 0.2);
  line-height: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-list tr td span.number::after {
  content: '';
  font-family: Fontawesome;
  font-size: 18px;
  color: #e72971;
  line-height: 1em;
  position: absolute;
  top: 150%;
  left: 50%;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-list tr td span.number.playing::before {
  top: 0%;
  visibility: hidden;
  opacity: 0;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist .ec-playlist-control .pl-list tr td span.number.playing::after {
  top: 50%;
  visibility: visible;
  opacity: 1;
}

#music_player .ms-table .ms-controls .ms-entry-controls .ec-item.ec-playlist:hover .ec-playlist-control {
  opacity: 1;
  visibility: visible;
}

#music_player .ms-table .ms-nowplaying {
  width: 280px;
  position: relative;
}

#music_player .ms-table .ms-nowplaying .np-thumb {
  display: inline-block;
  width: 66px;
  height: 66px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}

#music_player .ms-table .ms-nowplaying .np-meta {
  position: absolute;
  top: 50%;
  left: 80px;
  right: 25px;
  line-height: 1.3em;
  margin-top: -2px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

#music_player .ms-table .ms-nowplaying .np-meta .np-title {
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-right: 5px;
}

#music_player .ms-table .ms-nowplaying .np-meta .np-artist {
  // font-family: 'SF UI Display';
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: rgba(255, 255, 255, 0.4);
}

#music_player .ms-table .ms-nowplaying .ms-add-fav {
  width: 15px;
  height: 14px;
  line-height: 14px;
  position: absolute;
  top: 50%;
  right: 0px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

#music_player .ms-table .ms-nowplaying .ms-add-fav svg {
  width: 15px;
  height: 14px;
}

#music_player .ms-table .ms-nowplaying .ms-add-fav svg path {
  fill: #e72971;
}


.msplayer-skin.mejs-container {
  display: inline-block;
  background: none;



}

.msplayer-skin.mejs-container .mejs-offscreen {
  clip: auto;
  clip-path: none;
}

.msplayer-skin.mejs-container .mejs-controls {
  background: none;
}

.msplayer-skin.mejs-container .mejs-controls .mejs-time {
  font-size: 14px;
  // font-family: 'SF UI Display';
  font-weight: 500;
  color: rgba(255, 255, 255, 0.3);
  width: 44px;
}

.msplayer-skin.mejs-container .mejs-time-total.mejs-time-slider:focus {
  outline: none;
}

.msplayer-skin.mejs-container .mejs-controls .mejs-time-rail .mejs-time-total {
  background: none;
}

.msplayer-skin.mejs-container .mejs-controls .mejs-time-rail .mejs-time-total::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0px;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  background-color: rgba(255, 255, 255, 0.1);
}

.msplayer-skin.mejs-container .mejs-controls .mejs-time-rail .mejs-time-current {
  background: none;
}

.msplayer-skin.mejs-container .mejs-controls .mejs-time-rail .mejs-time-current::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0px;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  background-color: #e72971;
}

.msplayer-skin.mejs-container .mejs-controls .mejs-time-rail .mejs-time-current::after {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e72971;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: 0px;
  margin-right: -5px;
}

.msplayer-skin.mejs-container .mejs-controls .mejs-time-rail .mejs-time-loaded {
  background: none;
}

.msplayer-skin.mejs-container .mejs-controls .mejs-time-rail .mejs-time-loaded::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0px;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  background-color: rgba(255, 255, 255, 0.1);
}

.msplayer-skin.mejs-container .mejs-controls .mejs-time-rail .mejs-time-float {
  display: none !important;
}
/* Responsive
====================================*/


@media screen and (min-width: 992px) {

  .none-masonry .col-md-4:nth-child(3n+1) {
    clear: left;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  //.container {
  //  width: 100%;
  //}

  .sm-clear-left {
    clear: left;
  }

  .col-md-15.col-sm-4 {
    margin-bottom: 30px;
  }

  .col-md-15.col-sm-4:nth-child(3n+1) {
    clear: left;
  }

  .none-masonry .col-sm-6:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 768px) {

  .with-sidebar {
    padding-right: 35px;
  }

  .with-sidebar.pull-right {
    padding-right: 15px;
    padding-left: 35px;
  }
}

@media screen and (max-width: 767px) {

  .col-sm-9.pull-right {
    float: none !important;
  }

  .col-md-15.col-xs-6 {
    margin-bottom: 30px;
  }

  .col-md-15.col-xs-6:nth-child(2n+1) {
    clear: left;
  }
}


@media screen and (min-width: 992px) and (max-width: 1199px) {

  .bg-visible-lg {
    background-image: none !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .bg-visible-lg {
    background-image: none !important;
  }

  .bg-visible-md {
    background-image: none !important;
  }
}

@media screen and (max-width: 767px) {

  .bg-visible-lg {
    background-image: none !important;
  }

  .bg-visible-md {
    background-image: none !important;
  }

  .bg-visible-sm {
    background-image: none !important;
  }
}
/* Internet Explorer
====================================*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

}
