$main-color: #52AA7F;
$main-color-2: #AAD076;
$title-font: 'Montserrat', sans-serif;
$image-border: 1px solid #ccc;
$black-color: #333333;
$tiszta-szex-color: #293078;
$tiszta-szex-color-light: lighten($tiszta-szex-color, 20%);
$brand-primary: $main-color;
$body-bg: #fbfbfb;
$text-color: #4d4d4d;
//$btn-primary-bg: $main-color;