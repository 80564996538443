// Theme stuff
main-footer {
  #footer-inner {
    margin-top: 40px;
  }

  #footer-banner {
    margin-top: 40px;
    width: 300px;
    height: 350px;
    max-width: 26vw;

    a {
      border: none;
    }

    @media screen and (max-width: 991px) {
      max-width: 24vw;
    }

    @media screen and (max-width: 767px) {
      max-width: 70vw;
      margin: 0 auto;
    }
  }

  .widget ul li {
    line-height: 1.4em;
    margin: 15px 0;
  }

  .widget .social-links a {
    color: #fff;
    font-size: 2.8rem;
    vertical-align: middle;
  }

  .widget .social-links a:hover {
    color: $main-color;
  }

  .subscribe label {
    display: inline-block;
  }

  .subscribe-form {
    display: inline-block;
    width: 300px;
    max-width: 100%;
  }

  .subscribe-form button {
    display: inline-block;
    background-color: $main-color;
    color: #fff;
  }

  .tt-el-info h4 {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.85);
  }

  .tt-el-info p {
    font-size: 11px;
    color: rgba(255, 255, 255, 0.85);
  }

  .footer-entertainment.light .tt-el-info h4,
  .footer-entertainment.light .tt-el-info p {
    color: #000;
  }

  @media screen and (max-width: 767px) {
    .text-right {
      text-align: left;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 40px 0;
  }

  a {
    text-decoration: none;
  }

  .border-line,
  .border-line-1 {
    border-color: #414141;
  }

  .widget-title {
    color: #fff;
    margin: 0;
    margin-bottom: 30px;
  }

  .search_form {
    background: #2a2a2a;
  }

  .widget.widget_contact {
    background: url(images/map.png) no-repeat center right;
    background-size: contain;
  }

  .widget.widget_contact a,
  .widget.widget_contact strong {
    color: #9c9c9c;
  }

  .widget.categories ul li {
    border-bottom: 1px solid rgba(148, 148, 148, 0.1);
  }

  .meta span {
    color: #949494;
  }

  .recent-posts li {
    border-color: rgba(255, 255, 255, 0.1);
  }

  .widget-tags a,
  .ads-placeholder {
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
  }
}

main-footer {
  display: block;
  visibility: hidden;
  background-color: #1f1f1f;
  color: #949494;
  font-weight: 500;

  &.is-visible {
    visibility: visible;
  }

  .footer-inner {
    padding: 100px 0;
  }

  .footer-logo-container {
    &:before {
      content: '';
      display: block;
      height: 2px;
      background-color: #333;
      width: 60px;
      margin-bottom: 20px;
    }

    &:after {
      content: '';
      display: block;
      height: 2px;
      background-color: #333;
      width: 60px;
      margin-top: 20px;
    }
  }

  .footer-logo-icon,
  .footer-logo-text {
    linearGradient {
      stop[offset="0"] { stop-color: #FFFFFF !important; }
      stop[offset="1"] { stop-color: #FFFFFF !important; }
    }
  }

  .footer-logo-icon {
    height: 26px;
    margin-right: 8px;
  }

  .footer-logo-text {
    height: 30px;
  }

  ul.tag-list {
    li {
      margin: 0;
      line-height: 1;

      a {
        color: white;

        &:hover {
          color: white;
        }
      }
    }
  }

  .follow-us {
    vertical-align: middle;
    color: white;
    font-family: $title-font;
    font-size: 1.8rem;
    margin-right: 30px;
  }

  .have-a-question {
    font-size: 1.2rem;
    color: rgba(white, .85);
  }
}

#paypal-holder {
  display: none;
}