textarea, input { outline: none; }

.user-dialog-wrapper,
profile-page {
  .form-control,
  .btn {
    border-radius: 2px;
  }

  input.form-control,
  select.form-control {
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
    border-color: white;
    transition: .2s border-color, .2s background-color;
    font-size: 1.7rem;
    color: white;
    outline: none;

    &:focus {
      box-shadow: none;
      border-color: transparent;
      background-color: rgba(white, .2);
    }

    @include placeholder-style {
      color: white;
    }
  }
}

profile-page {
  h2 {
    text-transform: uppercase;
    font-size: 2.6rem;
  }

  input.form-control,
  select.form-control {
    border-color: $black-color;
    color: $black-color;
    outline: none;

    &:focus {
      box-shadow: none;
      border-color: transparent;
      background-color: rgba(black, .05);
    }
  }

  textarea.form-control {
    background: transparent;
    border: 1px solid $black-color;
    outline: none;

    &:focus {
      box-shadow: none;
      border-color: transparent;
      background-color: rgba(black, .05);
    }
  }

  .btn-primary {
    background-color: white;
    border: 1px solid $black-color;
    color: $black-color;
    margin-top: 45px;

    &:hover {
      background-color: $black-color;
      color: white;
    }
  }
}