article-page {
  display: block;
  margin-bottom: 85px;

  .article-header {
    height: 318px;
    margin-bottom: 50px;
    margin-top: -78px;
  }

  .col:nth-child(1) {
    border-left: 1px solid rgba(#999, .37);
    border-right: 1px solid rgba(#999, .37);
  }

  .entry-details {
    color: black;
    text-align: right;
    font-family: $title-font;
    line-height: 1;
    padding-right: 25px;

    hr {
      display: block;
      border: none;
      width: 80px;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.1);
      margin: 10px 5px 10px auto;
    }

    .entry-date {
      font-size: 4.2rem;
      font-weight: bold;
      margin-right: -3%;
    }

    .entry-year {
      font-size: 1.8rem;
    }

    .author-image-wrapper {
      max-width: 124px;
      display: inline-block;
      width: 100%;
      margin-bottom: 18px;

      .div-img {
        display: inline-block;
        width: 100%;
        margin-right: -10%;
      }
    }

    .author-label-text {
      font-size: 1.5rem;
      margin-bottom: 12px;
    }

    .author-name {
      text-transform: uppercase;
      font-size: 2.2rem;
      margin-bottom: 10px;
    }

    .author-title {
      @extend .category-color;

      font-size: 1.2rem;
      line-height: 1.3;
    }

    .entry-comment-count {
      font-size: 2rem;
      padding: 5px 0;
    }

    .entry-social {
      li {
        margin-bottom: 15px;
      }
    }
  }

  .author-desktop {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .mobile-author {
    font-family: "Montserrat", sans-serif;

    .author-holder {
      display: flex;
      flex-wrap: wrap;
      margin: 5% 0;
      align-content: space-between;

      .entry-author {
        flex: 0 0 120px
      }

      .info-holder {
        flex: 1;
        padding-top: 10px;
        color: black;

        .author-label {
          font-size: 1.5rem;
          margin-bottom: 12px;
        }

        .author-title {
          font-size: 1.2rem;
          line-height: 1.3;
          @extend .category-color;
        }

        .author-name {
          text-transform: uppercase;
          font-size: 2.2rem;
        }
      }

      .div-img {
        width: 100px;
      }
    }


    @media screen and (min-width: 767px) {
      display: none;
    }
  }
  .entry-content {
    .post-meta {
      hr {
        @extend .category-bg-color;

        display: inline-block;
        border: none;
        width: 56px;
        height: 4px;
        margin: 0;
      }

      .post-category {
        font-family: $title-font;
        text-transform: uppercase;
        font-size: 1.8rem;
      }

      .tag-list {
        font-size: 1.4rem;
      }
    }

    h2 {
      font-size: 3.6rem;
      line-height: 1.1;
      text-transform: uppercase;
      font-weight: bold;
      margin: 35px 0 25px;

      .circle-18-wrapper {
        display: block;
        margin-bottom: 15px;
      }
    }

    .post-content {
      word-break: break-word;
      overflow-wrap: break-word;
      line-height: 1.6;
      img {
        max-width: 100%;
        height: auto;
      }
    }

    .article-image {
      border: $image-border;
      @include fluid-aspect(2 1);
    }

    .article-image-caption {
      font-size: 1.3rem;
      color: #999;
      margin-bottom: 15px;
    }
  }

  aside {
    margin-bottom: 25px;

    .box-title {
      font-family: $title-font;
      font-size: 2rem;
    }
  }

  .recommended-articles {
    .post {
      margin-bottom: 15px;

      .post-image {
        margin-bottom: 10px;
      }

      .article-title {
        font-family: $title-font;
        text-transform: uppercase;
        line-height: 1.2;
        font-size: 1.3rem;
        margin-bottom: 5px;
      }

      .article-date {
        font-size: 1.2rem;
      }
    }
  }

  .entry-comment-count {
    span {
      margin-right: 5px;
    }
  }

  .comment-form {
    .btn {
      color: white;
    }
  }

  .article-comments {
    margin-top: 30px;

    article-comment {
      margin-bottom: 35px;
      display: block;
    }
  }

  .cant-comment {
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 1.6rem;

    a {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  addthis-toolbox {
    display: block;
    margin-bottom: 10px;
  }

  banner-block[location="above_comments"] {
    margin: 15px 0;
  }

  .social-after-content {
    height: 24px; // Facebook like explodes in height for a sec while loading (for whatever reason)

    > li {
      vertical-align: middle;

      facebook-recommend,
      twitter-tweet,
      google-plusone {
        display: block !important; // Needs important, bc google sets inline display: inline-block;

        iframe {
          display: block;
        }
      }
    }
  }
}
