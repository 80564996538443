premium-packages-page,
premium-package-page,
purchased-packages-page {
  .relative-wrapper {
    position: relative;
  }

  .page-header {
    margin: 0;
    padding: 0;
    border-bottom: .9rem solid transparent;
    background-size: cover;
    background-position: center center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;

    .relative-wrapper {
      height: 42.1rem;
    }

    .background-text {
      @include transat-black;

      position: absolute;
      left: 0;
      top: 6.5rem;
      width: 100%;
      color: rgba(24, 24, 26, 0.1);
      text-align: center;
      font-size: 10rem;
      line-height: 1;
      text-transform: uppercase;
    }
  }

  .page-body {
    background: {
      image: url(../../images/premium/premium_csomag_body_bg.png);
      repeat: no-repeat;
      position: center 43rem;
      size: 100% auto;
    }

    padding-bottom: 16rem;

    h1 {
      @include under-rectangle(white);

      color: white;
      margin: 0;
      padding: 4.5rem 0 5rem;
      text-align: center;
      text-transform: uppercase;
    }

    .main-title,
    .main-subtitle {
      line-height: 1;
      display: block;
    }

    .main-title {
      @include transat-black;

      font-size: 6.2rem;
      letter-spacing: 0.2rem;
      line-height: .85;

      .bigger-letters {
        font-size: 1.6em;
      }
    }

    .top-video {
      border-style: solid;
      border-color: #111111;
      border-width: 1.6rem 2.2rem 2.6rem;
      border-radius: .9rem;
      margin: 0 auto;
      max-width: 70rem;
    }

    .grey-box {
      border: 1px solid rgba(176, 176, 176, 0.7);
      border-radius: .8rem;
      background-color: rgba(235, 235, 235, 0.7);
    }

    .package-thumbnail {
      border-radius: .8rem;
      display: inline-block;
      background-color: #111111;
      padding: 1.2rem;

      img {
        max-width: 100%;
        border-radius: .8rem;
      }
    }

    .package-files-title {
      @include montserrat($font-weight-bold);
      text-transform: uppercase;
      font-size: 2.8rem;
      line-height: 1;
      letter-spacing: 0.1rem;
      margin-bottom: 1.5rem;
    }

    .package-files {
      @include list-unstyled;

      li {
        font-weight: $font-weight-semi-bold;
        line-height: 1.8;

        .item-number {
          display: inline-block;
          width: 1.7rem;

          margin-right: .2em;
        }
      }
    }
  }
}