home-page {
  display: block;
}

intro-slider {
  position: relative;
  margin-top: -78px;
  overflow: hidden;
  display: block;

  .slider img {
    display: inline-block; // Override slick default
  }

  .background-slider-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .left-fade,
    .right-fade {
      position: absolute;
      top: 0;
      height: 100%;
      opacity: .4;
    }

    .left-fade {
      left: 0;
    }

    .right-fade {
      right: 0;
    }
  }

  .background-slider {
    width: 100%;

    &,
    .slick-list,
    .slick-track,
    .slick-slide {
      height: 100%;
    }

    .slide {
      background-position: center;
      //background-size: contain;
      background-size: cover;
      background-repeat: no-repeat;

      position: relative;

      .background-tint {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(black,0.2);
      }
    }
  }

  .sliders-container {
    height: 100%;
    padding: 78px 0 0 0;

    @include dib-mid;

    > .row {
      margin: 0;
      width: 100%;

      @media (min-width: $screen-sm-min) {
        @include dib-mid('> .col');

        height: 100%;
        white-space: nowrap;

        .col {
          float: none;
        }
      }
    }
  }

  .current-slider {
    .slick-list {
      overflow: visible;
    }

    .slide {
      &,
      a {
        color: white;
        transition: .2s color;
      }

      a:hover {
        color: saturate($main-color-2, 20%);
      }

      .slide-top {
        font-size: 2.1rem;

        .article-category,
        .bullet,
        .date {
          vertical-align: middle;
        }

        .article-category {
          text-transform: uppercase;
        }

        .bullet {
          height: 15px;
          display: inline-block;
          width: 1px;
          background-color: white;
          margin: 0 10px;
        }
      }

      hr.short-hr {
        background-color: black;
        width: 80px;
      }

      h2 {
        text-transform: uppercase;
        font-size: 3.5rem;
        line-height: 1.15;
        margin-top: 0;

        @media (min-width: $screen-sm-min) {
          height: 2 * 1.1 * 3.5rem;
          overflow: hidden;
        }

        @media (max-width: $screen-xs-max) {
          font-size: 3.0rem;
        }
      }

      .slide-summary {
        font-size: 1.4rem;
        line-height: 1.4;

        @media (min-width: $screen-sm-min) {
          height: 3 * 1.1 * 1.8rem;
          overflow: hidden;
        }
      }

      .slide-author {
        margin-top: 20px;
        font-size: 2.1rem;

        img {
          margin-right: 10px;
        }
      }
    }
  }

  .intro-slider-inner {
    @media (max-width: $screen-sm) {
      height: 500px !important;
    }
  }

  .nav-slider-col {
    @media (min-width: $screen-sm-min) {
      padding-top: 10px;
      padding-bottom: 10px;
      border-right: 3px solid $main-color;
    }
  }

  .nav-slider {
    .slide {
      //height: 65px;
      text-align: right;
      line-height: 1;
      padding: 5px 0;
      cursor: pointer;

      &,
      a {
        color: white;
        transition: .2s color;
      }

      &.current-slide {
        &,
        a {
          color: #ccc;
        }
      }

      &:hover {
        &,
        a {
          color: #ccc;
        }
      }

      .slide-top {
        font-size: 1.2rem;
        margin-bottom: 10px;
      }

      h2 {
        margin: 0;
        font-size: 1.7rem;
        line-height: 1.15;
        text-transform: uppercase;

        height: 2 * 1.1 * 1.7rem;
        overflow: hidden;
      }

      hr.short-hr {
        background-color: black;
        height: 1px;
        margin: 10px 0 0 auto;
      }
    }
  }
}

.sub-intro-slider {
  padding: 20px 28px;
  position: relative;

  .slider-nav {
    color: $main-color;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 5.8rem;

    &.slider-nav-prev {
      left: 0;
    }

    &.slider-nav-next {
      right: 0;
    }

    .fa {
      cursor: pointer;
    }

    @media (max-width: $screen-tn-max) {
      display: none;
    }
  }

  .slide {
    padding: 0 7px;
    color: black;

    .image {
      @include aspect-ratio(5,3);

      width: 100%;
      background-position: center;
      background-size: cover;
      position: relative;
      display: block;

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: transparent;
        transition: .2s background-color;
      }
    }

    .slide-top {
      margin-top: 10px;
      font-size: 1.2rem;
      line-height: 1;

      .date {
        color: rgba(black, .7);
      }
    }

    .title {
      margin-top: 10px;
      font-size: 1.8rem;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 1.2;
    }

    &:hover {
      .image {
        &:after {
          background-color: rgba(black, .3);
        }
      }
    }
  }
}

.top-slider-container {
  overflow: hidden; // Counteract removing overflow: hidden from slider
}

.top-slider {
  margin-top: 50px;

  .slick-list {
    overflow: visible;
  }

  .slide {
    background-color: white;
    position: relative;

    .left-image {
      margin-top: -60px;
      max-width: 60%;
    }

    .slide-content {
      position: absolute;
      right: 10%;
      top: 50%;
      transform: translateY(-50%);
      width: 36%;

      blockquote {
        margin: 0;
        border: none;

        &:before {
          left: -80px;
          font-size: 16rem;
          top: auto;
          bottom: -20px;
        }

        .slide-title {
          display: inline-block;
          padding-bottom: 10px;
          margin-bottom: 30px;
          border-bottom: 2px solid $main-color;
        }

        .slide-text {
          text-transform: uppercase;
          font-size: 2.6rem;
        }
      }
    }

    .slide-inner-1 {
      .div-img {
        @include fluid-aspect(1140 487);
      }
    }

    @media (max-width: $screen-sm-max) {
      .slide-content {
        blockquote {
          .slide-title {
            font-size: 1.6rem;
            line-height: 1.4;
          }

          .slide-text {
            font-size: 2.4rem;
            line-height: 1.2;
          }
        }
      }
    }

    @media (max-width: $screen-xs-max) {
      .left-image {
        max-width: 100%;
      }

      .slide-content {
        position: static;
        transform: none;
        width: auto;
        padding: 20px;

        blockquote {
          &:before {
            left: -10px;
          }
        }
      }
    }

    @media (max-width: $screen-tn-max) {
      .slide-content {
        blockquote {
          .slide-title {
            font-size: 1.4rem;
          }

          .slide-text {
            font-size: 2rem;
          }
        }
      }
    }
  }
}

.top-slider-wrapper {
  position: relative;

  .slider-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #000;
    color: white;
    padding: 10px 15px;
    line-height: 1;
    cursor: pointer;

    &.slider-nav-prev {
      left: 0;
      padding-right: 10px;

      .fa {
        margin-right: 5px;
      }
    }

    &.slider-nav-next {
      right: 0;
      padding-left: 10px;

      .fa {
        margin-left: 5px;
      }
    }

    .fa {
      font-size: 1.8rem;
      vertical-align: middle;
    }

    .text {
      display: inline-block;
      opacity: 0;
      vertical-align: middle;
      width: 0;
      transition: .15s opacity, .15s width;
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif;
      font-size: 1.4rem;
    }

    &:hover {
      .text {
        opacity: 1;
        width: 50px;
      }
    }
  }
}

promo-cells {
  .cell {
    margin-top: 25px;
  }
}

latest-articles,
.latest-articles {
  .large-post {
    .post-image {
      margin-bottom: 10px;
    }

    h4 {
      text-transform: none;
    }

    .meta {
      line-height: 35px;
      margin-bottom: 10px;
      font-size: 1.4rem;

      .author-link {
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;

        .author-img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
          margin-bottom: 0;
          float: none;
          display: inline-block;
          vertical-align: middle;
        }
      }

      .date {
        vertical-align: middle;
      }
    }
  }

  .latest-articles-sidebar {
    .post {
      h4 {
        margin: 14px 0 10px;
        line-height: 1.2;
        font-size: 2.2rem;

        height: 2 * 1.2 * 2.2rem;
        overflow: hidden;
      }

      .meta {
        font-size: 1.4rem;
        line-height: 1;
        margin-bottom: 10px;

        img {
          margin-right: 8px;
          border-radius: 50%;
        }

        .author-link {
          margin-right: 8px;
        }
      }

      .summary,
      .summary p {
        font-size: 1.4rem;
        line-height: 1.4;
      }
    }
  }

  .bottom-articles {
    .post {
      .post-image {
        margin-bottom: 13px;
      }

      .meta {
        line-height: 1;
      }

      .summary {
        &,
        p {
          font-size: 1.4rem;
          line-height: 1.4;
        }
      }
    }
  }

  .border-line {
    border-width: 1px;
  }
}

most-viewed-articles {
  .post.cart-style {
    padding: 20px;

    h4 {
      margin: 10px 0 14px;
      font-size: 1.8rem;
      line-height: 1.1;
      height: 2 * 1.1 * 1.8rem;
      overflow: hidden;
    }

    .label {
      display: block;
      margin-right: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;

      &:before {
        top: 0;
      }
    }

    .meta .author,
    .meta .date {
      font-size: 1.2rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .article-summary {
      text-overflow: ellipsis;
      line-height: 1.2;
      font-size: 1.3rem;
      height: 4 * 1.2 * 1.3rem;
      overflow: hidden;
    }
  }
}

least-viewed-articles {
  display: block;

  .post.large-post {
    h4 {
      line-height: 1.2;
      font-size: 2.0rem;
    }

    .post-summary,
    .post-summary p {
      font-size: 1.4rem;
      line-height: 1.8;
    }
  }

  .post.small-post {
    h4 {
      line-height: 1.05;
      font-size: 1.8rem;
      margin-top: 10px;
      margin-bottom: 10px;

      height: 2 * 1 * 1.8rem;
      overflow: hidden;
    }

    .meta {
      line-height: 1;
      font-size: 1.2rem;
    }

    .border-bottom2 {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }
}

featured-authors {
  .post {
    .author-name {
      margin-top: 10px;
    }

    blockquote.introduction {
      font-size: 1.8rem;
      line-height: 1.2;
      margin-bottom: 30px;
      text-transform: none;

      &:before {
        line-height: 0.5;
        top: 0;
        left: -40px;
      }

      .crop-div {
        height: 4 * 1.2 * 1.8rem;
        overflow: hidden;
      }
    }

    .category-more {
      padding-top: 0;
    }
  }
}