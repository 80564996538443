premium-package-page {
  .page-header {
    border-color: #111111;

    .background-text {
      display: none;
    }
  }

  .page-body {
    h1 {
      line-height: 1;
    }

    .main-title {
      margin-bottom: 1.1rem;
      font-size: 5.6rem;
    }

    .package-title {
      @include montserrat($font-weight-bold);

      font-size: 8.5rem;
      letter-spacing: 0.3rem;
    }

    .package-subtitle {
      @include montserrat($font-weight-normal);

      color: white;
      text-align: center;
      font-size: 3.2rem;
      letter-spacing: 0.1rem;
      line-height: 1;
      margin-bottom: 2.8rem;
    }

    .top-video {
      margin-bottom: 4rem;
    }

    .package-intro {
      margin-bottom: 4.8rem;

      .package-intro-contents {
        text-align: center;
        font-size: 2.5rem;
        font-weight: $font-weight-bold;
      }

      .under-rectangle {
        @include under-rectangle-style(transparent);
      }
    }

    .package-files-title {
      font-size: 3.8rem;
      margin-bottom: 2.2rem;
    }

    .package-files-container {
      @include clearfix;

      padding: 4.3rem 2.5rem;
    }

    .thank-you-row {
      display: table;
      width: 100%;
      margin-bottom: 2rem;

      > div {
        display: table-cell;

        &:nth-child(1) {
          width: 16%;
        }

        &:nth-child(2) {
          padding-left: 2rem;
          font-size: 2.6rem;
        }
      }
    }

    .package-files {
      li {
        font-size: 2.1rem;
        line-height: 1.6;

        .item-number {
          width: 3.8rem;
        }
      }
    }

    .purchased-package-files {
      li {
        display: table;
        width: 100%;
        background-color: #fefefe;
        border-radius: .6rem;
        border: 1px solid #d59dc9;
        padding: .6rem .5rem;
        margin-bottom: 1rem;
        font-size: 1.9rem;

        > div {
          display: table-cell;
          vertical-align: middle;

          &:nth-child(1) {
            width: 1px;
            padding-right: 1rem;
            vertical-align: top;
            font-size: 2.5rem;
          }

          &:nth-child(3) {
            text-align: right;
            white-space: nowrap;
          }
        }

        .file-button {
          @include montserrat($font-weight-bold);

          $file-button-bg-color: #9e1581;

          color: #fdfbfb;
          background-color: $file-button-bg-color;
          text-transform: uppercase;
          margin-left: .5rem;
          border-radius: .6rem;
          padding: .7rem .9rem;
          transition: background-color .25s;
          font-size: 1.8rem;
          line-height: 1;
          display: inline-block;
          min-width: 12.7rem;
          text-align: center;

          &:hover {
            background-color: lighten($file-button-bg-color, 10%);
          }
        }
      }
    }

    .right-col-contents {
      margin-right: -(5.9rem + 2.5rem + 1.5rem); // stick out width + box padding + column padding
      text-align: right;
    }

    .package-thumbnail {
      background-color: white;
      margin-bottom: 1.1rem;
    }

    .package-price {
      display: inline-block;
      border-radius: .9rem;
      padding: 1.7rem 2.2rem;
      color: white;
      text-transform: uppercase;
      text-align: center;
      line-height: 1;

      .line {
        display: block;
      }

      .line:nth-child(1) {
        @include montserrat($font-weight-bold);

        font-size: 2.3rem;
        letter-spacing: 0.7rem;
      }

      .price-value {
        @include montserrat($font-weight-bold);

        font-size: 6rem;
        letter-spacing: 0.2rem;
      }

      .price-currency {
        @include montserrat($font-weight-thin);

        font-size: 5.9rem;
        letter-spacing: 0.3rem;
      }
    }

    .buy-button-row {
      @include clearfix;

      margin-top: -2.4rem;
      display: inline-block;
      width: 100%;
      position: relative;
      z-index: 1;
      padding: 0 2.5rem;

      .paypal-stuff {
        display: inline-block;
        vertical-align: middle;
        float: right;
        //margin-top: 2.4rem;
      }
    }

    .buy-button {
      display: inline-block;
      padding: .7rem;
      background-color: #141313;
      border-radius: .6rem;
      line-height: 1;
      vertical-align: middle;

      .buy-button-content {
        @include montserrat($font-weight-bold);

        display: inline-block;
        position: relative;
        padding: 2.4rem 3.3rem;
        text-transform: uppercase;
        border-radius: .6rem;
        color: white;
        //font-size: 4.2rem;
        font-size: 3.4rem;
        letter-spacing: 0.1rem;

        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background-color: white;
          opacity: 0;
          transition: opacity .25s;
        }

        &:hover {
          &:after {
            opacity: .2;
          }
        }
      }
    }

    h2 {
      @include under-rectangle(#111111);

      text-transform: uppercase;
      text-align: center;
      margin-top: 6rem;
      margin-bottom: 0;
      font-size: 4.8rem;
      font-weight: $font-weight-semi-bold;
    }

    .package-info-description {
      margin-top: 3.3rem;
      font-size: 1.6rem;
    }

    .big-buy-button-row {
      margin-top: 5rem;
      text-align: center;

      .buy-button {
        padding: 1.2rem;

        .buy-button-content {
          padding: 2.5rem 5.5rem;
          font-size: 6.8rem;
        }
      }
    }

    .paypal-information {
      @include montserrat($font-weight-normal);

      margin: 5rem 0;
      text-align: center;
      text-transform: uppercase;
      font-size: 2rem;

      .paypal-information-top {
        color: red;
      }

      .paypal-information-button {
        display: block;
        color: white;
        margin: 2rem 0;

        padding: 1.5rem 1rem;
        border-radius: .6rem;
        line-height: 1;
        transition: opacity .25s;

        &:hover {
          opacity: .8;
        }
      }
    }

    .who-am-i-block {
      margin-top: 4.2rem;
      padding-top: 3.3rem;
      padding-right: 4.6rem;
      padding-bottom: 1.7rem;

      .who-am-i-description {
        @include clearfix;

        .left-col-content {
          margin-left: -12.8rem;
        }

        .right-col-content {
          text-align: left;
          font-weight: $font-weight-semi-bold;
          font-size: 1.9rem;
          line-height: 1.9;
        }
      }
    }
  }

  @media (max-width: $screen-md-max) {
    .page-header {
      .background-text {
        font-size: 9rem;
      }
    }

    .page-body {
      .main-title {
        font-size: 5.0rem;
      }

      .package-title {
        font-size: 7.6rem;
      }

      .package-files {
        li {
          font-size: 2.1rem;
        }
      }

      .package-intro {
        .package-intro-contents {
          font-size: 2.2rem;
        }
      }

      .paypal-stuff {
        width: 28rem;
      }

      .buy-button {
        .buy-button-content {
          padding-left: 2.2rem;
          padding-right: 2.2rem;
          font-size: 3rem;
        }
      }

      h2 {
        font-size: 4.3rem;
      }

      .big-buy-button-row {
        .buy-button {
          .buy-button-content {
            padding-left: 4.5rem;
            padding-right: 4.5rem;
            font-size: 5.4rem;
          }
        }
      }

      .who-am-i-block {
        .who-am-i-description {
          .right-col-content {
            font-size: 1.7rem;
          }
        }
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    .page-header {
      .background-text {
        font-size: 7rem;
      }
    }

    .page-body {
      .main-title {
        font-size: 4.5rem;
      }

      .package-title {
        font-size: 6.8rem;
      }

      .package-subtitle {
        font-size: 2.9rem;
      }

      .package-intro {
        .package-intro-contents {
          font-size: 2rem;
        }
      }

      .package-files {
        li {
          font-size: 1.9rem;
        }
      }

      .package-price {
        .line:nth-child(1) {
          font-size: 1.8rem;
          letter-spacing: 0.5rem;
        }

        .price-value {
          font-size: 4.3rem;
        }

        .price-currency {
          font-size: 4.2rem;
        }
      }

      .buy-button-row {
        .paypal-stuff {
          float: none;
          margin-left: 12rem;
          margin-top: 2rem;
        }
      }

      .big-buy-button-row {
        .buy-button {
          .buy-button-content {
            padding-left: 3.5rem;
            padding-right: 3.5rem;
            font-size: 4.1rem;
          }
        }
      }

      h2 {
        font-size: 3.4rem;
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    .page-header {
      .relative-wrapper {
        height: 30rem;
      }

      .background-text {
        display: none;
      }
    }

    .page-body {
      background-position-y: 26rem;

      .package-title {
        font-size: 3rem;
      }

      .package-subtitle {
        font-size: 1.8rem;
      }

      .package-intro {
        .package-intro-contents {
          font-size: 1.6rem;
        }
      }

      .package-files {
        li {
          font-size: 1.4rem;
        }
      }

      .right-col-contents {
        margin-right: 0;
        text-align: center;
      }

      .package-thumbnail-wrapper {
        display: block;
      }

      .thank-you-row {
        display: block;

        > div {
          display: block;
          text-align: center;

          &:nth-child(1) {
            width: 100%;
          }

          &:nth-child(2) {
            padding-left: 0;
            font-size: 2rem;
          }
        }
      }

      .package-files-title {
        text-align: center;
      }

      .purchased-package-files {
        li {
          display: block;

          > div {
            display: block;
            text-align: center !important;
            width: auto !important;
            white-space: normal !important;

            + div {
              margin-top: 1rem;
            }
          }
        }
      }

      .buy-button-row {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        text-align: center;

        .paypal-stuff {
          margin-left: 0;

          img {
            max-width: 100%;
          }
        }
      }

      .buy-button {
        .buy-button-content {
          padding: 1rem 1.5rem;
          font-size: 2.3rem;
          text-align: center;
          line-height: 1.3;
        }
      }

      .big-buy-button-row {
        .buy-button {
          .buy-button-content {
            padding: 1rem 1.5rem;
            font-size: 2.3rem;
            text-align: center;
            line-height: 1.3;
          }
        }
      }

      .paypal-information {
        font-size: 1.4rem;
      }

      h2 {
        font-size: 2.4rem;
      }

      .package-info-description {
        font-size: 1.4rem;
      }

      .who-am-i-block {
        padding-left: 0;
        padding-right: 0;

        .who-am-i-description {
          .left-col-content {
            margin-left: 0;

            img {
              max-width: 100%;
            }
          }

          .right-col-content {
            font-size: 1.5rem;
            line-height: 1.5;
          }
        }
      }

      .main-title {
        font-size: 3.8rem;
      }

      .main-subtitle {
        font-size: 2.4rem;
      }

      .top-video {
        border-width: .8rem 1.1rem 1.3rem;
      }
    }
  }
}

file-video-dialog {
  .video-control-wrapper {
    @include fluid-aspect(16 9);

    video {
      width: 100%;
    }
  }
}