[data-animate] {
  visibility: hidden;
}

svg-img {
  vertical-align: middle;
  display: inline-block;

  svg {
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin post-image($ratio) {
  @include fluid-aspect($ratio);

  border: $image-border;
  background-position: center;
  background-size: cover;

  .background-link {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.post-image {
  @include post-image(5 3);
}

.category-label-overlay {
  position: absolute;
  left: 0;
  top: 6%;

  display: inline-block;
  background-color: rgba($main-color, .80);
  color: #fff;
  z-index: 2;
  padding: 8px 15px 8px 25px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-family: $title-font;
  transition: .2s color;

  &:hover {
    color: black;
  }

  &.small-overlay {
    font-size: 1.3rem;
    padding: 6px 10px 6px 16px;
  }

  &.smaller-overlay {
    font-size: 1.2rem;
    padding: 5px 8px 5px 14px;
  }

  &.tiny-overlay {
    font-size: 1.1rem;
    padding: 3px 5px 3px 10px;
  }
}

ul.tag-list {
  margin: 0;

  li {
    padding: 4px 2px;

    a {
      display: inline-block;
      color: white;
      font-family: $title-font;
      background-color: $main-color;
      padding: 4px 6px;
      border-radius: 2px;
      transition: background-color .2s;

      &:before {
        content: '#';
      }

      &:hover {
        background-color: $main-color-2;
      }
    }
  }
}

.pagination-wrapper {
  text-align: center;

  .pagination > li > a,
  .pagination > li > span {
    background-color: transparent;
    border: none;
    font-family: $title-font;
    font-size: 2rem;
  }

  .pagination > .active > a,
  .pagination > .active > a:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span,
  .pagination > .active > span:hover,
  .pagination > .active > span:focus {
    color: black;
    background-color: transparent;
  }

  .pagination-prev,
  .pagination-next {
    a {
      color: $main-color;
    }
  }
}

.section-title-wrapper {
  background: white;
  padding-bottom: 20px;
}

.section-title {
  position: relative;
  font-size: 13rem;
  color: rgba(black, .1);
  font-weight: bold;
  font-family: $title-font;
  text-transform: uppercase;
  line-height: 0.8;
  padding-top: 27px;

  margin-left: -7px;
  margin-top: 20px;
  overflow: hidden;
  white-space: nowrap;

  h2 {
    font-size: 5rem;
    color: $main-color;
    font-weight: bold;
    margin: 0;
    position: absolute;
    bottom: 16px;
    left: 14px;
    line-height: 1;
  }

  &.section-title-small {
    font-weight: normal;
    font-size: 10.4rem;

    h2 {
      font-size: 4rem;
      left: 7px;
      bottom: 20px;
    }
  }

  @media (max-width: $screen-md-max) {
    font-size: 12rem;

    h2 {
      font-size: 4.6rem;
    }

    &.section-title-small {
      font-size: 8.6rem;

      h2 {
        bottom: 14px;
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    font-size: 9rem;

    h2 {
      font-size: 3.6rem;
    }

    &.section-title-small {
      padding-top: 40px;
      font-size: 6.6rem;
      overflow: visible;

      h2 {
        bottom: 4px;
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    font-size: 5.4rem;
    margin-left: 0;

    h2 {
      font-size: 2.4rem;
      bottom: 8px;
    }

    &.section-title-small {
      font-size: 5.6rem;
      white-space: normal;

      h2 {
        font-size: 3.4rem;
      }
    }
  }

  @media (max-width: $screen-tn-max) {
    white-space: normal;
  }
}

.short-hr {
  display: block;
  border: none;
  width: 56px;
  height: 4px;
  background-color: $main-color;
  margin: 10px auto 10px 0;

  &.white {
    background-color: white;
  }

  &.center {
    margin-left: auto;
    margin-right: auto;
  }
}

.goto-link {
  .text {
    vertical-align: middle;
  }

  .fa {
    color: $main-color;
    font-size: 140%;
    vertical-align: middle;
    margin-left: 5px;
    margin-top: -2px;
  }
}

.visible-tn-block {
  display: none;

  @media (max-width: $screen-tn-max) {
    display: block;
  }
}

.visible-xs-notn-block {
  display: none;

  @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
    display: block;
  }
}