article-comment {
  .t-cell {
    vertical-align: top;

    &:last-child {
      padding-left: 10px;
    }
  }

  .comment-picture {
    border-radius: 50%;
    border: 1px solid #ccc;
    width: 60px;
    height: 60px;
  }

  .comment-name {
    font-family: $title-font;
    margin-bottom: 10px;
  }

  .comment-content {
    margin-bottom: 10px;
  }

  .comment-meta {
    .comment-date {
      font-size: 1.2rem;
      margin-right: 10px;
    }

    .like-count {
      margin-right: 5px;
    }

    .like-toggle,
    .dislike-toggle {
      transition: color .2s;
    }

    .like-toggle {
      margin-right: 10px;
    }
  }
}