@mixin under-rectangle-style($color) {
  display: block;
  width: 3.6rem;
  height: .5rem;
  background-color: $color;
  margin: 1rem auto 0 auto;
}

@mixin under-rectangle($color) {
  &:after {
    content: '';
    @include under-rectangle-style($color);
  }
}