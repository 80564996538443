@mixin colorize-category($slug, $color1, $color2) {
  .category-#{$slug} {
    main-header {
      .header-top {
        .header-top-background {
          background: linear-gradient(to bottom, $color2 0%, $color1 100%);
        }
      }

      .header-logo-icon,
      .header-logo-text {
        linearGradient {
          stop[offset="0"] { stop-color: $color1 !important; }
          stop[offset="1"] { stop-color: $color2 !important; }
        }
      }

      &.is_stuck {
        .header-body {
          background: rgba($color1, .95);

          .header-logo {
            .header-logo-icon {
              stop[offset="0"] { stop-color: white !important; }
              stop[offset="1"] { stop-color: white !important; }
            }
          }
        }
      }

      .dummy-color {
        color: $color1;
        background-color: $color2;
      }
    }

    main-sidebar {
      .header-logo-icon,
      .header-logo-text {
        linearGradient {
          stop[offset="0"] { stop-color: $color1 !important; }
          stop[offset="1"] { stop-color: $color2 !important; }
        }
      }
    }

    .category-bg-color {
      background-color: $color1;
    }

    .trending .fancy-number {
      color: rgba($color1, .3)
    }

    main {
      .tag-list {
        li {
          a {
            background-color: $color1;

            &:hover {
              background-color: $color2;
            }
          }
        }
      }

      .category-color {
        color: $color1;
      }

      a.category-color:hover {
          color: $color2;
      }

      .short-hr {
        background-color: $color1;
      }

      .goto-link {
        .fa {
          color: $color1;
        }
      }

      .pagination-wrapper {
        .pagination-prev,
        .pagination-next {
          a {
            color: $color1;
          }
        }
      }

      .most-viewed-col {
        .post {
          h4 a {
            color: $color1;

            &:hover {
              color: #ccc;
            }
          }
        }
      }

      latest-articles {
        .post {
          h4 a {
            color: $color1;

            &:hover {
              color: #ccc;
            }
          }
        }
      }

      .post-content {
        a {
          color: $color1;

          &:hover {
            color: $color2;
          }
        }

        .marker {
          background-color: rgba($color1, .55);
        }
      }

      .comment-form {
        .btn {
          background-color: $color1;

          &:hover {
            background-color: $color2;
          }
        }
      }

      .article-comments {
        .like-toggle,
        .dislike-toggle {
          &.active {
            color: $color1;
          }
        }
      }
    }
  }

  .category-dropdown-#{$slug} {
    .tag-list {
      li {
        a {
          background-color: $color1;

          &:hover {
            background-color: $color2;
          }
        }
      }
    }

    .sideways-text-wrapper {
      &:before {
        background-color: $color1;
      }
    }

    .category-color {
      color: $color1;
    }

    a.category-color:hover {
      color: $color2;
    }

    .short-hr {
      background-color: $color1;
    }
  }
}

@include colorize-category("eletmod",           #E34A56, #E9727A);
@include colorize-category("szex",              #F58B52, #F8AA81);
@include colorize-category("helyem-a-vilagban", #F4D620, #F8E372);
@include colorize-category("kapcsolatok",       #58C75E, #72CF76);
@include colorize-category("onkifejezes",      #4398D1, #66C8E8);
@include colorize-category("onismeret",         #765298, #9876B6);
@include colorize-category("spiritualitas",     #BB53A0, #CA77B6);
