banner-block {
  display: block;
  text-align: center;
  line-height: 1;

  .image-banner,
  .html-banner {
    display: inline-block;
    vertical-align: middle;
  }

  .image-banner {
    border: 1px solid black;

    img {
      @include img-responsive;
    }
  }

  .html-banner-outer {
    margin: 0 auto;

    .html-banner-wrapper {
      overflow: hidden;
      position: relative;

      .html-banner {
        position: absolute;
        left: 50%;
        top: 50%;
        overflow: hidden;
        transform: translate(-50%, -50%);
      }
    }
  }

}