author-page {
  display: block;

  .author-top {
    position: relative;

    .section-title {
      margin-bottom: 20px;

      h2 {
        color: black;
      }
    }

    .authors-back-container {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;

      a {
        font-family: $title-font;
        text-transform: uppercase;
        font-size: 1.8rem;
        font-weight: bold;

        img {
          margin-right: 5px;
        }
      }
    }
  }

  section {
    background-color: #f0f0f0;
    padding-top: 46px;
    padding-bottom: 50px;

    .author-main-image,
    .author-small-image {
      background-size: cover;
      background-position: center;
      border: 1px solid #1E2329;
    }

    .author-image-wrapper {
      max-width: 250px;
      margin: 0 auto;
    }

    .author-main-image {
      @include fluid-aspect(2 3);

      margin-bottom: 10px;
    }

    .small-images {
      margin-left: -5px;
      margin-right: -5px;

      [class*="col-"] {
        padding-left: 5px;
        padding-right: 5px;
      }

      .author-small-image {
        @include fluid-aspect(1 1);

        display: block;
      }
    }

    h2 {
      margin-top: 0;
      text-transform: uppercase;
      font-size: 3.5rem;
      line-height: 1;
      display: inline-block;

      &:after {
        content: '';
        display: block;
        width: 56px;
        height: 5px;
        background-color: $main-color;
        margin-top: 9px;
        margin-left: 3px;
      }
    }

    .author-title {
      font-family: $title-font;
      color: $main-color;
      font-size: 2.3rem;
      margin-bottom: 10px;
    }

    .author-introduction {
      font-family: $title-font;
      quotes: '„' '”';
      margin-bottom: 10px;

      &:before {
        content: open-quote;
      }

      &:after {
        content: close-quote;
      }
    }

    .author-biography {
      font-size: 1.3rem;
      line-height: 1.4;
      text-align: justify;

      a {
        color: $main-color !important;
        font-weight: bold;
        transition: .2s all ease;

        &:hover {
          opacity: .7;
        }
      }
    }

    .sidebar-container {
      background-color: white;
      padding: 38px 38px;

      h3 {
        text-transform: uppercase;
        margin-top: 0;
        display: inline-block;
        font-weight: bold;
        padding-bottom: 12px;
        border-bottom: 2px solid $main-color;
        margin-bottom: 25px;
      }

      .contact-list {
        margin-bottom: 20px;

        li {
          padding: 10px 0;
          border-bottom: 1px solid #5e5e5e;

          &:last-child {
            border-bottom: 0;
          }

          a {
            font-family: $title-font;
            font-size: 1.8rem;

            .fa {
              font-size: 2.4rem;
              vertical-align: middle;
              margin-right: 8px;
            }

            .contact-type {
              vertical-align: middle;
            }
          }
        }
      }

      .tag-list {
        font-size: 1.2rem;
      }
    }

    .author-articles {
      margin-top: 21px;
      padding: 40px 30px;
      background-color: white;

      h3 {
        color: black;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 2.3rem;
        margin-top: 0;
        margin-bottom: 20px;

        &:after {
          content: '';
          display: block;

          width: 56px;
          height: 4px;
          background-color: $main-color;
          margin-top: 7px;
        }

        .name {
          margin-right: 8px;
        }
      }

      .row {
        margin-left: -5px;
        margin-right: -5px;

        [class*="col-"] {
          padding-left: 5px;
          padding-right: 5px;
        }
      }

      .post-image {
        margin-bottom: 10px;
      }

      .article-title {
        font-family: $title-font;
        text-transform: uppercase;
        font-weight: bold;
        color: black;
        margin-bottom: 5px;
        font-size: 1.6rem;
        line-height: 1;

        height: 2 * 1 * 1.6rem; // Limit to 2 lines
        overflow: hidden;
      }

      .article-date {
        font-size: 1.2rem;
        margin-bottom: 5px;
      }

      .article-summary {
        font-size: 1.2rem;
        line-height: 1.2;
        height: 4 * 1.2 * 1.2rem; // Limit to 4 lines
        overflow: hidden;
        margin-bottom: 10px;
      }
    }

    @media (max-width: $screen-xs-max) {
      .author-image-wrapper {
        margin-bottom: 25px;
      }

      .sidebar-container {
        padding: 19px;
      }
    }
  }
}