premium-packages-page,
purchased-packages-page {
  .page-header {
    background-image: url(../../images/premium/premium_csomag_header_bg.jpg);
    border-color: $main-color;

    .main-subtitle {
      font-weight: $font-weight-normal;
      font-size: 3.5rem;
    }

    .leftside-image {
      position: absolute;
      left: -25.5rem;
      bottom: -10.4rem;
    }
  }

  .page-body {
    h2 {
      text-align: center;
      text-transform: uppercase;
      font-size: 3rem;
      margin: 2rem 0 4rem 0;

      @include under-rectangle($text-color);
    }

    .package-list-item {
      @include clearfix;

      margin-bottom: 5rem;

      // region left col

      .left-col-contents {
        margin-left: -4.7rem;
        margin-top: -1.4rem;
      }

      .package-button-wrapper {
        max-width: 29.5rem;
        padding: 2rem 0;
        text-align: center;
      }

      .package-button {
        position: relative;
        display: inline-block;
        color: white;
        border-radius: .8rem;
        padding: 1rem 2.6rem 0.5rem 2.6rem;
        text-transform: uppercase;
        line-height: 1.1;
        transition: opacity .25s;
        font-size: 3.8rem;

        &:hover {
          opacity: .8;
        }

        .line {
          display: block;
          text-align: center;
        }

        .line:nth-child(1) {
          @include montserrat($font-weight-bold);

          letter-spacing: .1rem;
        }

        .line:nth-child(2) {
          @include montserrat($font-weight-extra-bold);

          font-size: 1.3em;
          letter-spacing: 0.2rem;
        }

        &:after {
          content: '';
          display: block;
          background-image: url(../../images/premium/premium_csomagok_price_arrow.png);
          width: 9rem;
          height: 11.8rem;
          position: absolute;
          left: -6.9rem;
          //bottom: -3.8rem;
          top: 27%;
        }
      }

      &.purchased-package {
        .package-button {
          font-size: 2.8rem;
        }
      }

      // endregion left col

      // region right col

      h3 {
        @include montserrat($font-weight-bold);

        text-transform: uppercase;
        font-size: 4.7rem;
        letter-spacing: 0.2rem;
        line-height: 1;
        margin: 1.9rem 0 0.4rem;
      }

      .package-subtitle {
        font-size: 3.8rem;
        line-height: 1;
        margin-bottom: 1.7rem;
      }

      .package-short-description {
        font-weight: bold;
        font-size: 1.9rem;
      }

      .package-files-intro {
        margin-bottom: 1.5rem;
      }

      // endregion right col
    }

    .fedelzet-content {
      margin-bottom: 5rem;
    }

    .audiobooks-btn {
      text-transform: uppercase;
      font-size: 3rem;
      margin: 0 auto 5rem;
    }
  }

  @media (max-width: $screen-md-max) {
    .page-header {
      .background-text {
        font-size: 9rem;
      }
    }

    .page-body {
      .top-video {
        max-width: 60rem;
        margin-right: 5rem;
      }

      .package-list-item {
        .package-button {
          font-size: 3rem;
        }

        &.purchased-package {
          .package-button {
            font-size: 2.2rem;
          }
        }
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    .page-header {
      .background-text {
        font-size: 7rem;
      }

      .leftside-image {
        display: none;
      }
    }

    .page-body {
      .top-video {
        margin-right: auto;
      }

      .package-list-item {
        .package-subtitle {
          font-size: 3rem;
        }

        .package-short-description {
          font-size: 1.6rem;
        }

        .package-button {
          font-size: 2.1rem;
        }

        &.purchased-package {
          .package-button {
            font-size: 1.8rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
          }
        }
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    .page-header {
      .relative-wrapper {
        height: 26rem;
      }

      .background-text {
        display: none;
      }
    }

    .page-body {
      background-position-y: 26rem;

      .main-title {
        font-size: 4.2rem;
      }

      .main-subtitle {
        font-size: 2.4rem;
      }

      .top-video {
        border-width: .8rem 1.1rem 1.3rem;
      }

      .package-list-item {
        .left-col-contents {
          margin-left: 0;
          margin-top: 0;
          text-align: center;
        }

        .package-button:after {
          display: none;
        }

        .package-button-wrapper {
          margin: 0 auto;
        }
      }
    }
  }
}